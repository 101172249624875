// src/services/Limitation.service.ts

import { StoreService } from '@/services/Store.service';
import { getConfig } from '@/services/Config.service';
import { LimitationType } from '@/types/Limitation.type';
import { OrganizationType } from '@/types/Organization.type';
import { RoleType } from '@/types/Role.type';

export const getLimitation = {
	/**
	 * Limitations
	 */

	organizationsLimitReached() {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            StoreService.state.profile &&
            StoreService.state.profile._id &&
            StoreService.state.organizations.filter(
                (organization: OrganizationType) =>
                    organization.role && 
                    organization.role.user_id === StoreService.state.profile!._id &&
                    organization.role.role === getConfig('RoleConfig', 'Owner')
            ).length < 1
        ) return false
		return true
	},
	organizationSpacesLimitReached(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation && limitation.organization_spaces &&
            StoreService.state.spaces.length < limitation.organization_spaces
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.organization_spaces === undefined ||
                StoreService.state.spaces.length <
                    StoreService.state.organization.limitation.organization_spaces
            )
        ) return false
		return true
	},
	organizationRolesLimitReached(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation && limitation.organization_roles &&
            StoreService.state.roles.filter(
                (role: RoleType) => role && !role.space_id
            ).length < limitation.organization_roles
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.organization_roles === undefined ||
                StoreService.state.roles.filter(
                    (role: RoleType) => role && !role.space_id
                ).length < StoreService.state.organization.limitation.organization_roles
            )
        ) return false
		return true
	},
	spaceBrandsLimitReached(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation && limitation.space_brands &&
            StoreService.state.brands.length < limitation.space_brands
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.space_brands === undefined ||
                StoreService.state.brands.length <
                    StoreService.state.organization.limitation.space_brands
            )
        ) return false
		return true
	},
	spaceRolesLimitReached(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation && limitation.space_roles &&
            StoreService.state.roles.filter(
                (role: RoleType) => role && role.space_id
            ).length < limitation.space_roles
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.space_roles === undefined ||
                StoreService.state.roles.filter(
                    (role: RoleType) => role && role.space_id
                ).length < StoreService.state.organization.limitation.space_roles
            )
        ) return false
		return true
	},
	contentsLimitedAccess(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation &&
            limitation.contents_access !== undefined &&
            limitation.contents_access === true
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.contents_access === undefined ||
                StoreService.state.organization.limitation.contents_access === true
            )
        ) return false
		return true
	},
    postsLimitedAccess(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation &&
            limitation.posts_access !== undefined &&
            limitation.posts_access === true
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.posts_access === undefined ||
                StoreService.state.organization.limitation.posts_access === true
            )
        ) return false
		return true
	},
	insightsLimitedAccess(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation &&
            limitation.insights_access !== undefined &&
            limitation.insights_access === true
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.insights_access === undefined ||
                StoreService.state.organization.limitation.insights_access === true
            )
        ) return false
		return true
	},
	brandsLimitedAccess(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation &&
            limitation.brands_access !== undefined &&
            limitation.brands_access === true
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.brands_access === undefined ||
                StoreService.state.organization.limitation.brands_access === true
            )
        ) return false
		return true
	},
	viewsLimitedAccess(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation &&
            limitation.views_access !== undefined &&
            limitation.views_access === true
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.views_access === undefined ||
                StoreService.state.organization.limitation.views_access === true
            )
        ) return false
		return true
	},
	rolesLimitedAccess(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation &&
            limitation.roles_access !== undefined &&
            limitation.roles_access === true
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.roles_access === undefined ||
                StoreService.state.organization.limitation.roles_access === true
            )
        ) return false
		return true
	},
	mediasLimitedAccess(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation &&
            limitation.medias_access !== undefined &&
            limitation.medias_access === true
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.medias_access === undefined ||
                StoreService.state.organization.limitation.medias_access === true
            )
        ) return false
		return true
	},
	datesLimitedAccess(limitation?: LimitationType) {
		if (
			StoreService.state.profile &&
			StoreService.state.profile.admin === true
		) return false
        else if (
            limitation &&
            limitation.dates_access !== undefined &&
            limitation.dates_access === true
        ) return false
        else if (
            limitation === undefined &&
            StoreService.state.organization &&
            (
                !StoreService.state.organization.limitation ||
                StoreService.state.organization.limitation.dates_access === undefined ||
                StoreService.state.organization.limitation.dates_access === true
            )
        ) return false
		return true
	},
	getHistorical() {
		return StoreService.state.organization?.limitation?.historical || 30
	}
};

// import App from vue to create a plugin
import { App, Plugin } from 'vue';

// create and export config service
export const LimitationService: Plugin = {
	// required method to set config service as plugin
	install: (app: App) => {
		app.config.globalProperties.$limitation = getLimitation;
	},
};

// set $limitation as global variable
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$limitation: any;
	}
}
