import GetProfileService from '@/services/GetProfile.service';

export async function LoadProfileMiddleware() {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Profile loading... ',
			'color: #FF0000;font-weight:bold;'
		);
		GetProfileService()
			.then(() => {
				resolve(true);
			})
			.catch((error) => {
				console.log(error);
			});
	});
}
