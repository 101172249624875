
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		dropdownMenuInsideWrapperClass: {
			type: String,
		},
	},
});
