// src/configs/Role.config.ts

// hydrate and export RoleConfig object
export const RoleConfig: any = {
	ObjectName: 'RoleConfig',

	Viewer: 10,
	Editor: 20,
	Owner: 30
};
