// src/mixins/NavBar.mixin.ts

/**
 * parent component of all NavBar
 */
import { defineComponent } from 'vue';

export const NavBarMixin = defineComponent({
	/**
	 * business logic only
	 */
	computed: {
		NavBarVerticalChildComponent(): string | null {
			switch (this.$route.name) {
				case this.$config('RouteConfig', 'BrandCreateRouteName'):
					return 'ProductNavBarVerticalParametersComponent';
				case this.$config('RouteConfig', 'CheckoutRouteName'):
					return '';
				case this.$config('RouteConfig', 'ContentAddRouteName'):
					return 'ProductNavBarVerticalContentsComponent';
				case this.$config('RouteConfig', 'BrandUpdateRouteName'):
					return 'ProductNavBarVerticalParametersComponent';
				case this.$config('RouteConfig', 'MediaAddRouteName'):
					return 'ProductNavBarVerticalMediaComponent';
				case this.$config('RouteConfig', 'MediaUpdateRouteName'):
					return 'ProductNavBarVerticalMediaComponent';
				case this.$config('RouteConfig', 'OrganizationCreateRouteName'):
					return 'ProductNavBarVerticalOrganizationComponent';
				case this.$config('RouteConfig', 'OrganizationUpdateRouteName'):
					return 'ProductNavBarVerticalOrganizationComponent';
				case this.$config('RouteConfig', 'PaymentMethodAddRouteName'):
					return '';
				case this.$config('RouteConfig', 'PaymentMethodUpdateRouteName'):
					return '';
				case this.$config('RouteConfig', 'PrivacyPolicyRouteName'):
					return 'PublicNavBarVerticalPrivacyPolicyComponent';
				case this.$config('RouteConfig', 'ProfileUpdateRouteName'):
					return 'ProductNavBarVerticalProfileComponent';
				case this.$config('RouteConfig', 'RoleAddRouteName'):
					return 'ProductNavBarVerticalUserComponent';
				case this.$config('RouteConfig', 'RoleUpdateRouteName'):
					return 'ProductNavBarVerticalUserComponent';
				case this.$config('RouteConfig', 'SpaceCreateRouteName'):
					return 'ProductNavBarVerticalSpaceComponent';
				case this.$config('RouteConfig', 'SpaceUpdateRouteName'):
					return 'ProductNavBarVerticalSpaceComponent';
				case this.$config('RouteConfig', 'ContentsPreviewRouteName'):
					return '';
				case this.$config('RouteConfig', 'PostssPreviewRouteName'):
					return '';
				default:
					return 'ProductNavBarVerticalDefaultComponent';
			}
		},
		NavBarHorizontalChildComponent(): string | void {
			if (
				(this.$route.name &&
					Array.prototype.includes.call(
						this.$config('RouteConfig', 'LoggedSettingsRouteNames'),
						this.$route.name
					)) ||
				Array.prototype.includes.call(
					this.$config(
						'RouteConfig',
						'LoggedSettingsWithoutNavBarVerticalRouteNames'
					),
					this.$route.name
				)
			) {
				return 'ProductNavBarHorizontalSettingComponent';
			} else if (
				this.$route.name &&
				Array.prototype.includes.call(
					this.$config('RouteConfig', 'LoggedContentsWithoutNavBarVerticalRouteNames'),
					this.$route.name
				)
			) {
				return 'ProductNavBarHorizontalClosableComponent';
			} else if (
				this.$route.name &&
				Array.prototype.includes.call(
					this.$config('RouteConfig', 'LoggedClosableRouteNames'),
					this.$route.name
				)
			) {
				return 'ProductNavBarHorizontalClosableComponent';
			} else if (
				this.$route.name ===
				this.$config('RouteConfig', 'PrivacyPolicyRouteName')
			) {
				return 'PublicNavBarHorizontalClosableComponent';
			} else {
				return 'ProductNavBarHorizontalDefaultComponent';
			}
		},
	},
});
