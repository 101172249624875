import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';

export async function RedirectSpaceMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Space redirection ... ',
			'color: #FF0000;font-weight:bold;'
		);

		if (
			StoreService.state.space &&
			(
				to.name === getConfig('RouteConfig', 'OrganizationRouteName') ||
				(
					to.name === getConfig('RouteConfig', 'SpaceRouteName') &&
					!to.params.space_id
				)
			)
		) {
			// Redirect on organization route
			RouterService.push({
				name: getConfig('RouteConfig', 'SpaceRouteName'),
				params: {
					organization_id: to.params.organization_id,
					space_id: StoreService.state.space._id as string
				},
			}).then(() => {
				next(false);
			});
		}
		else {
			resolve(true)
		}
	});
}
