// src/services/beautify.service.ts

// import App from vue to create a plugin
import moment from 'moment';
import { App, Plugin } from 'vue';

export const getBeautify = {
	/**
	 *
	 * set each word first letter to uppercase
	 *
	 * @param text | string
	 * @returns string
	 */
	eachWordFirstLetterUppercase: (text: string): string => {
		if (text) {
			return text
				.toLowerCase()
				.split(' ')
				.map((word) => word.charAt(0).toUpperCase() + word.substring(1))
				.join(' ');
		}
		return '';
	},
	/**
	 *
	 * set string first letter to uppercase
	 *
	 * @param text | string
	 * @returns string
	 */
	firstLetterUppercase: (text: string): string => {
		if (text) {
			return text.charAt(0).toUpperCase() + text.slice(1);
		}
		return '';
	},
	/**
	 *
	 * set string to lowercase
	 *
	 * @param text | string
	 * @returns string
	 */
	lowercase: (text: string): string => {
		if (text) {
			return text.toLowerCase();
		}
		return '';
	},
	/**
	 *
	 * set string to uppercase
	 *
	 * @param text | string
	 * @returns string
	 */
	uppercase: (text: string): string => {
		if (text) {
			return text.toUpperCase();
		}
		return '';
	},
	/**
	 *
	 */
	date: (text: string): string => {
		return moment(text).format('MMM Do YYYY');
	},
	fullDate: (text: string): string => {
		return moment(text).format('HH:mm - MMM Do YYYY');
	},
};
// create and export beautify service
export const BeautifyService: Plugin = {
	// required method to set config service as plugin
	install: (app: App) => {
		app.config.globalProperties.$beautify = getBeautify;
	},
};

// set $beautify as global variable
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$beautify: any;
	}
}
