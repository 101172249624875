// src/services/CheckFilters.service.ts

import { getConfig } from '@/services/Config.service';
import { StoreService } from '@/services/Store.service';
import { LocationQueryRaw } from 'vue-router';
import { getToolbox } from '@/services/Toolbox.service';
import UpdateFiltersQuery from '@/services/UpdateFiltersQuery.service';
import BuildQueryFilters from '@/services/BuildQueryFilters.service';

export default async function () {
	return new Promise((resolve) => {
        console.log(
            '%c [Service] Check filters needs ... ',
            'color: #0000FF;font-weight:bold;'
        );

        if (
            !StoreService.state.redirecting &&
            getConfig('RouteConfig', 'FilterableRouteNames').includes(
                StoreService.state.current_route!.name
            ) &&
            StoreService.state.brandIdsFiltersReady &&
            StoreService.state.startDateFiltersReady &&
            StoreService.state.endDateFiltersReady &&
            StoreService.state.startDateCompareFiltersReady &&
            StoreService.state.endDateCompareFiltersReady &&
            StoreService.state.keywordIdsFiltersReady &&
            StoreService.state.pageFiltersReady &&
            StoreService.state.numFiltersReady
        ) {
            const query: LocationQueryRaw = BuildQueryFilters(
				StoreService.state.current_route!.name as string
			)
            if (
                StoreService.state.checkURLFilters ||
                getToolbox.entitiesAreDifferent(
                    query,
                    StoreService.state.filtersQuery
                )
            ) {
                UpdateFiltersQuery(query).then(() => {
                    resolve(true);
                });
            }
            else {
                resolve(true)
            }
        }
        else {
            resolve(true);
        }
	});
}
