// src/services/Authentication.service.ts

import { getConfig } from './Config.service';
import { RouterService } from './Router.service';
import { StoreService } from './Store.service';
import { setCookie } from 'typescript-cookie'

export const AuthenticationService = {
	logUser(jwt: string) {
		if (
			localStorage.getItem(
				getConfig('AppConfig', 'UserTokenLocalStorageKey') as string
			) === null
		) {
			return new Promise((resolve, reject) => {
				StoreService.dispatch('set', {
					stateProperty: getConfig(
						'AppConfig',
						'UserTokenLocalStorageKey'
					) as string,
					value: jwt,
				})
					.then(() => {
						StoreService.dispatch('login').then(() => {
							localStorage.setItem(
								getConfig('AppConfig', 'UserTokenLocalStorageKey') as string,
								jwt
							);
							setCookie(
								getConfig('AppConfig', 'UserTokenLocalStorageKey') as string,
								jwt,
								{
									domain: getConfig('AppConfig', 'CookieDomain') as string,
									expires: 365
								});
							RouterService.push({
								name: getConfig('RouteConfig', 'HomeRouteName') as string,
							})
								.then(() => {
									resolve(true);
								})
								.catch((error: any) => {
									reject(error);
								});
						});
					})
					.catch((error: any) => {
						reject(error);
					});
			});
		}
	},
	logoutUser() {
		StoreService.dispatch('set', {
			stateProperty: getConfig(
				'AppConfig',
				'UserTokenLocalStorageKey'
			) as string,
			value: null,
		}).then(() => {
			StoreService.dispatch('logout').then(() => {
				localStorage.removeItem(
					getConfig('AppConfig', 'UserTokenLocalStorageKey') as string
				);
				RouterService.push({
					name: getConfig('RouteConfig', 'WelcomeRouteName') as string,
					hash: '',
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'profile',
						value: null,
					}).then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'profileLoaded',
							value: false,
						}).then(() => {
							StoreService.dispatch('set', {
								stateProperty: 'organizationsLoaded',
								value: false,
							}).then(() => {});
						});
					});
				});
			});
		});
	},
};
