// src/services/beautify.service.ts

// import App from vue to create a plugin
import { App, Plugin } from 'vue';
import { getConfig } from '@/services/Config.service';
import { getText } from './Text.service';
import moment from 'moment';

export const getToolbox = {
	/**
	 *
	 * slugify string
	 *
	 * @param text | string
	 * @returns string
	 */
	slugify: (text: string): string => {
		text = text.toString().toLowerCase().trim();

		const sets = [
			{ to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
			{ to: 'c', from: '[ÇĆĈČ]' },
			{ to: 'd', from: '[ÐĎĐÞ]' },
			{ to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
			{ to: 'g', from: '[ĜĞĢǴ]' },
			{ to: 'h', from: '[ĤḦ]' },
			{ to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
			{ to: 'j', from: '[Ĵ]' },
			{ to: 'ij', from: '[Ĳ]' },
			{ to: 'k', from: '[Ķ]' },
			{ to: 'l', from: '[ĹĻĽŁ]' },
			{ to: 'm', from: '[Ḿ]' },
			{ to: 'n', from: '[ÑŃŅŇ]' },
			{ to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
			{ to: 'oe', from: '[Œ]' },
			{ to: 'p', from: '[ṕ]' },
			{ to: 'r', from: '[ŔŖŘ]' },
			{ to: 's', from: '[ßŚŜŞŠ]' },
			{ to: 't', from: '[ŢŤ]' },
			{ to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
			{ to: 'w', from: '[ẂŴẀẄ]' },
			{ to: 'x', from: '[ẍ]' },
			{ to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
			{ to: 'z', from: '[ŹŻŽ]' },
			{ to: '-', from: "[·/_,:;']" },
		];

		sets.forEach((set) => {
			text = text.replace(new RegExp(set.from, 'gi'), set.to);
		});

		return text
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w-]+/g, '') // Remove all non-word chars
			.replace(/--+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, ''); // Trim - from end of text
	},
	/**
	 *
	 * @param element HTMLElement | HTMLInputElement | Element
	 * @returns boolean
	 */
	// check if element is in viewport
	isInViewport: (
		element: HTMLElement | HTMLInputElement | Element
	): boolean => {
		const rect = element.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <=
				(window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	},
	// in construct method
	isInContentArea: (
		element: HTMLElement | HTMLInputElement | Element
	): boolean => {
		const DOMContentArea = document
			.getElementById('content')
			?.getBoundingClientRect();
		if (typeof DOMContentArea === 'object') {
			const DOMElementArea = element.getBoundingClientRect();
			return DOMElementArea.top === DOMContentArea.top;
		}

		return false;
	},
	isMobile: (): boolean => {
		return window.innerWidth <= getConfig('VueConfig', 'LgBreakPoint')!;
	},
	/**
	 * Convert a template string into HTML DOM nodes
	 * @param  {String} str The template string
	 * @return {Node}       The template HTML
	 */
	stringToHTML: (str: string) => {
		const support = (function () {
			if (!window.DOMParser) return false;
			const parser = new DOMParser();
			try {
				parser.parseFromString('x', 'text/html');
			} catch (err) {
				return false;
			}
			return true;
		})();

		// Otherwise, fallback to old-school method
		const dom = document.createElement('div');
		dom.innerHTML = str;

		return dom;
	},
	/**
	 *  Compare two objects
	 * @param object1 any
	 * @param object2 any
	 * @return boolean
	 */
	objectsAreDifferent: (object1: any, object2: any): boolean => {
		let response = false;
		object1 = JSON.parse(JSON.stringify(object1));
		object2 = JSON.parse(JSON.stringify(object2));
		if (object1 === null && object2 === null) {
			return false;
		} else if (object1 === null || object2 === null) {
			return true;
		}
		if (Object.keys(object1).length !== Object.keys(object2).length) {
			response = true;
		} else {
			Object.keys(object1).forEach((key: string) => {
				if (response === false) {
					if (Object.prototype.hasOwnProperty.call(object2, key)) {
						if (
							Object.prototype.toString.call(object1[key]) ===
							Object.prototype.toString.call(object2[key])
						) {
							if (
								Object.prototype.toString.call(object1[key]) ===
									'[object Array]' ||
								Object.prototype.toString.call(object1[key]) ===
									'[object Object]'
							) {
								response = getToolbox.entitiesAreDifferent(
									object1[key],
									object2[key]
								);
							} else {
								if (object1[key] !== object2[key]) {
									response = true;
								}
							}
						} else {
							response = true;
						}
					} else {
						response = true;
					}
				}
			});
		}
		return response;
	},
	/**
	 *
	 * @param entity1
	 * @param entity2
	 * @returns
	 */
	arraysAreDifferent: (array1: any, array2: any): boolean => {
		let itemFound = false;
		if (array1.length === 0 && array2.length === 0) {
			return itemFound;
		} else if (array1.length !== array2.length) {
			return true;
		} else {
			const loop = function (itemArray1: any) {
				array2.forEach((itemArray2: any) => {
					if (
						Object.prototype.toString.call(itemArray1) !==
						Object.prototype.toString.call(itemArray2)
					) {
						itemFound = false;
					} else {
						if (itemFound === false) {
							itemFound = !getToolbox.entitiesAreDifferent(
								itemArray1,
								itemArray2
							);
						}
					}
				});
			};
			array1.forEach((itemArray1: any) => {
				if (itemArray1 === array1[0]) {
					loop(itemArray1);
				} else if (itemFound === true) {
					itemFound = false;
					loop(itemArray1);
				}
			});

			return !itemFound;
		}
	},
	/**
	 *  Compare two entities
	 * @param entity1 any
	 * @param entity2 any
	 * @return boolean
	 */
	entitiesAreDifferent: (entity1: any, entity2: any): boolean => {
		// igual type
		let response: boolean = false;
		if ((entity1 && !entity2) || (!entity1 && entity2)) {
			response = true;
		} else {
			if (
				Object.prototype.toString.call(entity1) ===
				Object.prototype.toString.call(entity2)
			) {
				if (Object.prototype.toString.call(entity1) === '[object String]') {
					response = entity1 !== entity2;
				} else if (
					Object.prototype.toString.call(entity1) === '[object Array]'
				) {
					response = getToolbox.arraysAreDifferent(entity1, entity2);
				} else if (
					Object.prototype.toString.call(entity1) === '[object Object]'
				) {
					if (entity1 === null && entity2 === null) {
						response = false;
					} else {
						response = getToolbox.objectsAreDifferent(entity1, entity2);
					}
				} else response = JSON.stringify(entity1) === JSON.stringify(entity2);
			}
		}

		return response;
	},
	/**
	 *
	 * @param array1
	 * @param array2
	 * @returns
	 */
	getDifferentArray: (array1: Array<any>, array2: Array<any>): Array<any> => {
		const returnableArray: Array<any> = [];
		array1.forEach((itemArray1: any) => {
			if (
				array2.find(
					(itemArray2: any) =>
						JSON.parse(JSON.stringify(itemArray1)) ===
						JSON.parse(JSON.stringify(itemArray2))
				) === undefined
			) {
				returnableArray.push(itemArray1);
			}
		});
		array2.forEach((itemArray2: any) => {
			if (
				array2.find(
					(itemArray1: any) =>
						JSON.parse(JSON.stringify(itemArray2)) ===
						JSON.parse(JSON.stringify(itemArray1))
				) === undefined
			) {
				returnableArray.push(itemArray2);
			}
		});
		return returnableArray;
	},
	/**
	 * return difference between
	 * @param object
	 * @param initialObject
	 * @returns
	 */
	getDifferentObject: (object: any, initialObject: any): object => {
		const returnableObject: any = {};
		for (const k in object) {
			if (Object.prototype.hasOwnProperty.call(initialObject, k)) {
				if (object[k] !== initialObject[k]) {
					returnableObject[k] = object[k];
				}
			} else {
				returnableObject[k] = object[k];
			}
		}
		return returnableObject;
	},
	/**
	 *
	 * @param entity1
	 * @param entity2
	 * @returns
	 */
	getDifferentEntity: (entity1: any, entity2: any): Array<any> | object => {
		// igual type
		if (typeof entity1 === typeof entity2) {
			if (Array.isArray(entity1)) {
				return getToolbox.getDifferentArray(entity1, entity2);
			} else if (typeof entity1 === 'object') {
				return getToolbox.getDifferentObject(entity1, entity2);
			}
		}
		return entity1;
	},
	/**
	 *
	 * @param object
	 */
	setDefaultCountry(object: any) {
		if (
			typeof object.country === 'undefined' ||
			object.country === (null || '')
		) {
			object.country = navigator.language.split('-')[0];
		}
	},
	/**
	 *
	 * @param object
	 * @param propertiesToClean
	 * @returns
	 */
	cleanObject(object: any, propertiesToClean: Array<string>): any {
		for (let i = 0; propertiesToClean.length > i; i++) {
			if (Object.prototype.hasOwnProperty.call(object, propertiesToClean[i])) {
				delete object[propertiesToClean[i]];
			}
		}
		return object;
	},
	/**
	 *
	 */
	getRandomColor(): string {
		const hex = '0123456789ABCDEF';
		let r: string | number = 0;
		let g: string | number = 0;
		let b: string | number = 0;
		let r2: string | number = 0;
		let g2: string | number = 0;
		let b2: string | number = 0;
		while (r == g || r == b || g == b) {
			r = hex[Math.floor(Math.random() * (hex.length - 3 - 2 + 1)) + 2];
			r2 = hex[Math.floor(Math.random() * (hex.length - 1 - 0 + 1)) + 0];
			g = hex[Math.floor(Math.random() * (hex.length - 3 - 2 + 1)) + 2];
			g2 = hex[Math.floor(Math.random() * (hex.length - 1 - 0 + 1)) + 0];
			b = hex[Math.floor(Math.random() * (hex.length - 3 - 2 + 1)) + 2];
			b2 = hex[Math.floor(Math.random() * (hex.length - 1 - 0 + 1)) + 0];
		}

		return (
			'#' +
			r.toString().toUpperCase() +
			g.toString().toUpperCase() +
			b.toString().toUpperCase() +
			r2.toString().toUpperCase() +
			g2.toString().toUpperCase() +
			b2.toString().toUpperCase()
		);
	},
	/**
	 *
	 * @param hex
	 * @param a
	 * @returns
	 */
	hexToRgba(hex: string, a: number): string {
		// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		hex = hex.replace(shorthandRegex, function (m, r, g, b) {
			return r + r + g + g + b + b;
		});

		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		if (result !== null) {
			return (
				'rgba(' +
				parseInt(result[1], 16) +
				',' +
				parseInt(result[2], 16) +
				',' +
				parseInt(result[3], 16) +
				',' +
				a +
				')'
			);
		} else return hex;
	},

	retrieveCurrencySymbol(currency: string): string {
		switch (currency.toLocaleLowerCase()) {
			case 'eur':
				return '€';
			case 'usd':
				return '$';
			default:
				return '$';
		}
	},

	friendlyCountdown(date: any): string {
		date = moment(date);

		let friendlyCountdown: string = '';

		if (date.diff(moment(), 'days') >= 14) {
			friendlyCountdown =
				date.diff(moment(), 'weeks') + ' ' + getText('words', 'Weeks');
		} else if (date.diff(moment(), 'days') >= 7) {
			friendlyCountdown =
				date.diff(moment(), 'weeks') + ' ' + getText('words', 'Week');
		} else if (date.diff(moment(), 'days') > 1) {
			friendlyCountdown =
				date.diff(moment(), 'days') + ' ' + getText('words', 'days');
		} else {
			friendlyCountdown =
				date.diff(moment(), 'hours') + ' ' + getText('words', 'hours');
		}
		return friendlyCountdown;
	},
};
// create and export toolbox service
export const ToolboxService: Plugin = {
	// required method to set config service as plugin
	install: (app: App) => {
		app.config.globalProperties.$toolbox = getToolbox;
	},
};

// set $toolbox as global variable
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$toolbox: any;
	}
}
