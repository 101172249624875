import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "unvailable-layout",
  class: "min-h-full p-6 d-flex w-full align-items-center"
}
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { id: "main" }
const _hoisted_5 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoComponent = _resolveComponent("LogoComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LogoComponent)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ]))
}