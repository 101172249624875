// src/configs/Route.config.ts

const RouteNames: any = {
	// Home route names
	HomeRouteName: 'home',

	// Authentication route names
	WelcomeRouteName: 'welcome',
	EmailRouteName: 'sign',
	LoginRouteName: 'login',
	LoginGoogleCallbackRouteName: 'login-google-callback',
	LoginPasswordRouteName: 'login-password',
	RegisterRouteName: 'register',
	RegisterGoogleCallbackRouteName: 'register-google-callback',
	RegisterFormRouteName: 'register-form',
	RegisterSuccessRouteName: 'register-success',
	RegisterVerifyRouteName: 'register-verify',
	RegisterVerifySuccessRouteName: 'register-verify-success',
	RegisterVerifyErrorRouteName: 'register-verify-error',
	PasswordForgotRouteName: 'password-forgot',
	PasswordForgotSuccessRouteName: 'password-forgot-success',
	PasswordForgotErrorRouteName: 'password-forgot-error',
	PasswordResetRouteName: 'password-reset',
	PasswordResetFormRouteName: 'password-reset-form',
	PasswordResetSuccessRouteName: 'password-reset-success',
	PasswordResetErrorRouteName: 'password-reset-error',

	// Onboardings route name
	OnboardingRouteName: 'onboarding',

	// Redirection route names
	OrganizationRouteName: 'organization',
	SpaceRouteName: 'space',
	ViewRouteName: 'view',
	BrandRouteName: 'brand',
	AnalyticsGoogleCallbackRouteName: 'analytics-google-callback',

	// Contents route names
	ContentsRouteName: 'contents',
	ContentsPreviewRouteName: 'contents-preview',
	PostsRouteName: 'posts',
	PostsPreviewRouteName: 'posts-preview',
	InsightsRouteName: 'insights',
	MediasRouteName: 'medias',
	PredictiveRouteName: 'predictive',
	OrganizationRolesRouteName: 'organization-users',
	SpaceRolesRouteName: 'space-users',

	// Settings route names
	InformationsRouteName: 'informations',
	BrandCreateRouteName: 'brand-create',
	BrandUpdateRouteName: 'brand-update',
	CheckoutRouteName: 'checkout',
	ContentAddRouteName: 'content-add',
	ContentUpdateRouteName: 'content-update',
	MediaAddRouteName: 'media-add',
	MediaUpdateRouteName: 'media-update',
	OrganizationBillingRouteName: 'organization-billing',
	OrganizationCreateRouteName: 'organization-create',
	OrganizationUpdateRouteName: 'organization-update',
	PaymentMethodAddRouteName: 'payment-method-add',
	PaymentMethodUpdateRouteName: 'payment-method-update',
	PlansRouteName: 'plans',
	SubscribeRouteName: 'subscribe',
	ProfileUpdateRouteName: 'profile-update',
	NotificationsUpdateRouteName: 'notifications-update',
	SpaceBillingRouteName: 'space-billing',
	SpaceCreateRouteName: 'space-create',
	SpaceUpdateRouteName: 'space-update',
	RoleAddRouteName: 'role-add',
	RoleUpdateRouteName: 'role-update',

	// NotLogged Closable route names
	PrivacyPolicyRouteName: 'privacy-policy',

	// Error route names
	Error500RouteName: 'error-500',
	Error502RouteName: 'error-502',
	Error403RouteName: 'error-403',
	Error404RouteName: 'error-404',
	HashAddContentRoute: '#category',
	HashImportFileContentRoute: '#import-file',
};

// hydrate and export RouteConfig object
export const RouteConfig: any = {
	ObjectName: 'RouteConfig',

	// Error Route Names
	Error500RouteName: RouteNames.Error500RouteName,
	Error502RouteName: RouteNames.Error502RouteName,
	Error403RouteName: RouteNames.Error403RouteName,
	Error404RouteName: RouteNames.Error404RouteName,
	// array Error route names
	ErrorRouteNames: [
		RouteNames.Error500RouteName,
		RouteNames.Error502RouteName,
		RouteNames.Error403RouteName,
		RouteNames.Error404RouteName,
	],

	// Authentication route names
	WelcomeRouteName: RouteNames.WelcomeRouteName,
	EmailRouteName: RouteNames.EmailRouteName,
	LoginRouteName: RouteNames.LoginRouteName,
	LoginGoogleCallbackRouteName: RouteNames.LoginGoogleCallbackRouteName,
	LoginPasswordRouteName: RouteNames.LoginPasswordRouteName,
	RegisterRouteName: RouteNames.RegisterRouteName,
	RegisterGoogleCallbackRouteName: RouteNames.RegisterGoogleCallbackRouteName,
	RegisterFormRouteName: RouteNames.RegisterFormRouteName,
	RegisterSuccessRouteName: RouteNames.RegisterSuccessRouteName,
	RegisterVerifyRouteName: RouteNames.RegisterVerifyRouteName,
	RegisterVerifySuccessRouteName: RouteNames.RegisterVerifySuccessRouteName,
	RegisterVerifyErrorRouteName: RouteNames.RegisterVerifyErrorRouteName,
	PasswordForgotRouteName: RouteNames.PasswordForgotRouteName,
	PasswordForgotSuccessRouteName: RouteNames.PasswordForgotSuccessRouteName,
	PasswordForgotErrorRouteName: RouteNames.PasswordForgotErrorRouteName,
	PasswordResetRouteName: RouteNames.PasswordResetRouteName,
	PasswordResetFormRouteName: RouteNames.PasswordResetFormRouteName,
	PasswordResetSuccessRouteName: RouteNames.PasswordResetSuccessRouteName,
	PasswordResetErrorRouteName: RouteNames.PasswordResetErrorRouteName,
	InformationsRouteName: RouteNames.InformationsRouteName,
	// array Authentication route names
	AuthenticationLayoutRouteNames: [
		RouteNames.WelcomeRouteName,
		RouteNames.EmailRouteName,
		RouteNames.LoginRouteName,
		RouteNames.LoginGoogleCallbackRouteName,
		RouteNames.LoginPasswordRouteName,
		RouteNames.RegisterRouteName,
		RouteNames.RegisterFormRouteName,
		RouteNames.RegisterSuccessRouteName,
		RouteNames.RegisterVerifyRouteName,
		RouteNames.RegisterVerifySuccessRouteName,
		RouteNames.RegisterVerifyErrorRouteName,
		RouteNames.PasswordForgotRouteName,
		RouteNames.PasswordForgotSuccessRouteName,
		RouteNames.PasswordForgotErrorRouteName,
		RouteNames.PasswordResetRouteName,
		RouteNames.PasswordResetFormRouteName,
		RouteNames.PasswordResetSuccessRouteName,
		RouteNames.PasswordResetErrorRouteName,
	],
	// array NotLogged Route Names
	NotLoggedRouteNames: [
		RouteNames.WelcomeRouteName,
		RouteNames.EmailRouteName,
		RouteNames.LoginRouteName,
		RouteNames.LoginGoogleCallbackRouteName,
		RouteNames.LoginPasswordRouteName,
		RouteNames.RegisterRouteName,
		RouteNames.RegisterFormRouteName,
		RouteNames.RegisterSuccessRouteName,
		RouteNames.RegisterVerifyRouteName,
		RouteNames.RegisterVerifySuccessRouteName,
		RouteNames.RegisterVerifyErrorRouteName,
		RouteNames.PasswordForgotRouteName,
		RouteNames.PasswordForgotSuccessRouteName,
		RouteNames.PasswordForgotErrorRouteName,
		RouteNames.PasswordResetRouteName,
		RouteNames.PasswordResetSuccessRouteName,
		RouteNames.PasswordResetErrorRouteName,
		RouteNames.PasswordResetFormRouteName,
		RouteNames.PrivacyPolicyRouteName,
	],
	// arrray NotLoggedClosable Route Names
	NotLoggedClosableRouteNames: [
		RouteNames.PrivacyPolicyRouteName
	],

	// Home route names
	HomeRouteName: RouteNames.HomeRouteName,

	// Onboardings route names
	OnboardingRouteName: RouteNames.OnboardingRouteName,
	// array Onboarding route names
	OnboardingRouteNames: [
		RouteNames.OnboardingRouteName
	],

	// Contents route names
	OrganizationRouteName: RouteNames.OrganizationRouteName,
	SpaceRouteName: RouteNames.SpaceRouteName,
	ViewRouteName: RouteNames.ViewRouteName,
	ContentsRouteName: RouteNames.ContentsRouteName,
	ContentsPreviewRouteName: RouteNames.ContentsPreviewRouteName,
	PostsRouteName: RouteNames.PostsRouteName,
	PostsPreviewRouteName: RouteNames.PostsPreviewRouteName,
	InsightsRouteName: RouteNames.InsightsRouteName,
	MediasRouteName: RouteNames.MediasRouteName,
	PredictiveRouteName: RouteNames.PredictiveRouteName,
	OrganizationRolesRouteName: RouteNames.OrganizationRolesRouteName,
	SpaceRolesRouteName: RouteNames.SpaceRolesRouteName,
	PrivacyPolicyRouteName: RouteNames.PrivacyPolicyRouteName,
	OrganizationBillingRouteName: RouteNames.OrganizationBillingRouteName,
	SpaceBillingRouteName: RouteNames.SpaceBillingRouteName,
	PlansRouteName: RouteNames.PlansRouteName,
	SubscribeRouteName: RouteNames.SubscribeRouteName,

	// array ViewsRequired route names
	ViewsRequiredRouteNames: [
		RouteNames.SpaceRouteName,
		RouteNames.ViewRouteName,
		RouteNames.ContentsRouteName,
		RouteNames.ContentsPreviewRouteName,
		RouteNames.PostsRouteName,
		RouteNames.PostsPreviewRouteName,
		RouteNames.InsightsRouteName,
		RouteNames.PredictiveRouteName,
		RouteNames.MediasRouteName,
		RouteNames.SpaceRolesRouteName,
		RouteNames.SpaceBillingRouteName,
		RouteNames.PlansRouteName,
		RouteNames.NotificationsUpdateRouteName,
		RouteNames.SpaceUpdateRouteName,
		RouteNames.BrandUpdateRouteName,
		RouteNames.BrandCreateRouteName,
	],
	// array ViewRequired route names
	ViewRequiredRouteNames: [
		RouteNames.SpaceRouteName,
		RouteNames.ViewRouteName,
		RouteNames.BrandUpdateRouteName,
		RouteNames.BrandCreateRouteName,
	],
	// array BrandsRequired route names
	BrandsRequiredRouteNames: [
		RouteNames.OnboardingRouteName,
		RouteNames.BrandRouteName,
		RouteNames.BrandCreateRouteName,
		RouteNames.BrandUpdateRouteName,
		RouteNames.ViewRouteName,
		RouteNames.ContentsRouteName,
		RouteNames.ContentsPreviewRouteName,
		RouteNames.PostsRouteName,
		RouteNames.PostsPreviewRouteName,
		RouteNames.InsightsRouteName,
		RouteNames.PredictiveRouteName,
	],
	// array BrandRequired route names
	BrandRequiredRouteNames: [
		RouteNames.BrandRouteName,
		RouteNames.BrandCreateRouteName,
		RouteNames.BrandUpdateRouteName,
		RouteNames.SpaceRouteName,
		RouteNames.ViewRouteName,
	],
	// array RolesRequired route names
	RolesRequiredRouteNames: [
		RouteNames.OrganizationRolesRouteName,
		RouteNames.SpaceRolesRouteName,
		RouteNames.RoleAddRouteName,
		RouteNames.RoleUpdateRouteName,
	],
	// array RoleRequired route names
	RoleRequiredRouteNames: [
		RouteNames.RoleAddRouteName,
		RouteNames.RoleUpdateRouteName,
	],
	// array MediasRequired route names
	MediasRequiredRouteNames: [
		RouteNames.MediasRouteName,
		RouteNames.MediaAddRouteName,
		RouteNames.MediaUpdateRouteName,
	],
	// array MediaRequired route names
	MediaRequiredRouteNames: [
		RouteNames.MediaAddRouteName,
		RouteNames.MediaUpdateRouteName,
	],
	// array Paginated route names
	PaginatedRouteNames: [
		RouteNames.ContentsRouteName,
		RouteNames.ContentsPreviewRouteName,
		RouteNames.PostsRouteName,
		RouteNames.PostsPreviewRouteName,
	],
	// array Filterable route names
	FilterableRouteNames: [
		RouteNames.ViewRouteName,
		RouteNames.ContentsRouteName,
		RouteNames.ContentsPreviewRouteName,
		RouteNames.PostsRouteName,
		RouteNames.PostsPreviewRouteName,
		RouteNames.InsightsRouteName,
		RouteNames.PredictiveRouteName,
	],

	// Settings route names
	BrandRouteName: RouteNames.BrandRouteName,
	BrandCreateRouteName: RouteNames.BrandCreateRouteName,
	BrandUpdateRouteName: RouteNames.BrandUpdateRouteName,
	CheckoutRouteName: RouteNames.CheckoutRouteName,
	ContentAddRouteName: RouteNames.ContentAddRouteName,
	ContentUpdateRouteName: RouteNames.ContentUpdateRouteName,
	MediaAddRouteName: RouteNames.MediaAddRouteName,
	MediaUpdateRouteName: RouteNames.MediaUpdateRouteName,
	OrganizationCreateRouteName: RouteNames.OrganizationCreateRouteName,
	OrganizationUpdateRouteName: RouteNames.OrganizationUpdateRouteName,
	PaymentMethodAddRouteName: RouteNames.PaymentMethodAddRouteName,
	PaymentMethodUpdateRouteName: RouteNames.PaymentMethodUpdateRouteName,
	ProfileUpdateRouteName: RouteNames.ProfileUpdateRouteName,
	NotificationsUpdateRouteName: RouteNames.NotificationsUpdateRouteName,
	SpaceCreateRouteName: RouteNames.SpaceCreateRouteName,
	SpaceUpdateRouteName: RouteNames.SpaceUpdateRouteName,
	RoleAddRouteName: RouteNames.RoleAddRouteName,
	RoleUpdateRouteName: RouteNames.RoleUpdateRouteName,
	// array Settings route names
	LoggedSettingsRouteNames: [
		RouteNames.BrandCreateRouteName,
		RouteNames.BrandRouteName,
		RouteNames.BrandUpdateRouteName,
		RouteNames.ContentAddRouteName,
		RouteNames.ContentUpdateRouteName,
		RouteNames.MediaAddRouteName,
		RouteNames.MediaUpdateRouteName,
		RouteNames.ProfileUpdateRouteName,
		RouteNames.OrganizationCreateRouteName,
		RouteNames.OrganizationRouteName,
		RouteNames.OrganizationUpdateRouteName,
		RouteNames.SpaceCreateRouteName,
		RouteNames.SpaceRouteName,
		RouteNames.SpaceUpdateRouteName,
		RouteNames.RoleAddRouteName,
		RouteNames.RoleUpdateRouteName,
	],
	// array Settings without navbar vertical route names
	LoggedSettingsWithoutNavBarVerticalRouteNames: [
		RouteNames.CheckoutRouteName,
		RouteNames.PaymentMethodAddRouteName,
		RouteNames.PaymentMethodUpdateRouteName,
	],
	// array Settings without navbar vertical route names
	LoggedSettingsWithoutNavBarRouteNames: [
		RouteNames.InformationsRouteName,
		RouteNames.SubscribeRouteName,
	],

	// Google Callbacks route names
	AnalyticsGoogleCallbackRouteName: RouteNames.AnalyticsGoogleCallbackRouteName,

	// array Closable routes names
	LoggedClosableRouteNames: [],
	// array Contents without navbar vertical routes names
	LoggedContentsWithoutNavBarVerticalRouteNames: [
		RouteNames.ContentsPreviewRouteName,
		RouteNames.PostsPreviewRouteName,
	],

	// hash
	HashAddContentRoute: RouteNames.HashAddContentRoute,
	HashImportFileContentRoute: RouteNames.HashImportFileContentRoute,
};
