// src/services/SetSortByInStoreFilters.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from './Router.service';

export default async function (sort_by?: string) {
	const updateQuery = function () {
		return new Promise((resolve) => {
			StoreService.dispatch('set', {
				stateProperty: 'sortByFiltersReady',
				value: true,
			}).then(() => {
				resolve(true);
			});
		});
	};

	return new Promise((resolve) => {
		StoreService.dispatch('set', {
			stateProperty: 'sortByFiltersReady',
			value: false,
		}).then(() => {
			// set with argument
			if (sort_by !== undefined) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'sort_by',
					value: sort_by,
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
			// value is in URL
			else if (
				StoreService.state.checkURLFilters &&
				RouterService.currentRoute.value.query.sort_by !== undefined
			) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'sort_by',
					value: RouterService.currentRoute.value.query.sort_by as string,
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
			else {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'sort_by',
					value: 'iov',
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
