// src/services/SetSpaceInStore.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from '@/services/Router.service';
import { SpaceType } from '@/types/Space.type';

export default async function (space?: SpaceType | null) {
	return new Promise((resolve) => {
		console.log(
			'%c [Service] Set space in store... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'spaceLoaded',
			value: false,
		}).then(() => {
			/* set space passed by argument */
			if (space !== undefined) {
				StoreService.dispatch('set', {
					stateProperty: 'space',
					value: space,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'spaceLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			/* set space passed by url */
			else if (
				RouterService.currentRoute.value.params.space_id &&
				StoreService.state.spaces!.find(
					(space: SpaceType) =>
						space._id === RouterService.currentRoute.value.params.brand_id
				) !== undefined
			) {
				StoreService.dispatch('set', {
					stateProperty: 'space',
					value: StoreService.state.spaces!.find(
						(space: SpaceType) =>
							space._id === RouterService.currentRoute.value.params.space_id
					),
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'spaceLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			/* set default space */
			else {
				StoreService.dispatch('set', {
					stateProperty: 'space',
					value: StoreService.state.spaces![0],
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'spaceLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
