// src/services/GetRoles.service.ts

import { StoreService } from '@/services/Store.service';
import { getPermission } from '@/services/Permission.service';
import { API } from '@/services/API.service';
import { getAlerts } from '@/services/Alerts.service';

export default async function () {
	return new Promise((resolve, reject) => {
		StoreService.dispatch('set', {
			stateProperty: 'rolesLoaded',
			value: false,
		}).then(() => {
			if (getPermission.canListSpaceUsers()) {
				console.log(
					'%c [Service] Getting space roles... ',
					'color: #0000FF;font-weight:bold;'
				);
				API.spaceRoles('get', null, {
					space_id: StoreService.state.space!._id,
				})?.then((spaceResponse: any) => {
					if (getPermission.canListOrganizationUsers()) {
						console.log(
							'%c [Service] Getting organization roles... ',
							'color: #0000FF;font-weight:bold;'
						);
						API.organizationRoles('get', null, {
							organization_id: StoreService.state.organization!._id,
						})?.then((organizationResponse: any) => {
							console.log(
								'%c [Service] Set organization and space roles in store... ',
								'color: #0000FF;font-weight:bold;'
							);
							StoreService.dispatch('set', {
								stateProperty: 'roles',
								value: organizationResponse.data.concat(spaceResponse.data),
							}).then(() => {
								StoreService.dispatch('set', {
									stateProperty: 'rolesLoaded',
									value: true,
								}).then(() => {
									resolve(true);
								});
							});
						}).catch((error: any) => {
							getAlerts.pushFromError(error)
							reject(error)
						});
					} else {
						console.log(
							'%c [Service] Set space roles in store... ',
							'color: #0000FF;font-weight:bold;'
						);
						StoreService.dispatch('set', {
							stateProperty: 'roles',
							value: spaceResponse.data,
						}).then(() => {
							StoreService.dispatch('set', {
								stateProperty: 'rolesLoaded',
								value: true,
							}).then(() => {
								resolve(true);
							});
						});
					}
				}).catch((error: any) => {
					getAlerts.pushFromError(error)
					reject(error)
				});
			} else {
				StoreService.dispatch('set', {
					stateProperty: 'rolesLoaded',
					value: false,
				}).then(() => {
					resolve(true);
				});
			}
		});
	});
}
