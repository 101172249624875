
import { defineComponent } from 'vue';

export default defineComponent({
	data() {
		return {
			menu: [
				{
					name: 'Media',
					items: [
						{
							name: 'Media_Name',
							link:
								'#' + this.$toolbox.slugify(this.$text('labels', 'Media_Name')),
						},
						{
							name: 'Media_Domain',
							link:
								'#' +
								this.$toolbox.slugify(this.$text('labels', 'Media_Domain')),
						},
						{
							name: 'Media_Family',
							link:
								'#' +
								this.$toolbox.slugify(this.$text('labels', 'Media_Family')),
						},
						{
							name: 'Media_Category',
							link:
								'#' +
								this.$toolbox.slugify(this.$text('labels', 'Media_Category')),
						},
						{
							name: 'Media_Ignore',
							link:
								'#' +
								this.$toolbox.slugify(this.$text('labels', 'Media_Ignore')),
						},
					],
				},
			],
		};
	},
	// watch: {
	// 	'$store.state.progressBarFinished': function (value) {
	// 		if (value === true) {
	// 			document
	// 				.getElementById(this.$route.hash.replace('#', '') + '-input')!
	// 				.focus();
	// 		}
	// 	},
	// },
	created() {
		if (this.$route.hash === '') {
			this.$router.push({
				name: this.$route.name?.toString(),
				hash: this.menu[0].items[0].link,
			});
		}
	},
});
