import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "error-layout",
  class: "min-h-full p-6 w-full container"
}
const _hoisted_2 = { id: "main" }
const _hoisted_3 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoComponent = _resolveComponent("LogoComponent")!
  const _component_ProgressBarComponent = _resolveComponent("ProgressBarComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_LogoComponent, {
          viewBox: "0 0 185 32",
          width: "150"
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ]),
    _createVNode(_component_ProgressBarComponent)
  ], 64))
}