import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import SetViewInStoreService from '@/services/SetViewInStore.service';
import { StoreService } from '@/services/Store.service';
import { ViewType } from '@/types/View.type';

export async function LoadViewMiddleware(to: any, next: any) {
	const updateView = function (view: ViewType | null) {
		return new Promise((resolve) => {
			SetViewInStoreService(view).then(() => {
				resolve(true);
			});
		});
	};

	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] View loading... ',
			'color: #FF0000;font-weight:bold;'
		);
		// view_id is provided
		if (to.params.view_id) {
			// replace provided view
			const view = StoreService.state.views.find(
				(entity: ViewType) => entity._id === to.params.view_id
			);
			if (view) {
				updateView(view).then(() => {
					resolve(true);
				});
			} else {
				// redirect on not found route
				RouterService.push({
					name: getConfig('RouteConfig', 'Error404RouteName'),
				}).then(() => {
					next(false);
				});
			}
		}
		// view_id is not provided but space has overview
		else if (
			StoreService.state.views.find(
				(entity: ViewType) =>
					entity.title?.toLowerCase() ===
						getConfig('VueConfig', 'ViewNameOverview').toLowerCase() &&
					entity.space_id === null
			) !== undefined
		) {
			updateView(
				StoreService.state.views.find(
					(entity: ViewType) =>
						entity.title?.toLowerCase() ===
							getConfig('VueConfig', 'ViewNameOverview').toLowerCase() &&
						entity.space_id === null
				) as ViewType
			).then(() => {
				resolve(true);
			});
		}
		// view_id is not provided but space has views
		else if (StoreService.state.views.length > 0) {
			updateView(StoreService.state.views[0]).then(() => {
				resolve(true);
			});
		}
		// view_id is not provided and space has not views
		else {
			updateView(null).then(() => {
				resolve(true);
			});
		}
	});
}
