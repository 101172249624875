
import { defineComponent } from 'vue';

export default defineComponent({
	/**
	 * business logic only
	 */
	props: {
		aClass: {
			type: String,
		},
		fill: {
			type: String,
		},
		svgClass: {
			type: String,
		},
		viewBox: {
			type: String,
		},
		width: {
			type: String,
		},
	},
	computed: {
		routeName(): string | null {
			if (
				this.$route.name === this.$config('RouteConfig', 'RegisterRouteName') &&
				this.$route.hash === '#success'
			) {
				return this.$config('RouteConfig', 'LoginRouteName');
			} else if (
				this.$route.name === this.$config('RouteConfig', 'PasswordRouteName') &&
				this.$route.hash === '#success'
			) {
				return this.$config('RouteConfig', 'LoginRouteName');
			} else if (
				this.$route.name ===
					this.$config('RouteConfig', 'PasswordResetRouteName') &&
				this.$route.hash === '#errorToken'
			) {
				return this.$config('RouteConfig', 'PasswordRouteName');
			}
			return null;
		},
	},
});
