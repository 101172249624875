import { AlertType } from '@/types/Alert.type';
import { BrandType } from '@/types/Brand.type';
import { CampaignType } from '@/types/Campaign.type';
import { FiltersType } from '@/types/Filters.type';
import { LocationQueryRaw } from 'vue-router';
import { ImportFileModeType } from '@/types/ImportFileMode.type';
import { MediaType } from '@/types/Media.type';
import { OrganizationType } from '@/types/Organization.type';
import { UserType } from '@/types/User.type';
import { QueryType } from '@/types/Query.type';
import { RoleType } from '@/types/Role.type';
import { RouteType } from '@/types/Route.type';
import { SpaceType } from '@/types/Space.type';
import { ViewType } from '@/types/View.type';
import { createStore } from 'vuex';
import { ProductType } from '@/types/Product.type';
import { AnalyticsAccountType } from '@/types/AnalyticsAccount.type';
import { MetadataType } from '@/types/Metadata.type';

interface State {
	alerts: Array<AlertType>;
	analyticsViews: Array<AnalyticsAccountType> | null;
	analyticsViewsLoaded: Boolean;
	brand: BrandType | null;
	brands: Array<BrandType>;
	brandLoaded: Boolean;
	brandsLoaded: Boolean;
	brandIdsFiltersReady: Boolean;
	cart: ProductType | null;
	campaign: CampaignType | null;
	campaigns: Array<CampaignType> | [];
	campaignLoaded: Boolean;
	campaignsLoaded: Boolean;
	categoriesFiltersReady: Boolean;
	checkURLFilters: Boolean;
	compare: Boolean;
	compareDatesLoaded: Boolean;
	current_form: any | null;
	current_route: RouteType | null;
	datesLoaded: Boolean;
	endDateFiltersReady: Boolean;
	endDateCompareFiltersReady: Boolean;
	filters: FiltersType;
	filtersAlreadyLoaded: Boolean;
	filtersPreview: FiltersType;
	filtersOpened: Boolean;
	filtersQuery: LocationQueryRaw | null;
	filtersReady: Boolean;
	focusMediaFiltersReady: Boolean;
	granularityFiltersReady: Boolean;
	importFileMode: ImportFileModeType;
	isSubmitting: boolean;
	keywordsLoaded: boolean;
	keywordsFiltersReady: Boolean;
	keywordIdsFiltersReady: Boolean;
	languagesFiltersReady: Boolean;
	last_product_route: RouteType | null;
	loadingContent: boolean;
	logged: boolean;
	media: MediaType | null;
	mediaLoaded: Boolean;
	medias: Array<MediaType>;
	mediasLoaded: Boolean;
	metadata: MetadataType;
	next_route: RouteType | null;
	organization: OrganizationType | null;
	organizations: Array<OrganizationType>;
	organizationExists: Boolean;
	organizationLoaded: Boolean;
	organizationsLoaded: Boolean;
	pageFiltersReady: Boolean;
	numFiltersReady: Boolean;
	previous_route: RouteType | null;
	productLoaded: Boolean;
	profile: UserType | null;
	profileLoaded: Boolean;
	profileCompleted: Boolean;
	progressBarFinished: Boolean;
	query: QueryType | null;
	redirecting: Boolean;
	role: RoleType | null;
	roleLoaded: Boolean;
	roles: Array<RoleType>;
	rolesLoaded: Boolean;
	space: SpaceType | null;
	spaces: Array<SpaceType>;
	spaceExists: Boolean;
	spaceLoaded: Boolean;
	spacesLoaded: Boolean;
	startDateFiltersReady: Boolean;
	startDateCompareFiltersReady: Boolean;
	switchBrands: Boolean;
	unsaved_changes: Boolean;
	user_token: string | null;
	view: ViewType | null;
	views: Array<ViewType>;
	viewLoaded: Boolean;
	viewsLoaded: Boolean;
	wanted_route: RouteType | null;
}
export const StoreService = createStore<State>({
	state: {
		alerts: [],
		analyticsViews: null,
		analyticsViewsLoaded: false,
		brand: null,
		brands: [],
		brandLoaded: false,
		brandsLoaded: false,
		brandIdsFiltersReady: false,
		campaign: null,
		campaigns: [],
		campaignLoaded: false,
		campaignsLoaded: false,
		cart: null,
		categoriesFiltersReady: false,
		checkURLFilters: true,
		compare: false,
		compareDatesLoaded: false,
		current_form: null,
		current_route: null,
		datesLoaded: false,
		endDateFiltersReady: false,
		endDateCompareFiltersReady: false,
		filters: {},
		filtersAlreadyLoaded: true,
		filtersPreview: {},
		filtersOpened: false,
		filtersQuery: null,
		filtersReady: false,
		focusMediaFiltersReady: false,
		granularityFiltersReady: false,
		importFileMode: { value: false },
		isSubmitting: false,
		keywordsLoaded: false,
		keywordsFiltersReady: false,
		keywordIdsFiltersReady: false,
		languagesFiltersReady: false,
		last_product_route: null,
		loadingContent: false,
		logged: false,
		media: null,
		mediaLoaded: false,
		medias: [],
		mediasLoaded: false,
		metadata: {
			contents: undefined,
			posts: undefined,
			limited: 0
		},
		next_route: null,
		organization: null,
		organizations: [],
		organizationExists: false,
		organizationLoaded: false,
		organizationsLoaded: false,
		pageFiltersReady: false,
		numFiltersReady: false,
		productLoaded: false,
		previous_route: null,
		profile: null,
		profileLoaded: false,
		profileCompleted: false,
		progressBarFinished: false,
		query: null,
		redirecting: false,
		role: null,
		roleLoaded: false,
		roles: [],
		rolesLoaded: false,
		space: null,
		spaces: [],
		spaceExists: false,
		spaceLoaded: false,
		spacesLoaded: false,
		startDateFiltersReady: false,
		startDateCompareFiltersReady: false,
		switchBrands: true,
		unsaved_changes: false,
		user_token: null,
		view: null,
		views: [],
		viewLoaded: false,
		viewsLoaded: false,
		wanted_route: null,
	},

	actions: {
		login({ commit }) {
			commit('login');
		},
		logout({ commit }) {
			commit('logout');
		},
		set({ commit }, { stateProperty, value }) {
			commit('set', { stateProperty, value });
		},
		update({ commit }, { stateProperty, stateChildProperty, value }) {
			commit('update', { stateProperty, stateChildProperty, value });
		},
	},

	mutations: {
		login(state) {
			state.logged = true;
		},
		logout(state) {
			state.logged = false;
		},
		set(state: any, object: any) {
			state[object.stateProperty] = object.value;
		},
		update(state: any, object: any) {
			if (state[object.stateProperty] === null) {
				state[object.stateProperty] = {};
			}
			if (typeof object.stateChildProperty === 'string') {
				state[object.stateProperty][object.stateChildProperty] = object.value;
			} else {
				state[object.stateProperty] = object.value;
			}
		},
	},
});

// set $text as global variable
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$store: any;
	}
}
