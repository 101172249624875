import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';

export async function RedirectRoleMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Role redirection ... ',
			'color: #FF0000;font-weight:bold;'
		);

		if (to.name === getConfig('RouteConfig', 'RoleRouteName')) {

			if (StoreService.state.role) {
				if (!to.params.role_id) {
					// Redirect on stored role update route
					RouterService.push({
						name: getConfig('RouteConfig', 'RoleUpdateRouteName'),
						params: {
							organization_id: to.params.organization_id,
							space_id: to.params.space_id,
							role_id: StoreService.state.role._id as string,
						},
					}).then(() => {
						next(false);
					});
				}
				else {
					// Redirect on asked role update route
					RouterService.push({
						name: getConfig('RouteConfig', 'RoleUpdateRouteName'),
						params: {
							organization_id: to.params.organization_id,
							space_id: to.params.space_id,
							role_id: to.params.role_id,
						},
					}).then(() => {
						next(false);
					});
				}
			}
			else {
				// Redirect on add role route
				RouterService.push({
					name: getConfig('RouteConfig', 'RoleAddRouteName'),
					params: {
						organization_id: to.params.organization_id,
						space_id: to.params.space_id,
					},
				}).then(() => {
					next(false);
				});
			}
		} else {
			resolve(true);
		}
	});
}
