import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "nav-item" }
const _hoisted_2 = ["data-bs-target", "aria-controls"]
const _hoisted_3 = { class: "ms-6" }
const _hoisted_4 = { class: "d-block py-3" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "ms-6 d-flex align-items-center" }
const _hoisted_8 = { class: "d-inline-block py-2 ms-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (category, indexCategory) => {
    return (_openBlock(), _createElementBlock("ul", {
      class: "navbar-nav mb-2",
      key: indexCategory
    }, [
      _createElementVNode("li", _hoisted_1, [
        _createElementVNode("a", {
          href: "#!",
          class: _normalizeClass(["nav-link py-0 d-flex justify-content-between align-items-center pe-3", 
					_ctx.$route.hash &&
					_ctx.menu
						.find((c) => c.name === category.name)
						.items.find((i) => i.link === _ctx.$route.hash)
						? 'active text-brand'
						: ''
				]),
          "data-bs-toggle": "collapse",
          "data-bs-target": '#' + _ctx.$toolbox.slugify(category.name) + '-submenu',
          "aria-expanded": "false",
          "aria-controls": _ctx.$toolbox.slugify(category.name) + '-submenu'
        }, [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$beautify.firstLetterUppercase(_ctx.$text('words', category.name))), 1)
          ])
        ], 10, _hoisted_2),
        _createElementVNode("ul", {
          class: _normalizeClass(["navbar-nav multi-collapse collapse", [
					_ctx.$route.hash &&
					_ctx.menu
						.find((c) => c.name === category.name)
						.items.find((i) => i.link === _ctx.$route.hash)
						? 'show'
						: '',
					_ctx.$route.hash === '' && indexCategory === 0 ? 'show' : '',
				]]),
          id: _ctx.$toolbox.slugify(category.name) + '-submenu'
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.items, (item, indexItem) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "nav-item",
              key: indexItem
            }, [
              _createElementVNode("a", {
                href: item.link,
                class: _normalizeClass(["nav-link py-0 font-light", 
							_ctx.$route.hash === item.link
								? 'active text-brand font-semibold'
								: ''
						])
              }, [
                _createElementVNode("span", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$beautify.firstLetterUppercase(_ctx.$text('labels', item.name))), 1)
                ])
              ], 10, _hoisted_6)
            ]))
          }), 128))
        ], 10, _hoisted_5)
      ])
    ]))
  }), 128))
}