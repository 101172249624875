import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';
import BuildQueryFilters from '@/services/BuildQueryFilters.service';

export async function RedirectFiltersQueryMiddleware(from: any, to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Filters query loading... ',
			'color: #FF0000;font-weight:bold;'
		);

		if (
			from.params.space_id &&
			getConfig('RouteConfig', 'FilterableRouteNames').includes(to.name) &&
			(
				!StoreService.state.filtersReady ||
				(JSON.stringify(to.query) === "{}" && !StoreService.state.checkURLFilters)
			)
		) {
			if (
				from.params.space_id !== to.params.space_id ||
				to.name !== from.name
			) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'page',
					value: 0,
				}).then(() => {
					StoreService.dispatch('update', {
						stateProperty: 'filters',
						stateChildProperty: 'num',
						value: 10,
					}).then(() => {
						RouterService.push({
							name: to.name as string,
							params: to.params,
							query: BuildQueryFilters(to.name)
						}).then(() => {
							next(false);
						});
					})
				});
			}
			else {
				RouterService.push({
					name: to.name as string,
					params: to.params,
					query: BuildQueryFilters(to.name)
				}).then(() => {
					next(false);
				});
			}
		}
		else {
			resolve(true)
		}
	});
}
