
import { defineComponent } from 'vue';
import InfoAlert from '@/components/alerts/InfoAlert.component.vue';
import SuccessAlert from '@/components/alerts/SuccessAlert.component.vue';
import WarningAlert from '@/components/alerts/WarningAlert.component.vue';
import ErrorAlert from '@/components/alerts/ErrorAlert.component.vue';

export default defineComponent({
	components: {
		InfoAlert,
		SuccessAlert,
		WarningAlert,
		ErrorAlert,
	},
});
