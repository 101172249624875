import GetRolesService from '@/services/GetRoles.service';
import { StoreService } from '@/services/Store.service';

export async function LoadRolesMiddleware(to: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Roles loading... ',
			'color: #FF0000;font-weight:bold;'
		);

		StoreService.dispatch('set', {
			stateProperty: 'rolesLoaded',
			value: false,
		}).then(() => {
			// get space roles
			GetRolesService().then(() => {
				// set roles in Store.state.roles
				StoreService.dispatch('set', {
					stateProperty: 'rolesLoaded',
					value: true,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'roleLoaded',
						value: false,
					}).then(() => {
						resolve(true);
					});
				});
			});
		});
	});
}
