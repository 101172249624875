
import { defineComponent } from 'vue';
import { NavBarMixin } from '@/mixins/NavBar.mixin';
import PublicNavBarVerticalPrivacyPolicyComponent from '@/components/navbars/PublicNavBarVerticalPrivacyPolicy.component.vue';

export default defineComponent({
	mixins: [NavBarMixin],
	components: {
		PublicNavBarVerticalPrivacyPolicyComponent,
	},
});
