import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';

export async function RedirectOrganizationMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Organization redirection ... ',
			'color: #FF0000;font-weight:bold;'
		);

		if (
			StoreService.state.organization &&
			(
				to.name === getConfig('RouteConfig', 'HomeRouteName') ||
				(
					to.name === getConfig('RouteConfig', 'OrganizationRouteName') &&
					!to.params.organization_id
				)
			)
		) {
			// Redirect on organization route
			RouterService.push({
				name: getConfig('RouteConfig', 'OrganizationRouteName'),
				params: {
					organization_id: StoreService.state.organization._id as string
				},
			}).then(() => {
				next(false);
			});
		}
		else {
			resolve(true)
		}
	});
}
