
import { defineComponent } from 'vue';
export default defineComponent({
	props: {
		competitor: {
			type: Object,
		},
		deactivate: {
			type: Boolean,
		},
		selected: {
			type: Boolean,
		},
	},
	data() {
		return {
			mousein: false,
		};
	},
});
