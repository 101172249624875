
import { defineComponent } from 'vue';

export default defineComponent({
	data() {
		return {
			menu: [
				{
					name: 'Profile',
					items: [
						{
							name: 'First_Name',
							link: '#first-name',
						},
						{
							name: 'Last_Name',
							link: '#last-name',
						},
					],
				},
				{
					name: 'Contact',
					items: [
						{
							name: 'Email',
							link: '#email',
						},
						{
							name: 'Phone_Number',
							link: '#phone-number',
						},
						{
							name: 'Address',
							link: '#address',
						},
						{
							name: 'City',
							link: '#city',
						},
						{
							name: 'Country',
							link: '#country',
						},
						{
							name: 'ZIP',
							link: '#ZIP',
						},
					],
				},
				{
					name: 'Job',
					items: [
						{
							name: 'Job_Title',
							link: '#job-title',
						},
						{
							name: 'Company_Name',
							link: '#company-name',
						},
					],
				},
			],
		};
	},
	created() {
		if (this.$route.hash === '') {
			this.$router.push({
				name: this.$route.name?.toString(),
				hash: this.menu[0].items[0].link,
			});
		}
	},
});
