
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		loaderClass: {
			type: String,
		},
	},
});
