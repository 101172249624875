// src/configs/App.config.ts

// import AppConfig type
import type { AppConfigType } from '@/types/AppConfig.type';

// hydrate and export AppConfig object
export const AppConfig: AppConfigType = {
	AuthorizedContentFileTypes: [
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	],
	AuthorizedContentFileExtensions: ['.xlsx'],
	BackendAPIPrefix: process.env.VUE_APP_BACKEND_API_URL,
	CookieDomain: process.env.NODE_ENV === "production" ? "app.datakudo.com" : "dev.datakudo.com",
	BrandName: {
		min: 2,
	},
	Competitors: {
		min: 0,
		max: 10,
	},
	ExcludeKeywords: {
		min: 0,
		max: 10,
	},
	FiltersLanguages: ['fr', 'en', 'es', 'it', 'de'],
	SocialSources: ['twitter', 'linkedin'],
	FiltersCategories: ['news', 'web', 'job', 'market'],
	FiltersDefaultCategories: ['news', 'web'],
	FirstOrganizationName: 'My Organization',
	FirstSpaceName: 'My space',
	InputLength: {
		address: {
			min: 5,
			max: 155,
		},
		city: {
			min: 2,
			max: 55,
		},
		company_name: {
			min: 2,
			max: 55,
		},
		firstname: {
			min: 2,
			max: 55,
		},
		job_title: {
			min: 2,
			max: 55,
		},
		lastname: {
			min: 2,
			max: 55,
		},
		zip: {
			min: 1,
			max: 10,
		},
	},
	KeywordName: {
		min: 2,
	},
	KeywordVariations: {
		min: 0,
		max: 4,
	},
	MessageKeywords: {
		min: 0,
		max: 10,
	},
	Name: 'datakudo',
	ObjectName: 'AppConfig',
	ProfileMandatoriesFields: ['firstname', 'lastname', 'email', 'company'],
	Protocol: ['https://', 'http://'],
	SEOKeywords: {
		min: 0,
		max: 5,
	},
	Since: '2020',
	SpaceCategories: ["brands", "topics"],
	SpokeKeywords: {
		min: 0,
		max: 10,
	},
	TimeBetweenTwoEmailRequesting: 10,
	UserTokenLocalStorageKey: 'user_token',
};
