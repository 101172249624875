// src/services/SetPageInStoreFilters.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from './Router.service';
import { getConfig } from './Config.service';

export default async function (page?: number) {
	const updateQuery = function () {
		return new Promise((resolve) => {
			StoreService.dispatch('set', {
				stateProperty: 'pageFiltersReady',
				value: true,
			}).then(() => {
				resolve(true);
			});
		});
	};

	return new Promise((resolve) => {
		StoreService.dispatch('set', {
			stateProperty: 'pageFiltersReady',
			value: false,
		}).then(() => {
			// set with argument
			if (page !== undefined) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'page',
					value: page,
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
			// value is in URL
			else if (
				StoreService.state.checkURLFilters === true &&
				RouterService.currentRoute.value.query.page !== undefined
			) {
				const page: number = parseInt(RouterService.currentRoute.value.query.page as string)
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'page',
					value: isNaN(page)? 0: page,
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
			else {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'page',
					value: 0,
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
