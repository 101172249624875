import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';

export async function RedirectMediaMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Media redirection ... ',
			'color: #FF0000;font-weight:bold;'
		);

		if (to.name === getConfig('RouteConfig', 'MediaRouteName')) {

			if (StoreService.state.media) {
				if (!to.params.media_id) {
					// Redirect on stored media update route
					RouterService.push({
						name: getConfig('RouteConfig', 'MediaUpdateRouteName'),
						params: {
							organization_id: to.params.organization_id,
							space_id: to.params.space_id,
							media_id: StoreService.state.media._id as string,
						},
					}).then(() => {
						next(false);
					});
				}
				else {
					// Redirect on asked media update route
					RouterService.push({
						name: getConfig('RouteConfig', 'MediaUpdateRouteName'),
						params: {
							organization_id: to.params.organization_id,
							space_id: to.params.space_id,
							media_id: to.params.media_id,
						},
					}).then(() => {
						next(false);
					});
				}
			}
			else {
				// Redirect on add media route
				RouterService.push({
					name: getConfig('RouteConfig', 'MediaAddRouteName'),
					params: {
						organization_id: to.params.organization_id,
						space_id: to.params.space_id,
					},
				}).then(() => {
					next(false);
				});
			}
		} else {
			resolve(true);
		}
	});
}
