import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "navbar-horizontal",
  class: "border-bottom d-flex justify-content-between px-5 align-items-center sticky-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.NavBarHorizontalChildComponent)))
  ]))
}