// src/services/debug.service.ts

// import App from vue to create a plugin
import { App, Plugin } from 'vue';

export function debug(string: string, type?: string): void {
	console.log(string);
}

// create and export config service
export const DebugService: Plugin = {
	// required method to set config service as plugin
	install: (app: App) => {
		app.config.globalProperties.$debug = debug;
	},
};

// set $text as global variable
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$debug: any;
	}
}
