
import { defineComponent } from 'vue';
import LoaderSpinnerComponent from '@/components/LoaderSpinner.component.vue';

export default defineComponent({
	/**
	 * business logic only
	 */
	props: {
		disabled: {
			type: Boolean,
		},
		icon: {
			type: String,
		},
		iconEnd: {
			type: String,
		},
		loaderClass: {
			type: String,
		},
		loading: {
			type: Boolean,
		},
		label: {
			type: String,
		},
		labelClass: {
			type: String,
		},
		type: {
			type: String,
		},
	},

	components: {
		LoaderSpinnerComponent,
	},
});
