// src/services/GetBrands.service.ts

import { StoreService } from '@/services/Store.service';

// start/end submitting state
export default async function (boolean: boolean) {
	return new Promise((resolve) => {
		if (boolean === true) {
			console.log(
				'%c [Service] Submitting form... ',
				'color: #0000FF;font-weight:bold;'
			);
		}
		if (boolean === false) {
			console.log(
				'%c [Service] Stop submitting form... ',
				'color: #0000FF;font-weight:bold;'
			);
		}
		StoreService.dispatch('set', {
			stateProperty: 'isSubmitting',
			value: boolean,
		}).then(() => {
			resolve(true);
		});
	});
}
