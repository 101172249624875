
import { defineComponent } from 'vue';
import { NavBarMixin } from '@/mixins/NavBar.mixin';
import ProductNavBarHorizontalDefaultComponent from '@/components/navbars/ProductNavBarHorizontalDefault.component.vue';
import ProductNavBarHorizontalSettingComponent from '@/components/navbars/ProductNavBarHorizontalSetting.component.vue';
import ProductNavBarHorizontalClosableComponent from '@/components/navbars/ProductNavBarHorizontalClosable.component.vue';
import { AuthenticationService } from '@/services/Authentication.service';

export default defineComponent({
	/**
	 * business logic only
	 */
	mixins: [NavBarMixin],
	components: {
		ProductNavBarHorizontalDefaultComponent,
		ProductNavBarHorizontalClosableComponent,
		ProductNavBarHorizontalSettingComponent,
	},
	methods: {
		logout() {
			AuthenticationService.logoutUser();
		},
	},
});
