// src/services/SetKeywordIdsInStoreFilter.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from './Router.service';
import { getConfig } from '@/services/Config.service';
import SetPageInStoreFiltersService from './SetPageInStoreFilters.service';

export default async function (categories?: Array<string>) {
	const updateQuery = function (resetPage: boolean = false) {
		return new Promise((resolve) => {
			if (resetPage) {
				SetPageInStoreFiltersService(0)
					.then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'categoriesFiltersReady',
							value: true,
						}).then(() => {
							resolve(true);
						});
					})
			}
			else {
				StoreService.dispatch('set', {
					stateProperty: 'categoriesFiltersReady',
					value: true,
				}).then(() => {
					resolve(true);
				});
			}
		});
	};

	return new Promise((resolve) => {
		console.log(
			'%c [Service] Set categories in store... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'categoriesFiltersReady',
			value: false,
		}).then(() => {
			if (categories !== undefined) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'categories',
					value: categories,
				}).then(() => {
					updateQuery(true).then(() => {
						resolve(true);
					});
				});
			}
			/* keep categories filters */
			else if (StoreService.state.filters.categories) {
				updateQuery(false).then(() => {
					resolve(true);
				});
			}
			/* init categories filters */
			else {
				let categories: Array<string> = []
				if (
					StoreService.state.checkURLFilters === true &&
					RouterService.currentRoute.value.query.categories !== undefined
				) {
					categories = (
						RouterService.currentRoute.value.query.categories as string
					).replace('%2C', ',').split(',');
				}
				else {
					categories = getConfig('AppConfig', 'FiltersDefaultCategories')
				}
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'categories',
					value: categories,
				}).then(() => {
					updateQuery(!StoreService.state.checkURLFilters).then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
