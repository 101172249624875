import CreateDefaultSpaceService from '@/services/CreateDefaultSpace.service';
import GetSpacesService from '@/services/GetSpaces.service';
import { StoreService } from '@/services/Store.service';

export async function LoadSpacesMiddleware(to: any, next: any) {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Middleware] Spaces loading... ',
			'color: #FF0000;font-weight:bold;'
		);

		StoreService.dispatch('set', {
			stateProperty: 'spacesLoaded',
			value: false,
		}).then(() => {
			// check if user has spaces in organization
			GetSpacesService(StoreService.state.organization!._id).then(() => {
				// user has at least one space in organization
				if (StoreService.state.spaces!.length > 0) {
					StoreService.dispatch('set', {
						stateProperty: 'spacesLoaded',
						value: true,
					}).then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'spaceLoaded',
							value: false,
						}).then(() => {
							resolve(true)
						});
					});
				}
				// user doesn't have space in organization
				else {
					if (to.params.space_id) {
						resolve(true);
					} else {
						// create one default space for user  if no space_id
						CreateDefaultSpaceService().then((response: any) => {
							// set spaces in Store.state.spaces
							StoreService.dispatch('set', {
								stateProperty: 'spaces',
								value: [response.data],
							}).then(() => {
								StoreService.dispatch('set', {
									stateProperty: 'spacesLoaded',
									value: true,
								}).then(() => {
									StoreService.dispatch('set', {
										stateProperty: 'spaceLoaded',
										value: false,
									}).then(() => {
										resolve(true)
									});
								});
							});
						})
						.catch((error: any) => {
							console.log(error);
						});
					}
				}
			})
			.catch((error: any) => {
				console.log(error);
			});
		});
	});
}
