// src/services/SetStartDateInStore.service.ts

import { StoreService } from '@/services/Store.service';
import moment from 'moment';
import { getLimitation } from './Limitation.service';
import SetPageInStoreFiltersService from './SetPageInStoreFilters.service';

export default async function (startdate?: string | null) {
	const updateQuery = function (resetPage: boolean = false) {
		return new Promise((resolve) => {
			if (resetPage) {
				SetPageInStoreFiltersService(0)
					.then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'startDateFiltersReady',
							value: true,
						}).then(() => {
							resolve(true);
						});
					})
			}
			else {
				StoreService.dispatch('set', {
					stateProperty: 'startDateFiltersReady',
					value: true,
				}).then(() => {
					resolve(true);
				});
			}
		});
	};

	return new Promise((resolve) => {
		StoreService.dispatch('set', {
			stateProperty: 'startDateFiltersReady',
			value: false,
		}).then(() => {
			if (startdate !== undefined) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'startdate',
					value: startdate,
				}).then(() => {
					updateQuery(true).then(() => {
						resolve(true);
					});
				});
			}
			// set with url (arrive on product)
			else if (
				StoreService.state.checkURLFilters === true &&
				new URLSearchParams(window.location.search).get('startdate') &&
				!getLimitation.datesLimitedAccess()
			) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'startdate',
					value: moment(
						new URLSearchParams(window.location.search).get('startdate'),
						'YYYY-MM-DD'
					)
						.startOf('day')
						.format('YYYY-MM-DD'),
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
			else if (StoreService.state.filters?.startdate) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'startdate',
					value: moment(
						StoreService.state.filters?.startdate,
						'YYYY-MM-DD'
					)
						.startOf('day')
						.format('YYYY-MM-DD'),
				}).then(() => {
					updateQuery(true).then(() => {
						resolve(true);
					});
				});
			}
			else {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'startdate',
					value: getLimitation.getHistorical() <= 20 ? moment()
						.subtract(1, 'week')
						.startOf('day')
						.format('YYYY-MM-DD'): moment()
						.subtract(1, 'month')
						.startOf('month')
						.format('YYYY-MM-DD'),
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
