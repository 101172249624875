import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "onboarding-layout",
  class: "min-h-full p-6 w-full container mx-auto"
}
const _hoisted_2 = { class: "w-full d-flex justify-content-between" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_4 = { class: "d-flex h-full w-full" }
const _hoisted_5 = {
  id: "main-wrapper",
  class: "w-full d-flex"
}
const _hoisted_6 = {
  id: "main",
  class: "col-8 mx-auto d-flex mt-32 position-relative"
}
const _hoisted_7 = {
  id: "content",
  class: "p-6 pt-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoComponent = _resolveComponent("LogoComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_ProgressBarComponent = _resolveComponent("ProgressBarComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LogoComponent, {
          viewBox: "0 0 185 32",
          width: "93",
          aClass: "d-flex align-items-center ms-2"
        }),
        (!_ctx.hideSubmitAndExit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_ButtonComponent, {
                label: _ctx.$beautify.firstLetterUppercase(_ctx.$text('actions', 'Save_And_Exit')),
                class: "ms-2 btn-success btn-sm py-2",
                loading: _ctx.$store.state.isSubmitting,
                type: "submit",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.state.isSubmitting === false ? _ctx.handleSubmitAndExit() : '')),
                loaderClass: "light"
              }, null, 8, ["label", "loading"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_ProgressBarComponent)
  ], 64))
}