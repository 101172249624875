import { getConfig } from '@/services/Config.service';
import { getPermission } from '@/services/Permission.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';

export async function RedirectOnboardingMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Onboarding redirection ... ',
			'color: #FF0000;font-weight:bold;'
		);

		if (
			to.name !== getConfig('RouteConfig', 'OrganizationUpdateRouteName') &&
			to.name !== getConfig('RouteConfig', 'OrganizationRolesRouteName') &&
			to.name !== getConfig('RouteConfig', 'SpaceUpdateRouteName') &&
			to.name !== getConfig('RouteConfig', 'SpaceRolesRouteName') &&
			to.name !== getConfig('RouteConfig', 'RoleAddRouteName') &&
			to.name !== getConfig('RouteConfig', 'RoleUpdateRouteName') &&
			to.name !== getConfig('RouteConfig', 'OnboardingRouteName') &&
			to.name !== getConfig('RouteConfig', 'SubscribeRouteName') &&
			to.name !== getConfig('RouteConfig', 'CheckoutRouteName') &&
			getPermission.canCreateEntity() &&
			StoreService.state.space &&
			(
				!StoreService.state.space.onboarding ||
				StoreService.state.space.onboarding === 'pending' || 
				StoreService.state.space.onboarding === 'space-category' ||
				StoreService.state.space.onboarding === 'brand-name' ||
				StoreService.state.space.onboarding === 'country' ||
				StoreService.state.space.onboarding === 'competitors'
			)
		) {
			// Redirect on organization route
			RouterService.push({
				name: getConfig('RouteConfig', 'OnboardingRouteName'),
				params: {
					organization_id: to.params.organization_id,
					space_id: StoreService.state.space._id as string
				},
			}).then(() => {
				next(false);
			});
		}
		else {
			resolve(true)
		}
	});
}
