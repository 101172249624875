import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import SetRoleInStoreService from '@/services/SetRoleInStore.service';
import { StoreService } from '@/services/Store.service';
import { RoleType } from '@/types/Role.type';

export async function LoadRoleMiddleware(to: any, next: any) {

	const updateRole = function (role: RoleType | null) {
		return new Promise((resolve) => {
			SetRoleInStoreService(role).then(() => {
				resolve(true)
			});
		});
	};

	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Role loading... ',
			'color: #FF0000;font-weight:bold;'
		);
		// role_id is provided
		if (to.params.role_id) {
			// replace provided role
			const role = StoreService.state.roles.find(
				(entity: RoleType) =>
					entity._id === to.params.role_id
			)
			if (role) {
				updateRole(role).then(() => {
					resolve(true)
				});
			}
			else {
				// redirect on not found route
				RouterService.push({
					name: getConfig('RouteConfig', 'ErrorNotFoundRouteName'),
				}).then(() => {
					next(false);
				});
			}
		}
		// role_id is not provided and space has not roles
		else {
			updateRole(null).then(() => {
				resolve(true)
			});
		}
	});
}
