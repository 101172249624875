import { getConfig } from '@/services/Config.service';
import { StoreService } from '@/services/Store.service';
import { UserType } from '@/types/User.type';

export async function CheckProfileMiddleware() {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Middleware] Profile completion verification... ',
			'color: #FF0000;font-weight:bold;'
		);

		// check if profile is completed
		const profile: UserType = StoreService.state.profile as UserType;
		let profileCompleted: Boolean = true;
		getConfig('AppConfig', 'ProfileMandatoriesFields').forEach(
			(field: string) => {
				if (
					profile[field] === null ||
					profile[field] === undefined ||
					profile[field].trim() === ''
				) {
					profileCompleted = false;
				}
			}
		);
		if (profileCompleted === false) {
			StoreService.dispatch('set', {
				stateProperty: 'profileLoaded',
				value: false,
			}).then(() => {
				StoreService.dispatch('set', {
					stateProperty: 'profileCompleted',
					value: false,
				}).then(() => {
					resolve(true);
				});
			});
		} else {
			StoreService.dispatch('set', {
				stateProperty: 'profileCompleted',
				value: true,
			}).then(() => {
				resolve(true);
			});
		}
	});
}
