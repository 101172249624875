// src/mixins/Modal.mixin.ts
import { defineComponent } from 'vue';
export default defineComponent({
	/**
	 * business logic only
	 */
	props: {
		action1: {
			type: String,
		},
		action2: {
			type: String,
		},
		text: {
			type: String,
		},
		title: {
			type: String,
		},
	},
});
