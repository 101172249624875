
import { defineComponent } from 'vue';
import DropdownComponent from '@/components/Dropdown.component.vue';
import { Dropdown, Modal } from 'bootstrap';
import LoaderSpinnerComponent from '@/components/LoaderSpinner.component.vue';

export default defineComponent({
	/**
	 * business logic only
	 */
	components: {
		DropdownComponent,
		LoaderSpinnerComponent
	},
	mounted() {
		var dropdownElementList = [].slice.call(
			document.querySelectorAll('.dropdown-toggle')
		);
		if (dropdownElementList.length > 0) {
			dropdownElementList.map(function (dropdownToggleEl) {
				return new Dropdown(dropdownToggleEl);
			});
		}
	},
	methods: {
		switchSpace(space_id: string) {
			/* redirect to space */
			this.$router.push({
				name: this.$config('RouteConfig', 'SpaceRouteName') as string,
				params: {
					organization_id: this.$route.params.organization_id,
					space_id: space_id,
				},
			});
		},
		showPaymentModal() {
			var modalRequirePayment = new Modal(
				document.getElementById(
					'modal-organization-required-payement'
				) as Element
			);
			modalRequirePayment.show();
		},
	},
});
