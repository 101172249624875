
import { defineComponent } from 'vue';
import ButtonComponent from '@/components/buttons/Button.component.vue';
import { StoreService } from '@/services/Store.service';
import { FiltersType } from '@/types/Filters.type';
import SetGranularityInStoreFilters from '@/services/SetGranularityInStoreFilters.service';
import SetLanguagesInStoreFilters from '@/services/SetLanguagesInStoreFilters.service';
import SetCategoriesInStoreFilters from '@/services/SetCategoriesInStoreFilters.service';
import SetFocusMediaInStoreFilters from '@/services/SetFocusMediaInStoreFilters.service';
import GetKeywordsService from '@/services/GetKeywords.service';
import SetKeywordIdsInStoreFiltersService from '@/services/SetKeywordIdsInStoreFilters.service';
import CheckFiltersService from '@/services/CheckFilters.service';
import SetSocialSourceInStoreFiltersService from "@/services/SetSocialSourceInStoreFilters.service";

type dataType = {
	filters: FiltersType;
	initialFilters: FiltersType;
	isSubmittingFilters: boolean;
	saveButtonDisabled: boolean;
};

export default defineComponent({
	data(): dataType {
		return {
			filters: {},
			initialFilters: {},
			isSubmittingFilters: false,
			saveButtonDisabled: false,
		};
	},
	components: {
		ButtonComponent,
	},
	created() {

      SetGranularityInStoreFilters().then(() => {
        SetLanguagesInStoreFilters().then(() => {
          SetCategoriesInStoreFilters().then(() => {
            SetFocusMediaInStoreFilters().then(() => {
              GetKeywordsService().then(() => {
                SetKeywordIdsInStoreFiltersService().then(() => {
                  CheckFiltersService().then(() => {
                    SetSocialSourceInStoreFiltersService().then(() => {
                      this.filters = JSON.parse(JSON.stringify(
                          StoreService.state.filters
                      ))
                      this.initialFilters = JSON.parse(JSON.stringify(
                          StoreService.state.filters
                      ))
                    })
                  })
                });
              });
            });
          });
        });
      });
	},
	watch: {
		'$store.state.keywordIdsFiltersReady': function (value: boolean, old: boolean) {
			if (value && !old) {
				this.filters = JSON.parse(JSON.stringify(StoreService.state.filters))
				this.initialFilters = JSON.parse(JSON.stringify(StoreService.state.filters))
			}
		},
		filters: {
			handler(value: FiltersType) {
				if (this.$toolbox.entitiesAreDifferent(value, this.initialFilters)) {
					this.saveButtonDisabled = false;
				} else {
					this.saveButtonDisabled = true;
				}
			},
			deep: true,
		},
		'$route.params.space_id': function (value, old) {
			if (value !== old)
				SetLanguagesInStoreFilters();
				SetCategoriesInStoreFilters();
		},
		'$route.query': {
			handler() { this.handleUpdate() },
			deep: true,
		},
	},
	methods: {
		async handleSubmit() {
			this.isSubmittingFilters = true;
			const filters = JSON.parse(JSON.stringify(this.filters));
			await SetLanguagesInStoreFilters(filters.languages)
			await SetCategoriesInStoreFilters(filters.categories)
			await SetKeywordIdsInStoreFiltersService(filters.keyword_ids);
      await SetSocialSourceInStoreFiltersService(filters.social_source)
      await CheckFiltersService();
			this.filters = JSON.parse(JSON.stringify(this.$store.state.filters));
			this.initialFilters = JSON.parse(
				JSON.stringify(this.$store.state.filters)
			);
			this.isSubmittingFilters = false;
			this.saveButtonDisabled = true;
		},
		async handleUpdate() {
			this.filters = JSON.parse(JSON.stringify(this.$store.state.filters));
			this.initialFilters = JSON.parse(
				JSON.stringify(this.$store.state.filters)
			);
		},
		switchKeyword(keyword_id: string) {
			if (!this.filters.keyword_ids) {
				this.filters.keyword_ids = [keyword_id];
			} else if (this.filters.keyword_ids.includes(keyword_id)) {
				this.filters.keyword_ids = this.filters.keyword_ids.filter(
					(id: string) => id !== keyword_id
				);
			} else {
				this.filters.keyword_ids.push(keyword_id);
			}
		},
    switchSocialSource(source: string) {
      if (!this.filters.social_source) {
        this.filters.social_source = [source];
      } else if (this.filters.social_source.includes(source)) {
        this.filters.social_source = this.filters.social_source.filter(
            (id: string) => id !== source
        );
      } else {
        this.filters.social_source.push(source);
      }
    },
		switchLanguages(language: string) {
			if (!this.filters.languages) {
				this.filters.languages = [language];
			} else if (this.filters.languages.includes(language)) {
				this.filters.languages = this.filters.languages.filter(
					(id: string) => id !== language
				);
			} else {
				this.filters.languages.push(language);
			}
		},
		switchCategories(category: string) {
			if (!this.filters.categories) {
				this.filters.categories = [category];
			} else if (this.filters.categories.includes(category)) {
				this.filters.categories = this.filters.categories.filter(
					(id: string) => id !== category
				);
			} else {
				this.filters.categories.push(category);
			}
		},
    isSocialView() {
      return this.$store.state?.view?.social === true
    },
	},
});
