// src/configs/Countries.config.ts

// import AppConfig type
import type { CountriesConfigType } from '@/types/CountriesConfig.type';

// hydrate and export CountriesConfig object
export const CountriesConfig: CountriesConfigType = {
    af: { domain: 'google.com.af', language: 'ps', country: 'Afghanistan' }, 
    al: { domain: 'google.al', language: 'sq', country: 'Albania' }, 
    dz: { domain: 'google.dz', language: 'ar', country: 'Algeria' }, 
    as: { domain: 'google.as', language: 'en', country: 'American Samoa' }, 
    ad: { domain: 'google.ad', language: 'ca', country: 'Andorra' }, 
    ao: { domain: 'google.co.ao', language: 'pt', country: 'Angola' }, 
    ai: { domain: 'google.com.ai', language: 'en', country: 'Anguilla' }, 
    ag: { domain: 'google.com.ag', language: 'en', country: 'Antigua and Barbuda' }, 
    ar: { domain: 'google.com.ar', language: 'es', country: 'Argentina' }, 
    am: { domain: 'google.am', language: 'hy', country: 'Armenia' }, 
    ac: { domain: 'google.ac', language: 'en', country: 'Ascension Island' }, 
    au: { domain: 'google.com.au', language: 'en', country: 'Australia' }, 
    at: { domain: 'google.at', language: 'de', country: 'Austria' }, 
    az: { domain: 'google.az', language: 'az', country: 'Azerbaijan' }, 
    bs: { domain: 'google.bs', language: 'en', country: 'Bahamas' }, 
    bh: { domain: 'google.com.bh', language: 'ar', country: 'Bahrain' }, 
    bd: { domain: 'google.com.bd', language: 'bn', country: 'Bangladesh' }, 
    by: { domain: 'google.by', language: 'be', country: 'Belarus' }, 
    be: { domain: 'google.be', language: 'nl', country: 'Belgium' }, 
    bz: { domain: 'google.com.bz', language: 'en', country: 'Belize' }, 
    bj: { domain: 'google.bj', language: 'fr', country: 'Benin' }, 
    bt: { domain: 'google.bt', language: 'dz', country: 'Bhutan' }, 
    bo: { domain: 'google.com.bo', language: 'es', country: 'Bolivia' }, 
    ba: { domain: 'google.ba', language: 'bs', country: 'Bosnia and Herzegovina' }, 
    bw: { domain: 'google.co.bw', language: 'en', country: 'Botswana' }, 
    br: { domain: 'google.com.br', language: 'pt', country: 'Brazil' }, 
    vg: { domain: 'google.vg', language: 'en', country: 'British Virgin Islands' }, 
    bn: { domain: 'google.com.bn', language: 'ms', country: 'Brunei' }, 
    bg: { domain: 'google.bg', language: 'bg', country: 'Bulgaria' }, 
    bf: { domain: 'google.bf', language: 'fr', country: 'Burkina Faso' }, 
    bi: { domain: 'google.bi', language: 'fr', country: 'Burundi' }, 
    kh: { domain: 'google.com.kh', language: 'km', country: 'Cambodia' }, 
    cm: { domain: 'google.cm', language: 'en', country: 'Cameroon' }, 
    ca: { domain: 'google.ca', language: 'en', country: 'Canada' }, 
    cv: { domain: 'google.cv', language: 'pt', country: 'Cape Verde' }, 
    cat: { domain: 'google.cat', language: 'es', country: 'Catalonia' },
    cf: { domain: 'google.cf', language: 'fr', country: 'Central African Republic' }, 
    td: { domain: 'google.td', language: 'ar', country: 'Chad' }, 
    cl: { domain: 'google.cl', language: 'es', country: 'Chile' }, 
    cn: { domain: 'google.cn', language: 'zh', country: 'China' }, 
    cx: { domain: 'google.cx', language: 'en', country: 'Christmas Island' }, 
    cc: { domain: 'google.cc', language: 'en', country: 'Cocos (Keeling) Islands' }, 
    co: { domain: 'google.com.co', language: 'es', country: 'Colombia' }, 
    ck: { domain: 'google.co.ck', language: 'en', country: 'Cook Islands' }, 
    cr: { domain: 'google.co.cr', language: 'es', country: 'Costa Rica' }, 
    hr: { domain: 'google.hr', language: 'hr', country: 'Croatia' }, 
    cu: { domain: 'google.com.cu', language: 'es', country: 'Cuba' }, 
    cy: { domain: 'google.com.cy', language: 'el', country: 'Cyprus' }, 
    cz: { domain: 'google.cz', language: 'cs', country: 'Czech Republic' }, 
    cg: { domain: 'google.cd', language: 'fr', country: 'Democratic Rep of Congo' }, 
    dk: { domain: 'google.dk', language: 'da', country: 'Denmark' }, 
    dj: { domain: 'google.dj', language: 'ar', country: 'Djibouti' }, 
    dm: { domain: 'google.dm', language: 'en', country: 'Dominica' }, 
    do: { domain: 'google.com.do', language: 'es', country: 'Dominican Republic' }, 
    ec: { domain: 'google.com.ec', language: 'es', country: 'Ecuador' }, 
    eg: { domain: 'google.com.eg', language: 'ar', country: 'Egypt' }, 
    sv: { domain: 'google.com.sv', language: 'es', country: 'El Salvador' }, 
    ee: { domain: 'google.ee', language: 'et', country: 'Estonia' }, 
    et: { domain: 'google.com.et', language: 'am', country: 'Ethiopia' }, 
    fj: { domain: 'google.com.fj', language: 'en', country: 'Fiji' }, 
    fi: { domain: 'google.fi', language: 'fi', country: 'Finland' }, 
    fr: { domain: 'google.fr', language: 'fr', country: 'France' }, 
    gf: { domain: 'google.gf', language: 'fr', country: 'French Guiana' }, 
    ga: { domain: 'google.ga', language: 'fr', country: 'Gabon' }, 
    gm: { domain: 'google.gm', language: 'en', country: 'Gambia' }, 
    ge: { domain: 'google.ge', language: 'ka', country: 'Georgia' }, 
    de: { domain: 'google.de', language: 'de', country: 'Germany' }, 
    gh: { domain: 'google.com.gh', language: 'en', country: 'Ghana' }, 
    gi: { domain: 'google.com.gi', language: 'en', country: 'Gibraltar' }, 
    gr: { domain: 'google.gr', language: 'el', country: 'Greece' }, 
    gl: { domain: 'google.gl', language: 'kl', country: 'Greenland' }, 
    gp: { domain: 'google.gp', language: 'fr', country: 'Guadeloupe' }, 
    gt: { domain: 'google.com.gt', language: 'es', country: 'Guatemala' }, 
    gy: { domain: 'google.gy', language: 'en', country: 'Guyana' }, 
    ht: { domain: 'google.ht', language: 'fr', country: 'Haiti' }, 
    hn: { domain: 'google.hn', language: 'es', country: 'Honduras' }, 
    hk: { domain: 'google.com.hk', language: 'en', country: 'Hong Kong' }, 
    hu: { domain: 'google.hu', language: 'hu', country: 'Hungary' }, 
    is: { domain: 'google.is', language: 'is', country: 'Iceland' }, 
    in: { domain: 'google.co.in', language: 'hi', country: 'India' }, 
    io: { domain: 'google.io', language: 'en', country: 'Indian Ocean Territory' }, 
    id: { domain: 'google.co.id', language: 'id', country: 'Indonesia' }, 
    iq: { domain: 'google.iq', language: 'ar', country: 'Iraq' }, 
    ie: { domain: 'google.ie', language: 'en', country: 'Ireland' }, 
    il: { domain: 'google.co.il', language: 'he', country: 'Israel' }, 
    it: { domain: 'google.it', language: 'it', country: 'Italy' }, 
    ci: { domain: 'google.ci', language: 'fr', country: 'Ivory Coast' }, 
    jm: { domain: 'google.com.jm', language: 'en', country: 'Jamaica' }, 
    jp: { domain: 'google.co.jp', language: 'ja', country: 'Japan' }, 
    jo: { domain: 'google.jo', language: 'ar', country: 'Jordan' }, 
    kz: { domain: 'google.kz', language: 'kk', country: 'Kazakhstan' }, 
    ke: { domain: 'google.co.ke', language: 'en', country: 'Kenya' }, 
    ki: { domain: 'google.ki', language: 'en', country: 'Kiribati' }, 
    kw: { domain: 'google.com.kw', language: 'ar', country: 'Kuwait' }, 
    kg: { domain: 'google.kg', language: 'ky', country: 'Kyrgyzstan' }, 
    la: { domain: 'google.la', language: 'lo', country: 'Laos' }, 
    lv: { domain: 'google.lv', language: 'lv', country: 'Latvia' }, 
    lb: { domain: 'google.com.lb', language: 'ar', country: 'Lebanon' }, 
    ls: { domain: 'google.co.ls', language: 'en', country: 'Lesotho' }, 
    ly: { domain: 'google.com.ly', language: 'ar', country: 'Libya' }, 
    li: { domain: 'google.li', language: 'de', country: 'Liechtenstein' }, 
    lt: { domain: 'google.lt', language: 'lt', country: 'Lithuania' }, 
    lu: { domain: 'google.lu', language: 'fr', country: 'Luxembourg' }, 
    mk: { domain: 'google.mk', language: 'mk', country: 'Macedonia' }, 
    mg: { domain: 'google.mg', language: 'fr', country: 'Madagascar' }, 
    mw: { domain: 'google.mw', language: 'en', country: 'Malawi' }, 
    my: { domain: 'google.com.my', language: 'ms', country: 'Malaysia' }, 
    mv: { domain: 'google.mv', language: 'dv', country: 'Maldives' }, 
    ml: { domain: 'google.ml', language: 'fr', country: 'Mali' }, 
    mt: { domain: 'google.com.mt', language: 'mt', country: 'Malta' }, 
    mu: { domain: 'google.mu', language: 'en', country: 'Mauritius' }, 
    mx: { domain: 'google.com.mx', language: 'es', country: 'Mexico' }, 
    fm: { domain: 'google.fm', language: 'en', country: 'Micronesia' }, 
    md: { domain: 'google.md', language: 'ro', country: 'Moldova' }, 
    mn: { domain: 'google.mn', language: 'mn', country: 'Mongolia' }, 
    ms: { domain: 'google.ms', language: 'en', country: 'Montserrat' }, 
    ma: { domain: 'google.co.ma', language: 'ar', country: 'Morocco' }, 
    mz: { domain: 'google.co.mz', language: 'pt', country: 'Mozambique' }, 
    mm: { domain: 'google.com.mm', language: 'my', country: 'Myanmar' }, 
    na: { domain: 'google.com.na', language: 'en', country: 'Namibia' }, 
    nr: { domain: 'google.nr', language: 'en', country: 'Nauru' }, 
    np: { domain: 'google.com.np', language: 'ne', country: 'Nepal' }, 
    nl: { domain: 'google.nl', language: 'nl', country: 'Netherlands' }, 
    nz: { domain: 'google.co.nz', language: 'en', country: 'New Zealand' }, 
    ni: { domain: 'google.com.ni', language: 'es', country: 'Nicaragua' }, 
    ne: { domain: 'google.ne', language: 'fr', country: 'Niger' }, 
    ng: { domain: 'google.com.ng', language: 'en', country: 'Nigeria' }, 
    nu: { domain: 'google.nu', language: 'en', country: 'Niue' }, 
    no: { domain: 'google.no', language: 'no', country: 'Norway' }, 
    om: { domain: 'google.com.om', language: 'ar', country: 'Oman' }, 
    pk: { domain: 'google.com.pk', language: 'en', country: 'Pakistan' }, 
    ps: { domain: 'google.ps', language: 'ar', country: 'Palestine' }, 
    pa: { domain: 'google.com.pa', language: 'es', country: 'Panama' }, 
    pg: { domain: 'google.com.pg', language: 'en', country: 'Papua New Guinea' }, 
    py: { domain: 'google.com.py', language: 'es', country: 'Paraguay' }, 
    pe: { domain: 'google.com.pe', language: 'es', country: 'Peru' }, 
    ph: { domain: 'google.com.ph', language: 'tl', country: 'Philippines' }, 
    pl: { domain: 'google.pl', language: 'pl', country: 'Poland' }, 
    pt: { domain: 'google.pt', language: 'pt', country: 'Portugal' }, 
    pr: { domain: 'google.com.pr', language: 'es', country: 'Puerto Rico' }, 
    qa: { domain: 'google.com.qa', language: 'ar', country: 'Qatar' }, 
    ro: { domain: 'google.ro', language: 'ro', country: 'Romania' }, 
    ru: { domain: 'google.ru', language: 'ru', country: 'Russia' }, 
    rw: { domain: 'google.rw', language: 'rw', country: 'Rwanda' }, 
    sh: { domain: 'google.sh', language: 'en', country: 'Saint Helena' }, 
    vc: { domain: 'google.com.vc', language: 'en', country: 'Saint Vincent' }, 
    ws: { domain: 'google.ws', language: 'sm', country: 'Samoa' }, 
    sm: { domain: 'google.sm', language: 'it', country: 'San Marino' }, 
    sa: { domain: 'google.com.sa', language: 'ar', country: 'Saudi Arabia' }, 
    sn: { domain: 'google.sn', language: 'fr', country: 'Senegal' }, 
    rs: { domain: 'google.rs', language: 'sr', country: 'Serbia' }, 
    sc: { domain: 'google.sc', language: 'fr', country: 'Seychelles' }, 
    sl: { domain: 'google.com.sl', language: 'en', country: 'Sierra Leone' }, 
    sg: { domain: 'google.com.sg', language: 'en', country: 'Singapore' }, 
    sk: { domain: 'google.sk', language: 'sk', country: 'Slovakia' }, 
    si: { domain: 'google.si', language: 'sl', country: 'Slovenia' }, 
    sb: { domain: 'google.com.sb', language: 'en', country: 'Solomon Islands' }, 
    so: { domain: 'google.so', language: 'so', country: 'Somalia' }, 
    za: { domain: 'google.co.za', language: 'af', country: 'South Africa' }, 
    kp: { domain: 'google.co.kr', language: 'ko', country: 'South Korea' }, 
    es: { domain: 'google.es', language: 'es', country: 'Spain' }, 
    lk: { domain: 'google.lk', language: 'si', country: 'Sri Lanka' }, 
    sr: { domain: 'google.sr', language: 'nl', country: 'Suriname' }, 
    se: { domain: 'google.se', language: 'sv', country: 'Sweden' }, 
    ch: { domain: 'google.ch', language: 'de', country: 'Switzerland' }, 
    tw: { domain: 'google.com.tw', language: 'zh', country: 'Taiwan' }, 
    tj: { domain: 'google.com.tj', language: 'tg', country: 'Tajikistan' }, 
    tz: { domain: 'google.co.tz', language: 'sw', country: 'Tanzania' }, 
    th: { domain: 'google.co.th', language: 'th', country: 'Thailand' }, 
    tl: { domain: 'google.tl', language: 'pt', country: 'Timor-Leste' }, 
    tg: { domain: 'google.tg', language: 'fr', country: 'Togo' }, 
    tk: { domain: 'google.tk', language: 'en', country: 'Tokelau' }, 
    to: { domain: 'google.to', language: 'en', country: 'Tonga' }, 
    tt: { domain: 'google.tt', language: 'en', country: 'Trinidad and Tobago' }, 
    tn: { domain: 'google.tn', language: 'ar', country: 'Tunisia' }, 
    tr: { domain: 'google.com.tr', language: 'tr', country: 'Turkey' }, 
    tm: { domain: 'google.tm', language: 'tk', country: 'Turkmenistan' }, 
    ug: { domain: 'google.co.ug', language: 'en', country: 'Uganda' }, 
    ua: { domain: 'google.com.ua', language: 'uk', country: 'Ukraine' }, 
    ae: { domain: 'google.ae', language: 'ar', country: 'United Arab Emirates' }, 
    uk: { domain: 'google.co.uk', language: 'en', country: 'United Kingdom' }, 
    us: { domain: 'google.com', language: 'en', country: 'United States' }, 
    uy: { domain: 'google.com.uy', language: 'es', country: 'Uruguay' }, 
    vi: { domain: 'google.co.vi', language: 'en', country: 'US Virgin Islands' }, 
    uz: { domain: 'google.co.uz', language: 'uz', country: 'Uzbekistan' }, 
    vu: { domain: 'google.vu', language: 'bi', country: 'Vanuatu' }, 
    ve: { domain: 'google.co.ve', language: 'es', country: 'Venezuela' }, 
    vn: { domain: 'google.com.vn', language: 'vi', country: 'Vietnam' }, 
    zm: { domain: 'google.co.zm', language: 'en', country: 'Zambia' }, 
    zw: { domain: 'google.co.zw', language: 'en', country: 'Zimbab' }
};
