// src/services/SetNumInStoreFilters.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from './Router.service';
import SetPageInStoreFiltersService from './SetPageInStoreFilters.service';

export default async function (num?: number) {
	const updateQuery = function (resetPage: boolean = false) {
		return new Promise((resolve) => {
			if (resetPage) {
				SetPageInStoreFiltersService(0)
					.then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'numFiltersReady',
							value: true,
						}).then(() => {
							resolve(true);
						});
					})
			}
			else {
				StoreService.dispatch('set', {
					stateProperty: 'numFiltersReady',
					value: true,
				}).then(() => {
					resolve(true);
				});
			}
		});
	};

	return new Promise((resolve) => {
		StoreService.dispatch('set', {
			stateProperty: 'numFiltersReady',
			value: false,
		}).then(() => {
			// set with argument
			if (num !== undefined) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'num',
					value: num,
				}).then(() => {
					updateQuery(true).then(() => {
						resolve(true);
					});
				});
			}
			// value is in URL
			else if (
				StoreService.state.checkURLFilters &&
				RouterService.currentRoute.value.query.num !== undefined
			) {
				const num: number = parseInt(RouterService.currentRoute.value.query.num as string)
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'num',
					value: isNaN(num)? 50: num,
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
			else {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'num',
					value: 50,
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
