import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalDangerComponent = _resolveComponent("ModalDangerComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ModalDangerComponent, {
      title: 
			_ctx.$beautify.firstLetterUppercase(
				_ctx.$text('expressions', 'Ask_Sure_To_Quit_Without_Save')
			)
		,
      id: "modal-parameters-unsaved-changes-close-action",
      action1: 
			_ctx.$beautify.firstLetterUppercase(_ctx.$text('actions', 'Discard_Changes'))
		,
      action2: _ctx.$beautify.firstLetterUppercase(_ctx.$text('actions', 'Save')),
      ref: "modal-parameters-unsaved-changes-close-action",
      onCallbackAction1: _cache[0] || (_cache[0] = ($event: any) => (_ctx.discardChangesAndGoLastRoute())),
      onCallbackAction2: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveChangesAndGoLastRoute())),
      callbackAction1AutoDismiss: true,
      callbackAction2AutoDismiss: true
    }, null, 8, ["title", "action1", "action2"]),
    _createVNode(_component_ModalDangerComponent, {
      title: 
			_ctx.$beautify.firstLetterUppercase(
				_ctx.$text('expressions', 'Ask_Sure_To_Quit_Without_Save')
			)
		,
      id: "modal-parameters-unsaved-changes-switch-brand-action",
      action1: 
			_ctx.$beautify.firstLetterUppercase(_ctx.$text('actions', 'Discard_Changes'))
		,
      action2: _ctx.$beautify.firstLetterUppercase(_ctx.$text('actions', 'Save')),
      ref: "modal-parameters-unsaved-changes-switch-brand-action",
      onCallbackAction1: _cache[2] || (_cache[2] = ($event: any) => (_ctx.discardChanges())),
      onCallbackAction2: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveChanges())),
      callbackAction1AutoDismiss: true,
      callbackAction2AutoDismiss: true
    }, null, 8, ["title", "action1", "action2"])
  ], 64))
}