// src/services/SetRoleInStore.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from '@/services/Router.service';
import { RoleType } from '@/types/Role.type';

export default async function (role?: RoleType | null) {
	return new Promise((resolve) => {
		console.log(
			'%c [Service] Set role in store',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'roleLoaded',
			value: false,
		}).then(() => {
			/* set role passed by argument */
			if (role !== undefined) {
				StoreService.dispatch('set', {
					stateProperty: 'role',
					value: role,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'roleLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			/* set role passed by url */
			else if (
				RouterService.currentRoute.value.params.role_id &&
				StoreService.state.roles!.find(
					(role: RoleType) =>
						role._id === RouterService.currentRoute.value.params.role_id
				) !== undefined
			) {
				StoreService.dispatch('set', {
					stateProperty: 'role',
					value: StoreService.state.roles!.find(
						(role: RoleType) =>
							role._id === RouterService.currentRoute.value.params.role_id
					),
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'roleLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			else {
				StoreService.dispatch('set', {
					stateProperty: 'roleLoaded',
					value: true,
				}).then(() => {
					resolve(true);
				});
			}
		});
	});
}
