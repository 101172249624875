import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", {
    id: "navbar-horizontal",
    class: _normalizeClass(["border-bottom d-flex justify-content-between px-5 align-items-center", 
			Array.prototype.includes.call(
				_ctx.$config('RouteConfig', 'LoggedSettingsRouteNames'),
				_ctx.$route.name
			) ||
			Array.prototype.includes.call(
				_ctx.$config('RouteConfig', 'LoggedSettingsWithoutNavBarVerticalRouteNames'),
				_ctx.$route.name
			) ||
			Array.prototype.includes.call(
				_ctx.$config('RouteConfig', 'LoggedContentsWithoutNavBarVerticalRouteNames'),
				_ctx.$route.name
			) ||
			Array.prototype.includes.call(
				_ctx.$config('RouteConfig', 'LoggedClosableRouteNames'),
				_ctx.$route.name
			)
				? 'sticky-top'
				: ''
		])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.NavBarHorizontalChildComponent)))
  ], 2))
}