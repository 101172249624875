// src/services/SetFocusMediaInStoreFilters.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from './Router.service';
import SetPageInStoreFiltersService from './SetPageInStoreFilters.service';

export default async function (focus_media?: boolean) {
	const updateQuery = function (resetPage: boolean = false) {
		return new Promise((resolve) => {
			if (resetPage) {
				SetPageInStoreFiltersService(0)
					.then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'focusMediaFiltersReady',
							value: true,
						}).then(() => {
							resolve(true);
						});
					})
			}
			else {
				StoreService.dispatch('set', {
					stateProperty: 'focusMediaFiltersReady',
					value: true,
				}).then(() => {
					resolve(true);
				});
			}
		});
	};
	
	return new Promise((resolve) => {
		StoreService.dispatch('set', {
			stateProperty: 'focusMediaFiltersReady',
			value: false,
		}).then(() => {
			// set with argument
			if (focus_media !== undefined) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'focus_media',
					value: focus_media,
				}).then(() => {
					updateQuery(true).then(() => {
						resolve(true);
					});
				});
			}
			// set with url (arrive on product)
			else if (
				StoreService.state.checkURLFilters === true &&
				RouterService.currentRoute.value.query.focus_media !== undefined
			) {
				if (RouterService.currentRoute.value.query.focus_media === 'true') {
					StoreService.dispatch('update', {
						stateProperty: 'filters',
						stateChildProperty: 'focus_media',
						value: true,
					}).then(() => {
						updateQuery().then(() => {
							resolve(true);
						});
					});
				} else if (
					RouterService.currentRoute.value.query.focus_media !== 'true'
				) {
					StoreService.dispatch('update', {
						stateProperty: 'filters',
						stateChildProperty: 'focus_media',
						value: false,
					}).then(() => {
						updateQuery().then(() => {
							resolve(true);
						});
					});
				}
			}
			else {
				updateQuery().then(() => {
					resolve(true);
				});
			}
		});
	});
}
