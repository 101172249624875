// src/services/GetOrganization.service.ts

import { StoreService } from '@/services/Store.service';
import { API } from '@/services/API.service';
import { getAlerts } from '@/services/Alerts.service';

export default async function (organization_id?: string): Promise<any> {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Service] Getting organization... ',
			'color: #0000FF;font-weight:bold;'
		);
		if (!organization_id) {
			if (StoreService.state.organization!._id) {
				organization_id = StoreService.state.organization!._id;
			} else {
				reject(false);
			}
		}
		API.organization('get', null, {
			organization_id: StoreService.state.organization!._id,
		})?.then((response: any) => {
			resolve(response.data);
		}).catch((error) => {
			getAlerts.pushFromError(error)
			reject(error)
		});
	});
}
