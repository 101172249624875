import { getConfig } from '@/services/Config.service';
import GetMediasService from '@/services/GetMedias.service';
import { StoreService } from '@/services/Store.service';

export async function LoadMediasMiddleware(to: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Medias loading... ',
			'color: #FF0000;font-weight:bold;'
		);

		StoreService.dispatch('set', {
			stateProperty: 'mediasLoaded',
			value: false,
		}).then(() => {
			// get space medias
			GetMediasService().then(() => {
				// set medias in Store.state.medias
				StoreService.dispatch('set', {
					stateProperty: 'mediasLoaded',
					value: true,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'mediaLoaded',
						value: false,
					}).then(() => {
						resolve(true);
					});
				});
			});
		});
	});
}
