import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "fluid-layout",
  class: "px-5 py-5 p-lg-0 bg-surface-secondary h-full"
}
const _hoisted_2 = { class: "h-full w-full d-flex align-items-center" }
const _hoisted_3 = {
  id: "main",
  class: "h-full w-full"
}
const _hoisted_4 = {
  id: "content",
  class: "w-full d-flex align-items-center mt-20"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertsGroup = _resolveComponent("AlertsGroup")!
  const _component_ProgressBarComponent = _resolveComponent("ProgressBarComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ])
    ]),
    _createVNode(_component_AlertsGroup),
    _createVNode(_component_ProgressBarComponent)
  ], 64))
}