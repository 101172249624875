
import { defineComponent } from 'vue';
import ButtonComponent from '@/components/buttons/Button.component.vue';
import { RouteParamsRaw } from 'vue-router';
import { Modal } from 'bootstrap';
import SetUnsavedChangesToStoreService from '@/services/SetUnsavedChangesToStore.service';
import SubmittingService from '@/services/Submitting.service';
import { getPermission } from '@/services/Permission.service';
import { getLimitation } from '@/services/Limitation.service';

export default defineComponent({
	components: {
		ButtonComponent,
	},
	computed: {
		canSave(): boolean {
			if (
				(
					!getPermission.canUpdateOrganization() &&
						this.currentRouteName ===
							this.$config('RouteConfig', 'OrganizationUpdateRouteName')
				) ||
				(
					!getPermission.canCreateSpace() &&
						this.currentRouteName ===
							this.$config('RouteConfig', 'SpaceCreateRouteName')
				) ||
				(
					!getPermission.canUpdateSpace() &&
						this.currentRouteName ===
							this.$config('RouteConfig', this.$store.state.space.onboarding === 'completed'?'SpaceUpdateRouteName' :'OnboardingRouteName')
				) ||
				(
					(
						!getPermission.canCreateEntity() ||
						getLimitation.brandsLimitedAccess()
					) &&
					(
						this.currentRouteName ===
							this.$config('RouteConfig', 'BrandCreateRouteName') ||
						this.currentRouteName ===
							this.$config('RouteConfig', 'RoleAddRouteName') ||
						this.currentRouteName ===
							this.$config('RouteConfig', 'ContentAddRouteName') ||
						this.currentRouteName ===
							this.$config('RouteConfig', 'MediaAddRouteName')
					)
				) ||
				(
					(
						!getPermission.canUpdateEntity() ||
						getLimitation.brandsLimitedAccess()
					) &&
					(
						this.currentRouteName ===
							this.$config('RouteConfig', 'BrandUpdateRouteName') ||
						this.currentRouteName ===
							this.$config('RouteConfig', 'RoleUpdateRouteName') ||
						this.currentRouteName ===
							this.$config('RouteConfig', 'ContentUpdateRouteName') ||
						this.currentRouteName ===
							this.$config('RouteConfig', 'MediaUpdateRouteName')
					)
				)
			)
				return false
			return true
		},

		lastRouteName(): string {
			if (
				this.$store.state.last_product_route &&
				this.$store.state.last_product_route.name
			) {
				return this.$store.state.last_product_route.name;
			} else {
				return '';
			}
		},
		lastRouteParams(): RouteParamsRaw {
			if (
				this.$store.state.last_product_route &&
				this.$store.state.last_product_route.params
			) {
				return this.$store.state.last_product_route.params;
			} else {
				return {};
			}
		},
		lastRouteHash(): string {
			if (
				this.$store.state.last_product_route &&
				this.$store.state.last_product_route.hash
			) {
				return this.$store.state.last_product_route.hash;
			} else {
				return '';
			}
		},

		currentRouteFriendlyName(): string {
			return JSON.parse(
				JSON.stringify(this.$store.state.current_route.meta.friendlyName)
			);
		},
		currentRouteName(): string {
			if (
				this.$store.state.current_route &&
				this.$store.state.current_route.name
			) {
				return this.$store.state.current_route.name;
			} else {
				return '';
			}
		},
		currentRouteParams(): RouteParamsRaw {
			if (
				this.$store.state.current_route &&
				this.$store.state.current_route.params
			) {
				return this.$store.state.current_route.params;
			} else {
				return {};
			}
		},

		nextRouteName(): string {
			if (this.$store.state.next_route && this.$store.state.next_route.name) {
				return this.$store.state.next_route.name;
			} else {
				return '';
			}
		},
		nextRouteParams(): RouteParamsRaw {
			if (this.$store.state.next_route && this.$store.state.next_route.params) {
				return this.$store.state.next_route.params;
			} else {
				return {};
			}
		},
		nextRouteHash(): string {
			if (this.$store.state.next_route && this.$store.state.next_route.hash) {
				return this.$store.state.next_route.hash;
			} else {
				return '';
			}
		},
	},

	methods: {
		goToLastRoute() {
			this.$router.push({
				name: this.lastRouteName,
				params: this.lastRouteParams as RouteParamsRaw,
				hash: this.lastRouteHash,
			});
		},
		goToCurrentParamsRoute() {
			if (
				this.currentRouteParams.organization_id &&
				this.currentRouteParams.space_id
			) {
				if (
					this.currentRouteName ===
						this.$config('RouteConfig', 'RoleAddRouteName') ||
					this.currentRouteName ===
						this.$config('RouteConfig', 'RoleUpdateRouteName')
				) {
					this.$router.push({
						name: this.$config('RouteConfig', 'SpaceRolesRouteName'),
						params: {
							organization_id: this.currentRouteParams.organization_id,
							space_id: this.currentRouteParams.space_id,
						},
					});
				} else if (
					this.currentRouteName ===
						this.$config('RouteConfig', 'MediaAddRouteName') ||
					this.currentRouteName ===
						this.$config('RouteConfig', 'MediaUpdateRouteName')
				) {
					this.$router.push({
						name: this.$config('RouteConfig', 'MediasRouteName'),
						params: {
							organization_id: this.currentRouteParams.organization_id,
							space_id: this.currentRouteParams.space_id,
						},
					});
				} else if (
					this.currentRouteName ===
						this.$config('RouteConfig', 'PaymentMethodAddRouteName') ||
					this.currentRouteName ===
						this.$config('RouteConfig', 'PaymentMethodUpdateRouteName') ||
					this.currentRouteName ===
						this.$config('RouteConfig', 'CheckoutRouteName')
				) {
					this.$router.push({
						name: this.$config('RouteConfig', 'SpaceBillingRouteName'),
						params: {
							organization_id: this.currentRouteParams.organization_id,
							space_id: this.currentRouteParams.space_id,
						},
					});
				} else if(this.currentRouteName ===
						this.$config('RouteConfig', 'ContentAddRouteName')) {
					this.$router.push({
						name: this.$config('RouteConfig', 'ContentsRouteName'),
						params: {
							organization_id: this.currentRouteParams.organization_id,
							space_id: this.currentRouteParams.space_id,
						},
					});
				}else {
					if(this.currentRouteParams.view_id) {
						this.$router.push({
							name: this.$config('RouteConfig', 'ViewRouteName'),
							params: {
								organization_id: this.currentRouteParams.organization_id,
								space_id: this.currentRouteParams.space_id,
								view_id: this.currentRouteParams.view_id,
							},
						});
					} else {
						this.$router.push({
							name: this.$config('RouteConfig', 'HomeRouteName'),
						});
					}
				}
			} else if (this.currentRouteParams.organization_id) {
				this.$router.push({
					name: this.$config('RouteConfig', 'SpaceRouteName'),
					params: {
						organization_id: this.currentRouteParams.organization_id,
					},
				});
			} else {
				this.$router.push({
					name: this.$config('RouteConfig', 'OrganizationRouteName'),
				});
			}
		},
		goToNextRoute() {
			this.$router.push({
				name: this.nextRouteName,
				params: this.nextRouteParams as RouteParamsRaw,
				hash: this.nextRouteHash,
			});
		},

		handleClose() {
			if (this.$store.state.unsaved_changes === false) {
				if (this.lastRouteName) {
					this.goToLastRoute();
				} else {
					this.goToCurrentParamsRoute();
				}
			} else {
				var modalParametersUnsavedChanges = new Modal(
					document.getElementById(
						'modal-parameters-unsaved-changes-close-action'
					) as Element
				);
				modalParametersUnsavedChanges.show();
			}
		},
		handleSubmitAndExit() {
			SubmittingService(true).then(() => {
				this.$store.state.current_form
					.onSubmit()
					.then(() => {
						SubmittingService(false).then(() => {
							SetUnsavedChangesToStoreService(false).then(() => {
								if (this.lastRouteName) {
									this.goToLastRoute();
								} else {
									this.goToCurrentParamsRoute();
								}
							});
						});
					})
					.catch((error: any) => {
						console.log(error);
						SubmittingService(false).then(() => console.log(error));
					});
			});
		},
	},
});
