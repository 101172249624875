
import { defineComponent } from 'vue';

export default defineComponent({
	data() {
		return {
			menu: [
				{
					name: 'Space',
					items: [
						{
							name: 'Space_Name',
							link: '#name',
						},
						{
							name: 'Space_Description',
							link: '#description',
						},
						{
							name: 'Space_Website',
							link: '#website',
						},
					],
				},
				{
					name: 'Contact',
					items: [
						{
							name: 'Email',
							link: '#email',
						},
						{
							name: 'Phone_Number',
							link: '#phone-number',
						},
						{
							name: 'Address',
							link: '#address',
						},
						{
							name: 'City',
							link: '#city',
						},
						{
							name: 'Country',
							link: '#country',
						},
						{
							name: 'ZIP',
							link: '#ZIP',
						},
					],
				},
			],
		};
	},
	created() {
		if (this.$route.hash === '') {
			this.$router.push({
				name: this.$route.name?.toString(),
				hash: this.menu[0].items[0].link,
			});
		}
	},
});
