import { getConfig } from '@/services/Config.service';
import { StoreService } from '@/services/Store.service';
import { RouterService } from '@/services/Router.service';
import { getLimitation } from '@/services/Limitation.service';

export async function LimitationMiddleware(to: any, next: any) {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Middleware] Check Limitation ... ',
			'color: #FF0000;font-weight:bold;'
		);

		if (
			StoreService.state.space && StoreService.state.space._id &&
			(
				// contents pages limitations
				(
					getLimitation.contentsLimitedAccess() &&
					(
						to.name === getConfig('RouteConfig', 'ContentsRouteName') ||
						to.name === getConfig('RouteConfig', 'ContentAddRouteName') ||
						to.name === getConfig('RouteConfig', 'ContentUpdateRouteName')
					)
				) ||
				// posts pages limitations
				(
					getLimitation.postsLimitedAccess() &&
					(
						to.name === getConfig('RouteConfig', 'ContentsRouteName') ||
						to.name === getConfig('RouteConfig', 'ContentAddRouteName') ||
						to.name === getConfig('RouteConfig', 'ContentUpdateRouteName')
					)
				) ||
				// insights page limitations
				(
					getLimitation.insightsLimitedAccess() &&
					to.name === getConfig('RouteConfig', 'InsightsRouteName')
				) ||
				// brands page limitations
				(
					getLimitation.brandsLimitedAccess() &&
					(
						to.name === getConfig('RouteConfig', 'BrandCreateRouteName')
					)
				) ||
				// roles page limitations
				(
					getLimitation.rolesLimitedAccess() &&
					(
						to.name === getConfig('RouteConfig', 'RoleAddRouteName') ||
						to.name === getConfig('RouteConfig', 'RoleUpdateRouteName')
					)
				) ||
				// medias page limitations
				(
					getLimitation.mediasLimitedAccess() &&
					(
						to.name === getConfig('RouteConfig', 'MediaAddRouteName') ||
						to.name === getConfig('RouteConfig', 'MediaUpdateRouteName')
					)
				)
			)
		)
			// Redirect on space route
			RouterService.push({
				name: getConfig('RouteConfig', 'SpaceRouteName'),
				params: {
					organization_id: to.params.organization_id,
					space_id: StoreService.state.space._id as string
				},
			}).then(() => {
				next(false);
			});
		else {
			resolve(true);
		}
	});
}
