
import { defineComponent } from 'vue';
import { IndexMixin } from '@/mixins/Index.mixin';
import AlertsGroup from '@/layouts/Alerts/AlertsGroup.layout.vue';
import LogoComponent from '@/components/Logo.component.vue';

export default defineComponent({
	mixins: [IndexMixin],

	components: {
		AlertsGroup,
		LogoComponent,
	},
});
