// src/services/BuildQueryFilters.service.ts

import { StoreService } from '@/services/Store.service';
import { LocationQueryRaw } from 'vue-router';
import { getConfig } from './Config.service';

export default function (routeName: string) {
    console.log(
        '%c [Service] Build query filters ... ',
        'color: #0000FF;font-weight:bold;'
    );

    const query: LocationQueryRaw = {}
    if (
        routeName == getConfig("RouteConfig", "ContentsPreviewRouteName") ||
        routeName == getConfig("RouteConfig", "PostsPreviewRouteName")
    ) {
        const previewFilters = Object.assign(
            {}, StoreService.state.filters, StoreService.state.filtersPreview
        )
        if (previewFilters.startdate)
            query.startdate = previewFilters.startdate;
        if (previewFilters.enddate)
            query.enddate = previewFilters.enddate;
        if (previewFilters.brand_ids && previewFilters.brand_ids.length)
            query.brand_ids = previewFilters.brand_ids.join(',');
        if (previewFilters.keyword_ids && previewFilters.keyword_ids.length)
            query.keyword_ids = previewFilters.keyword_ids.join(',');
        if (previewFilters.languages && previewFilters.languages.length)
            query.languages = previewFilters.languages.join(',');
        if (previewFilters.sort_by)
            query.sort_by = previewFilters.sort_by;
        if (previewFilters.social_source && previewFilters.social_source.length)
            query.social_source = previewFilters.social_source.join(',');
        if (routeName == getConfig("RouteConfig", "ContentsPreviewRouteName")) {
            if (previewFilters.categories && previewFilters.categories.length)
                query.categories = previewFilters.categories.join(',');
            if (previewFilters.media_focus)
                query.media_focus = "true";
            if (previewFilters.media_reach)
                query.media_reach = "true";
            if (previewFilters.media_is_newspaper !== undefined)
                query.media_is_newspaper = previewFilters.media_is_newspaper ? "true" : "false";
            if (previewFilters.media_names && previewFilters.media_names.length)
                query.media_names = previewFilters.media_names.join(',');
            if (previewFilters.media_websites && previewFilters.media_websites.length)
                query.media_websites = previewFilters.media_websites.join(',');
            if (previewFilters.media_families && previewFilters.media_families.length)
                query.media_families = previewFilters.media_families.join(',');
        }
        else {
            if (previewFilters.sources && previewFilters.sources.length)
                query.sources = previewFilters.sources.join(',');
            if (previewFilters.accounts && previewFilters.accounts.length)
                query.accounts = previewFilters.accounts.join(',');
            if (previewFilters.hashtags && previewFilters.hashtags.length)
                query.hashtags = previewFilters.hashtags.join(',');
        }
        if (previewFilters.sizes && previewFilters.sizes.length)
            query.sizes = previewFilters.sizes.join(',');
        if (previewFilters.positions && previewFilters.positions.length)
            query.positions = previewFilters.positions.join(',');
        if (previewFilters.sentiments && previewFilters.sentiments.length)
            query.sentiments = previewFilters.sentiments.join(',');
        if (previewFilters.backlinks)
            query.backlinks = "true";
        if (previewFilters.page !== undefined)
            query.page = previewFilters.page.toString();
        if (previewFilters.num !== undefined)
            query.num = previewFilters.num.toString();
    }
    else {
        if (StoreService.state.filters.startdate)
            query.startdate = StoreService.state.filters.startdate;
        if (StoreService.state.filters.enddate)
            query.enddate = StoreService.state.filters.enddate;
        if (StoreService.state.filters.sort_by)
            query.sort_by = StoreService.state.filters.sort_by;
        if (StoreService.state.filters.social_source && StoreService.state.filters.social_source.length)
            query.social_source = StoreService.state.filters.social_source.join(',');
        if (routeName === getConfig('RouteConfig', 'ViewRouteName')) {
            if (StoreService.state.filters.compare_startdate)
                query.compare_startdate = StoreService.state.filters.compare_startdate;
            if (StoreService.state.filters.compare_enddate)
                query.compare_enddate = StoreService.state.filters.compare_enddate;
            if (StoreService.state.filters.granularity)
                query.granularity = StoreService.state.filters.granularity;
        }
        if (StoreService.state.filters.brand_ids && StoreService.state.filters.brand_ids.length)
            query.brand_ids = StoreService.state.filters.brand_ids.join(',');
        if (StoreService.state.filters.keyword_ids && StoreService.state.filters.keyword_ids.length)
            query.keyword_ids = StoreService.state.filters.keyword_ids.join(',');
        if (StoreService.state.filters.languages && StoreService.state.filters.languages.length)
            query.languages = StoreService.state.filters.languages.join(',');
        if (routeName !== getConfig('RouteConfig', 'ContentsRouteName')) {
            if (StoreService.state.filters.sources && StoreService.state.filters.sources.length)
                query.sources = StoreService.state.filters.sources.join(',');
        }
        if (routeName !== getConfig('RouteConfig', 'PostsRouteName')) {
            if (StoreService.state.filters.categories && StoreService.state.filters.categories.length)
                query.categories = StoreService.state.filters.categories.join(',');
            if (StoreService.state.filters.media_focus)
                query.media_focus = "true";
        }
        if (getConfig('RouteConfig', 'PaginatedRouteNames').includes(routeName)) {
            if (StoreService.state.filters.page !== undefined)
                query.page = StoreService.state.filters.page.toString();
            if (StoreService.state.filters.num !== undefined)
                query.num = StoreService.state.filters.num.toString();
        }
        StoreService.dispatch('set', {
            stateProperty: 'filtersPreview',
            value: {},
        })
    }
    StoreService.dispatch('set', {
        stateProperty: 'filtersReady',
        value: true,
    })
    return(query);
}
