import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "navbar-light d-flex flex-column justify-content-between",
  id: "navbar-vertical"
}
const _hoisted_2 = {
  key: 0,
  class: "text-xs py-2 border-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["py-3 d-flex flex-column h-full", 
				Array.prototype.includes.call(
					_ctx.$config('RouteConfig', 'LoggedSettingsRouteNames'),
					_ctx.$route.name
				) ||
				Array.prototype.includes.call(
					_ctx.$config('RouteConfig', 'LoggedContentsWithoutNavBarVerticalRouteNames'),
					_ctx.$route.name
				) ||
				Array.prototype.includes.call(
					_ctx.$config('RouteConfig', 'LoggedClosableRouteNames'),
					_ctx.$route.name
				)
					? 'pt-0'
					: ''
			])
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.NavBarVerticalChildComponent)))
    ], 2),
    (
				_ctx.NavBarVerticalChildComponent === 'ProductNavBarVerticalDefaultComponent'
			)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            class: "d-block w-full text-body px-5",
            to: { name: _ctx.$config('RouteConfig', 'PrivacyPolicyRouteName') }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$beautify.firstLetterUppercase(
						_ctx.$text('routes_name', 'Route_Privacy_Policy_Name')
					)), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : _createCommentVNode("", true)
  ]))
}