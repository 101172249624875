// src/services/SetOrganizationInStore.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from '@/services/Router.service';
import { OrganizationType } from '@/types/Organization.type';

export default async function (organization?: OrganizationType | null) {
	return new Promise((resolve) => {
		console.log(
			'%c [Service] Set organization in store... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'organizationLoaded',
			value: false,
		}).then(() => {
			/* set organization passed by argument */
			if (organization !== undefined) {
				StoreService.dispatch('set', {
					stateProperty: 'organization',
					value: organization,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'organizationLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			/* set organization passed by url */
			else if (
				RouterService.currentRoute.value.params.organization_id &&
				StoreService.state.organizations!.find(
					(organization: OrganizationType) =>
						organization._id === RouterService.currentRoute.value.params.brand_id
				) !== undefined
			) {
				StoreService.dispatch('set', {
					stateProperty: 'organization',
					value: StoreService.state.organizations!.find(
						(organization: OrganizationType) =>
							organization._id === RouterService.currentRoute.value.params.organization_id
					),
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'organizationLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			/* set default organization */
			else {
				StoreService.dispatch('set', {
					stateProperty: 'organization',
					value: StoreService.state.organizations![0],
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'organizationLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
