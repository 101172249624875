import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "position-absolute top-18 end-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuccessAlert = _resolveComponent("SuccessAlert")!
  const _component_WarningAlert = _resolveComponent("WarningAlert")!
  const _component_ErrorAlert = _resolveComponent("ErrorAlert")!
  const _component_InfoAlert = _resolveComponent("InfoAlert")!

  return (_ctx.$store.state.alerts && _ctx.$store.state.alerts.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.alerts, (alert, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "mt-2",
            key: index
          }, [
            (alert.category === 'success')
              ? (_openBlock(), _createBlock(_component_SuccessAlert, {
                  key: 0,
                  title: alert.title,
                  texts: alert.texts,
                  onDismiss: ($event: any) => (_ctx.$store.state.alerts.splice(index, 1))
                }, null, 8, ["title", "texts", "onDismiss"]))
              : (alert.category === 'warning')
                ? (_openBlock(), _createBlock(_component_WarningAlert, {
                    key: 1,
                    title: alert.title,
                    texts: alert.texts,
                    onDismiss: ($event: any) => (_ctx.$store.state.alerts.splice(index, 1))
                  }, null, 8, ["title", "texts", "onDismiss"]))
                : (alert.category === 'error')
                  ? (_openBlock(), _createBlock(_component_ErrorAlert, {
                      key: 2,
                      title: alert.title,
                      texts: alert.texts,
                      onDismiss: ($event: any) => (_ctx.$store.state.alerts.splice(index, 1))
                    }, null, 8, ["title", "texts", "onDismiss"]))
                  : (_openBlock(), _createBlock(_component_InfoAlert, {
                      key: 3,
                      title: alert.title,
                      texts: alert.texts,
                      onDismiss: ($event: any) => (_ctx.$store.state.alerts.splice(index, 1))
                    }, null, 8, ["title", "texts", "onDismiss"]))
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}