
import { defineComponent } from 'vue';
import ModalDangerComponent from '@/components/modals/ModalDanger.component.vue';
import { RouteParamsRaw } from 'vue-router';
import SetUnsavedChangesToStoreService from '@/services/SetUnsavedChangesToStore.service';
import SubmittingService from '@/services/Submitting.service';

export default defineComponent({
	components: {
		ModalDangerComponent,
	},
	computed: {
		lastRouteName(): string {
			if (
				this.$store.state.last_product_route &&
				this.$store.state.last_product_route.name
			) {
				return this.$store.state.last_product_route.name;
			} else {
				return '';
			}
		},
		lastRouteParams(): RouteParamsRaw {
			if (
				this.$store.state.last_product_route &&
				this.$store.state.last_product_route.params
			) {
				return this.$store.state.last_product_route.params;
			} else {
				return {};
			}
		},
		lastRouteHash(): string {
			if (
				this.$store.state.last_product_route &&
				this.$store.state.last_product_route.hash
			) {
				return this.$store.state.last_product_route.hash;
			} else {
				return '';
			}
		},

		currentRouteFriendlyName(): string {
			return JSON.parse(
				JSON.stringify(this.$store.state.current_route.meta.friendlyName)
			);
		},
		currentRouteName(): string {
			if (
				this.$store.state.current_route &&
				this.$store.state.current_route.name
			) {
				return this.$store.state.current_route.name;
			} else {
				return '';
			}
		},
		currentRouteParams(): RouteParamsRaw {
			if (
				this.$store.state.current_route &&
				this.$store.state.current_route.params
			) {
				return this.$store.state.current_route.params;
			} else {
				return {};
			}
		},

		nextRouteName(): string {
			if (
				this.$store.state.next_route &&
				this.$store.state.next_route.name
			) {
				return this.$store.state.next_route.name;
			} else {
				return '';
			}
		},
		nextRouteParams(): RouteParamsRaw {
			if (
				this.$store.state.next_route &&
				this.$store.state.next_route.params
			) {
				return this.$store.state.next_route.params;
			} else {
				return {};
			}
		},
		nextRouteHash(): string {
			if (
				this.$store.state.next_route &&
				this.$store.state.next_route.hash
			) {
				return this.$store.state.next_route.hash;
			} else {
				return '';
			}
		},
	},
	methods: {
		goToLastRoute() {
			this.$router.push({
				name: this.lastRouteName,
				params: this.lastRouteParams as RouteParamsRaw,
				hash: this.lastRouteHash,
			});
		},
		goToCurrentParamsRoute() {
			if (
				this.currentRouteParams.organization_id &&
				this.currentRouteParams.space_id
			) {
				if (
					this.currentRouteName === this.$config('RouteConfig', 'RoleAddRouteName') ||
					this.currentRouteName === this.$config('RouteConfig', 'RoleUpdateRouteName')
				) {
					this.$router.push({
						name: this.$config('RouteConfig', 'SpaceRolesRouteName'),
						params: {
							organization_id: this.currentRouteParams.organization_id,
							space_id: this.currentRouteParams.space_id,
						},
					});
				} else if (
					this.currentRouteName === this.$config('RouteConfig', 'MediaAddRouteName') ||
					this.currentRouteName === this.$config('RouteConfig', 'MediaUpdateRouteName')
				) {
					this.$router.push({
						name: this.$config('RouteConfig', 'MediasRouteName'),
						params: {
							organization_id: this.currentRouteParams.organization_id,
							space_id: this.currentRouteParams.space_id,
						},
					});
				} else  {
					this.$router.push({
						name: this.$config('RouteConfig', 'ViewRouteName'),
						params: {
							organization_id: this.currentRouteParams.organization_id,
							space_id: this.currentRouteParams.space_id,
						},
					});
				}
			} else if (this.currentRouteParams.organization_id) {
				this.$router.push({
					name: this.$config('RouteConfig', 'SpaceRouteName'),
					params: {
						organization_id: this.currentRouteParams.organization_id,
					},
				});
			} else {
				this.$router.push({
					name: this.$config('RouteConfig', 'OrganizationRouteName'),
				});
			}
		},
		goToNextRoute() {
			this.$router.push({
				name: this.nextRouteName,
				params: this.nextRouteParams as RouteParamsRaw,
				hash: this.nextRouteHash,
			});
		},

		discardChangesAndGoLastRoute() {
			SetUnsavedChangesToStoreService(false).then(() => {
				if (this.lastRouteName) {
					this.goToLastRoute();
				} else {
					this.goToCurrentParamsRoute();
				}
			});
		},
		saveChangesAndGoLastRoute() {
			SubmittingService(true).then(() => {
				this.$store.state.current_form
					.onSubmit()
					.then(() => {
						SubmittingService(false).then(() => {
							SetUnsavedChangesToStoreService(false).then(() => {
								if (this.lastRouteName) {
									this.goToLastRoute();
								} else {
									this.goToCurrentParamsRoute();
								}
							});
						});
					})
					.catch((error: any) => {
						SubmittingService(false).then(() => {
							console.log(error);
						});
					});
			});
		},

		discardChanges() {
			SetUnsavedChangesToStoreService(false).then(() => {
				if (this.nextRouteName) {
					this.goToNextRoute();
				} else if (this.lastRouteName) {
					this.goToLastRoute();
				} else {
					this.goToCurrentParamsRoute();
				}
			});
		},
		saveChanges() {
			SubmittingService(true).then(() => {
				this.$store.state.current_form
					.onSubmit()
					.then(() => {
						SubmittingService(false).then(() => {
							SetUnsavedChangesToStoreService(false).then(() => {
								if (this.nextRouteName) {
									this.goToNextRoute();
								} else if (this.lastRouteName) {
									this.goToLastRoute();
								} else {
									this.goToCurrentParamsRoute();
								}
							});
						});
					})
					.catch((error: any) => {
						console.log(error);
						SubmittingService(false)
					});
				})
		},
	},
});
