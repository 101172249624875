import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderSpinnerComponent = _resolveComponent("LoaderSpinnerComponent")!

  return (_openBlock(), _createElementBlock("button", {
    class: "btn d-flex justify-content-center align-items-center",
    type: _ctx.type ? _ctx.type : 'button',
    disabled: _ctx.disabled
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          innerHTML: _ctx.icon
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.iconEnd)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "ps-2 order-2",
          innerHTML: _ctx.iconEnd
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      innerHTML: _ctx.$beautify.firstLetterUppercase(_ctx.label),
      class: _normalizeClass(_ctx.labelClass)
    }, null, 10, _hoisted_4),
    (_ctx.loading === true)
      ? (_openBlock(), _createBlock(_component_LoaderSpinnerComponent, {
          key: 2,
          class: _normalizeClass(["ms-2 mt-n1", _ctx.loaderClass ? _ctx.loaderClass : ''])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$beautify.firstLetterUppercase(_ctx.$text('actions', 'loading'))) + "...", 1)
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}