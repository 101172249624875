// src/services/SetKeywordIdsInStoreFilter.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from './Router.service';
import SetPageInStoreFiltersService from './SetPageInStoreFilters.service';

export default async function (keyword_ids?: Array<string>) {
	const updateQuery = function (resetPage: boolean = false) {
		return new Promise((resolve) => {
			if (resetPage) {
				SetPageInStoreFiltersService(0)
					.then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'keywordIdsFiltersReady',
							value: true,
						}).then(() => {
							resolve(true);
						});
					})
			}
			else {
				StoreService.dispatch('set', {
					stateProperty: 'keywordIdsFiltersReady',
					value: true,
				}).then(() => {
					resolve(true);
				});
			}
		});
	};

	return new Promise((resolve) => {
		console.log(
			'%c [Service] Set keyword ids in store... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'keywordIdsFiltersReady',
			value: false,
		}).then(() => {
			if (keyword_ids !== undefined) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'keyword_ids',
					value: keyword_ids,
				}).then(() => {
					updateQuery(true).then(() => {
						resolve(true);
					});
				});
			}
			/* keep keywords filters */
			else if (StoreService.state.filters.keyword_ids) {
				updateQuery(false).then(() => {
					resolve(true);
				});
			}
			/* init keywords filters */
			else {
				let keyword_ids: Array<string> = []
				if (
					StoreService.state.checkURLFilters === true &&
					RouterService.currentRoute.value.query.keyword_ids !== undefined
				) {
					if (StoreService.state.filters.keywords) {
						if (StoreService.state.filters.keywords.message) {
							StoreService.state.filters.keywords.message.forEach(keyword => {
								if (!keyword_ids.includes(keyword._id!)) {
									keyword_ids.push(keyword._id!)
								}
							});
						}
						if (StoreService.state.filters.keywords.spoke) {
							StoreService.state.filters.keywords.spoke.forEach(keyword => {
								if (!keyword_ids.includes(keyword._id!)) {
									keyword_ids.push(keyword._id!)
								}
							});
						}
					}
					const url_keyword_ids: Array<string> = (
						RouterService.currentRoute.value.query.keyword_ids as string
					).replace('%2C', ',').split(',');
					keyword_ids = keyword_ids.filter(id => url_keyword_ids.includes(id!));
				}
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'keyword_ids',
					value: keyword_ids,
				}).then(() => {
					updateQuery(!StoreService.state.checkURLFilters).then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
