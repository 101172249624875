// src/services/text.service.ts

// import texts
import { TextsConfig } from '@/configs/Texts.config';

// import App from vue to create a plugin
import { App, Plugin } from 'vue';

export function getText(objectName: string, property: string): string {
	if (Object.prototype.hasOwnProperty.call(TextsConfig, objectName)) {
		if (
			Object.prototype.hasOwnProperty.call(TextsConfig[objectName], property)
		) {
			return TextsConfig[objectName][property];
		}
	}
	return '';
}

// create and export config service
export const TextService: Plugin = {
	// required method to set config service as plugin
	install: (app: App) => {
		app.config.globalProperties.$text = getText;
	},
};

// set $text as global variable
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$text: any;
	}
}
