
import { defineComponent } from 'vue';
import { NavBarMixin } from '@/mixins/NavBar.mixin';

import PublicNavBarHorizontalClosableComponent from '@/components/navbars/PublicNavBarHorizontalClosable.component.vue';

export default defineComponent({
	/**
	 * business logic only
	 */
	mixins: [NavBarMixin],
	components: {
		PublicNavBarHorizontalClosableComponent,
	},
});
