// src/services/GetOrganizations.service.ts

import { StoreService } from '@/services/Store.service';
import { API } from '@/services/API.service';
import { getAlerts } from '@/services/Alerts.service';

export default async function () {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Service] Getting organizations... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'organizationsLoaded',
			value: false,
		}).then(() => {
			API.organizations('get')?.then((response: any) => {
				console.log(
					'%c [Service] Set organizations in store... ',
					'color: #0000FF;font-weight:bold;'
				);
				StoreService.dispatch('set', {
					stateProperty: 'organizations',
					value: response.data,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'organizationsLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}).catch((error: any) => {
				getAlerts.pushFromError(error)
				reject(error)
			});
		});
	});
}
