
import { defineComponent, ref } from 'vue';
import DateRangePicker from '@/components/vue2-daterange-picker/src/components/DateRangePicker.vue';
import moment from 'moment';
import { StoreService } from '@/services/Store.service';
import SetStartDateInStoreService from '@/services/SetStartDateInStore.service';
import SetEndDateInStoreService from '@/services/SetEndDateInStore.service';
import SetStartDateCompareInStoreService from '@/services/SetStartDateCompareInStore.service';
import SetEndDateCompareInStoreService from '@/services/SetEndDateCompareInStore.service';
import { DateRangesType } from '@/types/DateRanges.type';
import GetKeywordsService from '@/services/GetKeywords.service';
import SetKeywordIdsInStoreFiltersService from '@/services/SetKeywordIdsInStoreFilters.service';
import CheckFiltersService from '@/services/CheckFilters.service';

const DateRangePickerComponent: any = DateRangePicker;
type dataType = {
	compare: boolean;
	isSubmitting: boolean;
	saveButtonDisabled: boolean;
	shortcut: string;
	shortcutCompare: string;
	waitForCompare: boolean;
	waitForEnd: boolean;
};
export default defineComponent({
	data(): dataType {
		return {
			compare: false,
			isSubmitting: false,
			saveButtonDisabled: true,
			shortcut: 'custom',
			shortcutCompare: 'custom',
			waitForCompare: false,
			waitForEnd: false,
		};
	},
	components: {
		DateRangePickerComponent,
	},
	setup() {
		const daterange = ref<DateRangesType>({});
		const initialDaterange = ref<DateRangesType>({});
		const selection = ref<DateRangesType>({});
		return {
			daterange,
			initialDaterange,
			selection,
		};
	},
	created() {
		SetStartDateInStoreService().then(() => {
			SetEndDateInStoreService().then(() => {
				SetStartDateCompareInStoreService().then(() => {
					SetEndDateCompareInStoreService().then(() => {
						this.initializeDateRanges()
						StoreService.dispatch('set', {
							stateProperty: 'datesLoaded',
							value: true,
						});
					});
				});
			});
		});
	},
	computed: {
		dateRangeComputed(): any {
			if (!this.daterange.startDate || !this.daterange.endDate) {
				return {
					startDate: null,
					endDate: null,
				};
			}
			return {
				startDate: moment(this.dateToString(this.daterange.startDate))
					.startOf('day')
					.toDate(),
				endDate: moment(this.dateToString(this.daterange.endDate))
					.endOf('day')
					.toDate(),
			};
		},
		startDateButton(): string {
			if (!this.daterange.startDate) return '';
			return moment(this.dateToString(this.daterange.startDate))
				.startOf('day')
				.format('ll');
		},
		endDateButton(): string {
			if (!this.daterange.endDate) return '';
			return moment(this.dateToString(this.daterange.endDate))
				.endOf('day')
				.format('ll');
		},
		startDateCompareButton(): string {
			if (!this.daterange.startDateCompare) return '';
			return moment(this.dateToString(this.daterange.startDateCompare))
				.startOf('day')
				.format('ll');
		},
		endDateCompareButton(): string {
			if (!this.daterange.endDateCompare) return '';
			return moment(this.dateToString(this.daterange.endDateCompare))
				.endOf('day')
				.format('ll');
		},
		startDateHeader(): string {
			if (!this.daterange.startDate) return '';
			return moment(this.dateToString(this.daterange.startDate))
				.startOf('day')
				.format('ll');
		},
		endDateHeader(): string {
			if (!this.daterange.endDate) return '';
			return moment(this.dateToString(this.daterange.endDate))
				.endOf('day')
				.format('ll');
		},
		startDateCompareHeader(): string {
			if (!this.daterange.startDateCompare) return '';
			return moment(this.dateToString(this.daterange.startDateCompare))
				.startOf('day')
				.format('ll');
		},
		endDateCompareHeader(): string {
			if (!this.daterange.endDateCompare) return '';
			return moment(this.dateToString(this.daterange.endDateCompare))
				.endOf('day')
				.format('ll');
		},
		shortcutsComputed(): any {
			return {
				'This Week': [
					moment().startOf('week').toDate(),
					moment().endOf('day').toDate(),
				],
				'Last Week': [
					moment().subtract(1, 'week').startOf('week').toDate(),
					moment().subtract(1, 'week').endOf('week').toDate(),
				],
				'This Month': [
					moment().startOf('month').toDate(),
					moment().endOf('day').toDate(),
				],
				'Last Month': [
					moment().subtract(1, 'month').startOf('month').toDate(),
					moment().subtract(1, 'month').endOf('month').toDate(),
				],
				'This Quarter': [
					moment().startOf('quarter').toDate(),
					moment().endOf('day').toDate(),
				],
				'Last Quarter': [
					moment().subtract(1, 'quarter').startOf('quarter').toDate(),
					moment().subtract(1, 'quarter').endOf('quarter').toDate(),
				],
				'This Year': [
					moment().startOf('year').toDate(),
					moment().endOf('day').toDate(),
				],
				'Last Year': [
					moment().subtract(1, 'year').startOf('year').toDate(),
					moment().subtract(1, 'year').endOf('year').toDate(),
				],
			};
		},
		shortcutsCompareComputed(): any {
			if (!this.daterange.startDate || !this.daterange.endDate) {
				return [];
			}
			return {
				// 'Previous Period': [
				// 	moment(this.dateToString(this.daterange.startDate))
				// 		.startOf('week')
				// 		.toDate()
				// 	,
				// 	moment()
				// 		.endOf('day')
				// 		.toDate()
				// ],
				'Same Period Last Year': [
					moment(this.dateToString(this.daterange.startDate))
						.subtract(52, 'week')
						.startOf('day')
						.toDate(),
					moment(this.dateToString(this.daterange.endDate))
						.subtract(52, 'week')
						.endOf('day')
						.toDate(),
				],
				// 'Previous Period (days matching)': [
				// 	moment()
				// 		.startOf('week')
				// 		.toDate()
				// 	,
				// 	moment()
				// 		.endOf('day')
				// 		.toDate()
				// ],
				'Same Period Last Year (days matching)': [
					moment(this.dateToString(this.daterange.startDate))
						.subtract(1, 'year')
						.startOf('day')
						.toDate(),
					moment(this.dateToString(this.daterange.endDate))
						.subtract(1, 'year')
						.endOf('day')
						.toDate(),
				],
			};
		},
	},

	watch: {
		compare: function (value: boolean) {
			if (value) {
				if (
					!this.daterange.startDateCompare ||
					!this.daterange.endDateCompare
				) {
					this.setDefaultCompareSelection();
				}
			} else {
				this.daterange.startDateCompare = null;
				this.daterange.endDateCompare = null;
				this.shortcutCompare = 'custom';
			}
		},
		daterange: {
			handler(value: DateRangesType) {
				if (this.$toolbox.entitiesAreDifferent(value, this.initialDaterange)) {
					this.saveButtonDisabled = false;
				} else {
					this.saveButtonDisabled = true;
				}
			},
			deep: true,
		},
		'$route.query': {
			handler() {
				this.initializeDateRanges()
			},
			deep: true,
		},
	},

	methods: {
		initializeDateRanges() {
			this.daterange = {
				startDate: this.stringToDate(this.$store.state.filters.startdate),
				endDate: this.stringToDate(this.$store.state.filters.enddate),
				startDateCompare: this.stringToDate(
					this.$store.state.filters.compare_startdate
				),
				endDateCompare: this.stringToDate(
					this.$store.state.filters.compare_enddate
				),
			};
			this.initialDaterange = JSON.parse(JSON.stringify(this.daterange));
			this.selection = {
				startDate: this.daterange.startDate,
				startDateCompare: this.daterange.startDateCompare,
			};
			if (
				this.$route.name ===
					this.$config('RouteConfig', 'ViewRouteName') &&
				this.daterange.startDateCompare &&
				this.daterange.endDateCompare
			) {
				this.compare = true;
			}
		},

		stringToDate(date: string): Date | null {
			return date ? new Date(date) : null;
		},
		dateToString(date: any): string {
			return moment(date).format('YYYY-MM-DD');
		},

		selectDate(date: any) {
			this.shortcut = 'custom';
			if (!this.waitForEnd) {
				this.waitForEnd = true;
				if (!this.waitForCompare) {
					this.selection.startDate = date;
					if (this.daterange.endDate && date > this.daterange.endDate) {
						this.daterange.startDate = this.daterange.endDate;
						this.daterange.endDate = date;
					} else {
						this.daterange.startDate = date;
					}
				} else {
					this.selection.startDateCompare = date;
					if (
						this.daterange.endDateCompare &&
						date > this.daterange.endDateCompare
					) {
						this.daterange.startDateCompare = this.daterange.endDateCompare;
						this.daterange.endDateCompare = date;
					} else {
						this.daterange.startDateCompare = date;
					}
				}
			} else {
				this.waitForEnd = false;
				if (!this.waitForCompare) {
					if (this.compare) this.waitForCompare = true;
					if (this.selection.startDate && date < this.selection.startDate) {
						this.daterange.endDate = this.selection.startDate;
						this.daterange.startDate = date;
						this.selection.startDate = date;
					} else {
						this.daterange.endDate = date;
					}
				} else {
					this.waitForCompare = false;
					if (
						this.selection.startDateCompare &&
						date < this.selection.startDateCompare
					) {
						this.daterange.endDateCompare = this.selection.startDateCompare;
						this.daterange.startDateCompare = date;
						this.selection.startDateCompare = date;
					} else {
						this.daterange.endDateCompare = date;
					}
				}
				this.checkReverse();
			}
		},
		selectRange(range: any, name: string) {
			this.waitForEnd = false;
			if (!this.waitForCompare) {
				if (this.compare) this.waitForCompare = true;
				this.shortcut = name;
				this.selection.startDate = range[0];
				this.daterange.startDate = range[0];
				this.daterange.endDate = range[1];
			} else {
				this.waitForCompare = false;
				this.shortcutCompare = name;
				this.selection.startDateCompare = range[0];
				this.daterange.startDateCompare = range[0];
				this.daterange.endDateCompare = range[1];
			}
			this.checkReverse();
		},
		selectCompareRange(range: any, name: string) {
			this.waitForEnd = false;
			this.waitForCompare = false;
			this.shortcutCompare = name;
			this.selection.startDateCompare = range[0];
			this.daterange.startDateCompare = range[0];
			this.daterange.endDateCompare = range[1];
		},
		checkReverse() {
			// check reverse ranges
			if (
				this.daterange.startDate &&
				this.daterange.endDate &&
				this.daterange.startDateCompare &&
				this.daterange.endDateCompare &&
				this.selection.startDate &&
				this.selection.startDateCompare
			) {
				if (
					this.daterange.startDate.getTime() +
						this.daterange.endDate.getTime() <
					this.daterange.startDateCompare.getTime() +
						this.daterange.endDateCompare.getTime()
				) {
					// swap start and startCompare
					let dateSwap = this.daterange.startDate;
					this.daterange.startDate = this.daterange.startDateCompare;
					this.daterange.startDateCompare = dateSwap;
					// swap end and endCompare
					dateSwap = this.daterange.endDate;
					this.daterange.endDate = this.daterange.endDateCompare;
					this.daterange.endDateCompare = dateSwap;
					// swap end and endCompare
					dateSwap = this.selection.startDate;
					this.selection.startDate = this.selection.startDateCompare;
					this.selection.startDateCompare = dateSwap;
					// swap shortcut and shortcutCompare
					let shortcutSwap = this.shortcut;
					this.shortcut = this.shortcutCompare;
					this.shortcutCompare = shortcutSwap;
				}
			}
		},

		setWaits(forEnd: boolean, forCompare: boolean) {
			this.waitForEnd = forEnd;
			this.waitForCompare = forCompare;
		},
		setDefaultCompareSelection() {
			if (this.daterange.startDate && this.daterange.endDate) {
				switch (this.shortcut) {
					case 'This Week':
					case 'Last Week':
						this.daterange.startDateCompare = moment(
							this.dateToString(this.daterange.startDate)
						)
							.subtract(1, 'week')
							.startOf('week')
							.toDate();
						this.daterange.endDateCompare = moment(
							this.dateToString(this.daterange.endDate)
						)
							.subtract(1, 'week')
							.endOf('week')
							.toDate();
						break;
					case 'This Month':
					case 'Last Month':
						this.daterange.startDateCompare = moment(
							this.dateToString(this.daterange.startDate)
						)
							.subtract(1, 'month')
							.startOf('month')
							.toDate();
						this.daterange.endDateCompare = moment(
							this.dateToString(this.daterange.endDate)
						)
							.subtract(1, 'month')
							.endOf('month')
							.toDate();
						break;
					case 'This Quarter':
					case 'Last Quarter':
						this.daterange.startDateCompare = moment(
							this.dateToString(this.daterange.startDate)
						)
							.subtract(1, 'quarter')
							.startOf('quarter')
							.toDate();
						this.daterange.endDateCompare = moment(
							this.dateToString(this.daterange.endDate)
						)
							.subtract(1, 'quarter')
							.endOf('quarter')
							.toDate();
						break;
					case 'This Year':
					case 'Last Year':
						this.daterange.startDateCompare = moment(
							this.dateToString(this.daterange.startDate)
						)
							.subtract(1, 'year')
							.startOf('year')
							.toDate();
						this.daterange.endDateCompare = moment(
							this.dateToString(this.daterange.endDate)
						)
							.subtract(1, 'year')
							.endOf('year')
							.toDate();
						break;
					default:
						this.daterange.startDateCompare = moment(
							this.dateToString(this.daterange.startDate)
						)
							.subtract(
								moment
									.duration(
										moment(this.dateToString(this.daterange.endDate)).diff(
											this.dateToString(this.daterange.startDate)
										)
									)
									.asDays() + 1,
								'day'
							)
							.startOf('day')
							.toDate();
						this.daterange.endDateCompare = moment(
							this.dateToString(this.daterange.startDate)
						)
							.subtract(1, 'day')
							.endOf('day')
							.toDate();
				}
			}
			this.waitForCompare = false;
		},

		hoverDate(date: any) {
			if (this.waitForEnd && !this.waitForCompare) {
				if (this.selection.startDate && date < this.selection.startDate) {
					this.daterange.endDate = this.selection.startDate;
					this.daterange.startDate = date;
				} else {
					this.daterange.startDate = this.selection.startDate;
					this.daterange.endDate = date;
				}
			} else if (this.waitForEnd && this.waitForCompare) {
				if (
					this.selection.startDateCompare &&
					date < this.selection.startDateCompare
				) {
					this.daterange.endDateCompare = this.selection.startDateCompare;
					this.daterange.startDateCompare = date;
				} else {
					this.daterange.startDateCompare = this.selection.startDateCompare;
					this.daterange.endDateCompare = date;
				}
			}
		},
		// Overload calendar date formatting
		dateFormat(classes: any, date: any) {
			let dt = new Date(date);
			dt.setHours(0, 0, 0, 0);

			if (this.daterange.startDate && this.daterange.endDate) {
				let start = new Date(this.daterange.startDate.toString());
				start.setHours(0, 0, 0, 0);
				let end = new Date(this.daterange.endDate.toString());
				end.setHours(0, 0, 0, 0);

				classes['in-range'] = dt >= start && dt <= end;
				classes['start-date'] = dt.getTime() === start.getTime();
				classes['end-date'] = dt.getTime() === end.getTime();
			}
			if (
				this.compare &&
				this.daterange.startDateCompare &&
				this.daterange.endDateCompare
			) {
				let start = new Date(this.daterange.startDateCompare.toString());
				start.setHours(0, 0, 0, 0);
				let end = new Date(this.daterange.endDateCompare.toString());
				end.setHours(0, 0, 0, 0);

				classes['in-compare-range'] = dt >= start && dt <= end;
				classes['start-date-compare'] = dt.getTime() === start.getTime();
				classes['end-date-compare'] = dt.getTime() === end.getTime();
			}
			classes['active'] = false;
			return classes;
		},

		async handleSubmit() {
			this.isSubmitting = true;
			await SetStartDateInStoreService(
				this.dateToString(this.daterange.startDate)
			);
			await SetEndDateInStoreService(this.dateToString(this.daterange.endDate));
			if (this.compare) {
				await SetStartDateCompareInStoreService(
					this.dateToString(this.daterange.startDateCompare)
				);
				await SetEndDateCompareInStoreService(
					this.dateToString(this.daterange.endDateCompare)
				);
			} else {
				await SetStartDateCompareInStoreService(null);
				await SetEndDateCompareInStoreService(null);
			}
			await GetKeywordsService();
			await SetKeywordIdsInStoreFiltersService();
			await CheckFiltersService();
			this.initialDaterange = JSON.parse(JSON.stringify(this.daterange));
			this.saveButtonDisabled = true;
			this.isSubmitting = false;
		},
	},
});
