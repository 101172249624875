
import { defineComponent } from 'vue';

export default defineComponent({
	data() {
		return {
			menu: [
				{
					name: 'Content',
					textCategory: 'words',
					items: [
						{
							name: 'Content_Category',
							link: this.$config('RouteConfig', 'HashAddContentRoute'),
							textCategory: 'labels',
						},
						{
							name: 'Content_Date',
							link: '#date',
							textCategory: 'labels',
						},
						{
							name: 'Content_Title',
							link: '#title',
							textCategory: 'labels',
						},
						{
							name: 'Content_Link',
							link: '#link',
							textCategory: 'labels',
						},
					],
				},
				{
					name: 'ImportContents',
					textCategory: 'actions',
					items: [
						{
							name: 'ImportFile',
							link: this.$config('RouteConfig', 'HashImportFileContentRoute'),
							textCategory: 'actions',
						},
						{
							name: 'CheckInformation',
							link: '#check-information',
							textCategory: 'actions',
						},
					],
				},
			],
		};
	},
	created() {
		if (this.$route.hash === '') {
			this.$router.push({
				name: this.$route.name?.toString(),
				hash: this.menu[0].items[0].link,
			});
		}
	},
	computed: {
		menuComputed() {
			if (this.$store.state.importFileMode.value === false) {
				return [
					{
						name: 'Content',
						textCategory: 'words',
						items: [
							{
								name: 'Content_Category',
								link: this.$config('RouteConfig', 'HashAddContentRoute'),
								textCategory: 'labels',
							},
							{
								name: 'Content_Date',
								link: '#date',
								textCategory: 'labels',
							},
							{
								name: 'Content_Title',
								link: '#title',
								textCategory: 'labels',
							},
							{
								name: 'Content_Link',
								link: '#link',
								textCategory: 'labels',
							},
						],
					},
				];
			} else {
				return [
					{
						name: 'ImportContents',
						textCategory: 'actions',
						items: [
							{
								name: 'ImportFile',
								link: this.$config('RouteConfig', 'HashImportFileContentRoute'),
								textCategory: 'actions',
							},
							{
								name: 'CheckInformation',
								link: '#check-information',
								textCategory: 'actions',
							},
						],
					},
				];
			}
		},
	},
});
