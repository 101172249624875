
import { defineComponent } from 'vue';
import { IndexMixin } from '@/mixins/Index.mixin';
import PublicNavBarHorizontalComponent from '@/components/PublicNavBarHorizontal.component.vue';

export default defineComponent({
	mixins: [IndexMixin],

	components: {
		PublicNavBarHorizontalComponent,
	},
});
