// src/services/SetUnsavedChanges.service.ts

import { StoreService } from '@/services/Store.service';

// unsaved_changes boolean true/false
export default async function (boolean: boolean) {
	return new Promise((resolve) => {
		// if (boolean === true) {
		// 	console.log(
		// 		'%c [Service] There are unsaved changes... ',
		// 		'color: #0000FF;font-weight:bold;'
		// 	);
		// }
		StoreService.dispatch('set', {
			stateProperty: 'unsaved_changes',
			value: boolean,
		}).then(() => {
			resolve(true);
		});
	});
}
