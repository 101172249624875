import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import SetOrganizationInStoreService from '@/services/SetOrganizationInStore.service';
import { StoreService } from '@/services/Store.service';
import { OrganizationType } from '@/types/Organization.type';
import GetOrganizationService from '@/services/GetOrganization.service';

export async function LoadOrganizationMiddleware(to: any, next: any) {
	const updateOrganization = function (organization: OrganizationType | null) {
		return new Promise((resolve) => {
			// set organization in store
			SetOrganizationInStoreService(organization).then(() => {
				// set child entities loaded status to false
				StoreService.dispatch('set', {
					stateProperty: 'spacesLoaded',
					value: false,
				}).then(() => {
					if (!StoreService.state.organization!.owner) {
						GetOrganizationService().then((response: OrganizationType) => {
							SetOrganizationInStoreService(response).then(() => {
								resolve(true);
							});
						});
					} else {
						resolve(true);
					}
				});
			});
		});
	};

	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Organization loading... ',
			'color: #FF0000;font-weight:bold;'
		);
		// organization_id is provided
		if (to.params.organization_id) {
			// replace provided organization
			const organization = StoreService.state.organizations.find(
				(entity: OrganizationType) => entity._id === to.params.organization_id
			);
			if (organization) {
				updateOrganization(organization).then(() => {
					resolve(true);
				});
			} else {
				// redirect on not found route
				RouterService.push({
					name: getConfig('RouteConfig', 'Error404RouteName'),
				}).then(() => {
					next(false);
				});
			}
		}
		// organization_id is not provided but loaded organizations not empty
		else if (StoreService.state.organizations.length > 0) {
			updateOrganization(StoreService.state.organizations[0]).then(() => {
				resolve(true);
			});
		}
		// organization_id is not provided and loaded organizations is empty
		else {
			updateOrganization(null).then(() => {
				resolve(true);
			});
		}
	});
}
