// src/services/CreateDefaultSpace.service.ts
import { API } from '@/services/API.service';
import { getConfig } from '@/services/Config.service';
import { StoreService } from '@/services/Store.service';
import { SpaceType } from '@/types/Space.type';
import { getAlerts } from '@/services/Alerts.service';

export default async function (organization_id?: string) {
	if (organization_id === undefined) {
		organization_id = StoreService.state.organization?._id;
	}
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Service] Create default space... ',
			'color: #0000FF;font-weight:bold;'
		);
		const space: SpaceType = {
			name: getConfig('AppConfig', 'FirstSpaceName') as string,
		};
		API.organizationSpaces('post', space, {
			organization_id: organization_id,
		})
			?.then((response: any) => {
				resolve(response);
			})
			.catch((error: any) => {
				getAlerts.pushFromError(error)
				reject(error)
			});
	});
}
