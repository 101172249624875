
import { defineComponent } from 'vue';
import { IndexMixin } from '@/mixins/Index.mixin';
import PublicLayout from '@/layouts/NotLogged/Public.layout.vue';
import NotLoggedClosableLayout from '@/layouts/NotLogged/Closable.layout.vue';
import AuthenticationLayout from '@/layouts/NotLogged/Authentication.layout.vue';
import LoadingLayout from '@/layouts/NotLogged/Loading.layout.vue';
import LoggedOnboardingLayout from '@/layouts/Logged/Onboarding.layout.vue';
import LoggedClosableLayout from '@/layouts/Logged/Closable.layout.vue';
import LoggedContentLayout from '@/layouts/Logged/Content.layout.vue';
import LoggedContentsWithoutNavBarVerticalLayout from '@/layouts/Logged/ContentsWithoutNavBarVertical.layout.vue';
import LoggedSettingsLayout from '@/layouts/Logged/Settings.layout.vue';
import LoggedSettingsWithoutNavBarVerticalLayout from '@/layouts/Logged/SettingsWithoutNavBarVertical.layout.vue';
import LoggedSettingsWithoutNavBarLayout from '@/layouts/Logged/SettingsWithoutNavBar.layout.vue';
import EmbededErrorLayout from '@/layouts/Logged/EmbededError.layout.vue';
import ErrorLayout from '@/layouts/NotLogged/Error.layout.vue';
import UnvailableServiceLayout from '@/layouts/NotLogged/UnvailableService.layout.vue';
import DisableMobileVue from '@/vues/DisableMobile.vue';
import { useCookies } from "vue3-cookies";
import {getConfig} from "@/services/Config.service";

export default defineComponent({
	mixins: [IndexMixin],

  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },

	components: {
		AuthenticationLayout,
		DisableMobileVue,
		EmbededErrorLayout,
		ErrorLayout,
		LoadingLayout,
		LoggedClosableLayout,
		LoggedContentLayout,
		LoggedContentsWithoutNavBarVerticalLayout,
		LoggedOnboardingLayout,
		LoggedSettingsLayout,
		LoggedSettingsWithoutNavBarVerticalLayout,
		LoggedSettingsWithoutNavBarLayout,
		PublicLayout,
		NotLoggedClosableLayout,
		UnvailableServiceLayout,
	},

	computed: {
		isMobile(): boolean {
			return this.$toolbox.isMobile();
		},
	},

	mounted() {
    const userTokenLocalStorageKey = getConfig('AppConfig', 'UserTokenLocalStorageKey') as string;
    const jwtLocalStorage = localStorage.getItem(userTokenLocalStorageKey);
    const jwtCookie = this.cookies.get(userTokenLocalStorageKey);
    if (jwtLocalStorage && !jwtCookie) {
      this.cookies.set(userTokenLocalStorageKey, jwtLocalStorage);
    }
  },

	methods: {},
});
