
import { defineComponent } from 'vue';
import LogoComponent from '@/components/Logo.component.vue';
import DropdownComponent from '@/components/Dropdown.component.vue';
import { Dropdown, Modal } from 'bootstrap';
import { AuthenticationService } from '@/services/Authentication.service';
import { OrganizationType } from '@/types/Organization.type';

export default defineComponent({
	/**
	 * business logic only
	 */
	components: { LogoComponent, DropdownComponent },
	mounted() {
		var dropdownElementList = [].slice.call(
			document.querySelectorAll('.dropdown-toggle')
		);
		if (dropdownElementList.length > 0) {
			dropdownElementList.map(function (dropdownToggleEl) {
				return new Dropdown(dropdownToggleEl);
			});
		}
	},
	methods: {
		logout() {
			AuthenticationService.logoutUser();
		},
		switchOrganization(organization: OrganizationType) {
			/* redirect to organization */
			this.$router.push({
				name: this.$config('RouteConfig', 'OrganizationRouteName') as string,
				params: {
					organization_id: organization._id as string,
				},
			});
		},
		showPaymentModal() {
			var modalRequirePayment = new Modal(
				document.getElementById('modal-user-required-payement') as Element
			);
			modalRequirePayment.show();
		},
	},
});
