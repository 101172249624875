import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "fluid-layout",
  class: "h-full"
}
const _hoisted_2 = {
  class: "d-flex h-full w-full",
  id: "wrapper-main-settings"
}
const _hoisted_3 = {
  id: "main-wrapper",
  class: "w-full d-flex offset-navbar-horizontal"
}
const _hoisted_4 = {
  id: "main",
  class: "bg-secondary col h-full"
}
const _hoisted_5 = {
  id: "content",
  class: "p-6 pt-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductNavBarHorizontalComponent = _resolveComponent("ProductNavBarHorizontalComponent")!
  const _component_AlertsGroup = _resolveComponent("AlertsGroup")!
  const _component_ProgressBarComponent = _resolveComponent("ProgressBarComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ProductNavBarHorizontalComponent, { class: "bg-white" }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_AlertsGroup),
    _createVNode(_component_ProgressBarComponent)
  ], 64))
}