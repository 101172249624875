// src/services/SetStartDateCompareInStore.service.ts

import { StoreService } from '@/services/Store.service';
import moment from 'moment';
import { getLimitation } from './Limitation.service';
import SetPageInStoreFiltersService from './SetPageInStoreFilters.service';

export default async function (startdate?: string | null) {
	const updateQuery = function (resetPage: boolean = false) {
		return new Promise((resolve) => {
			if (resetPage) {
				SetPageInStoreFiltersService(0)
					.then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'startDateCompareFiltersReady',
							value: true,
						}).then(() => {
							resolve(true);
						});
					})
			}
			else {
				StoreService.dispatch('set', {
					stateProperty: 'startDateCompareFiltersReady',
					value: true,
				}).then(() => {
					resolve(true);
				});
			}
		});
	};

	return new Promise((resolve) => {
		StoreService.dispatch('set', {
			stateProperty: 'startDateCompareFiltersReady',
			value: false,
		}).then(() => {
			if (startdate !== undefined) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'compare_startdate',
					value: startdate,
				}).then(() => {
					updateQuery(true).then(() => {
						resolve(true);
					});
				});
			}
			// set with url (arrive on product)
			else if (
				StoreService.state.checkURLFilters === true &&
				new URLSearchParams(window.location.search).get('compare_startdate') &&
				!getLimitation.datesLimitedAccess()
			) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'compare_startdate',
					value: moment(
						new URLSearchParams(window.location.search).get('compare_startdate'),
						'YYYY-MM-DD'
					)
						.startOf('day')
						.format('YYYY-MM-DD'),
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
			else if (StoreService.state.filters?.compare_startdate) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'compare_startdate',
					value: moment(
						StoreService.state.filters?.compare_startdate,
						'YYYY-MM-DD'
					)
						.startOf('day')
						.format('YYYY-MM-DD'),
				}).then(() => {
					updateQuery(true).then(() => {
						resolve(true);
					});
				});
			}
			else {
				updateQuery().then(() => {
					resolve(true);
				});
			}
		});
	});
}
