// src/services/API.service.ts

// import App from vue to create a plugin
import { App, Plugin } from 'vue';
import { axios } from '@/services/Axios.service';
import { getConfig } from '@/services/Config.service';
import { EmailType } from '@/types/Email.type';
import { LoginType } from '@/types/Login.type';
import { RegisterType } from '@/types/Register.type';
import { RegisterSendType } from '@/types/RegisterSend.type';
import { RegisterVerifyType } from '@/types/RegisterVerify.type';
import { PasswordResetType } from '@/types/PasswordReset.type';
import { PasswordSendType } from '@/types/PasswordSend.type';
import { TokenCheckType } from '@/types/TokenCheck.type';
import { OrganizationType } from '@/types/Organization.type';
import { SpaceType } from '@/types/Space.type';
import { APIParametersType } from '@/types/APIParameters.type';
import { UserType } from '@/types/User.type';
import { BrandType } from '@/types/Brand.type';
import { KeywordType } from '@/types/Keyword.type';
import { QueryType } from '@/types/Query.type';
import { ViewType } from '@/types/View.type';
import { ContentType } from '@/types/Content.type';
import { PostType } from '@/types/Post.type';
import { MediaType } from '@/types/Media.type';
import { ObservationType } from '@/types/Observation.type';
import { GoogleSSOCallbackType } from '@/types/GoogleSSOCallback.type';
import { RoleType } from '@/types/Role.type';
import { PaymentMethodType } from '@/types/PaymentMethod.type';
import { SubscriptionType } from '@/types/Subscription.type';
import { SpaceNotificationConfiguration } from '@/types/Notification.type';

const BackendAPIPrefix = getConfig('AppConfig', 'BackendAPIPrefix');

const authHeader = () => {
	const jwt = JSON.parse(
		JSON.stringify(
			localStorage.getItem(
				getConfig('AppConfig', 'UserTokenLocalStorageKey') as string
			) as string
		)
	);

	if (jwt) {
		// return { Authorization: `Bearer ${jwt}` };
		return { Authorization: `${jwt}` };
	}
	return {};
};

const headers = () => {
	return {
		headers: authHeader(),
	};
};

export const API = {
	/**
	 * Authentication
	 */

	// email
	email(object: EmailType) {
		return axios.post(BackendAPIPrefix + '/email', object);
	},
	// login
	login(object: LoginType) {
		return axios.post(BackendAPIPrefix + '/login', object);
	},
	// login/google
	loginGoogle() {
		return axios.get(BackendAPIPrefix + '/login/google');
	},
	// login/google/callback
	loginGoogleCallback(object: GoogleSSOCallbackType) {
		return axios.post(BackendAPIPrefix + '/login/google/callback', object);
	},
	// register
	register(object: RegisterType) {
		return axios.post(BackendAPIPrefix + '/register', object);
	},
	// register/send
	registerSend(object: RegisterSendType) {
		return axios.post(BackendAPIPrefix + '/register/send', object);
	},
	// register/verify
	registerVerify(object: RegisterVerifyType) {
		return axios.post(BackendAPIPrefix + '/register/verify', object);
	},
	// password/send
	passwordSend(object: PasswordSendType) {
		return axios.post(BackendAPIPrefix + '/password/send', object);
	},
	// password/reset
	passwordReset(object: PasswordResetType) {
		return axios.post(BackendAPIPrefix + '/password/reset', object);
	},
	// token/check
	tokenCheck(object: TokenCheckType) {
		return axios.post(BackendAPIPrefix + '/token/check', object);
	},
	/**
	 * User
	 */
	// /profile
	profile(
		type: string,
		object?: UserType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(BackendAPIPrefix + '/profile', headers());
			case 'post':
				return axios.post(BackendAPIPrefix + '/profile', object, headers());
			case 'put':
				return axios.put(BackendAPIPrefix + '/profile', object, headers());
		}
	},
	// /products
	products(
		type: string,
		object?: UserType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(BackendAPIPrefix + '/products', headers());
		}
	},
	// /payment-methods
	paymentMethods(
		type: string,
		object?: PaymentMethodType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(BackendAPIPrefix + '/payment-methods', headers());
			case 'post':
				return axios.post(
					BackendAPIPrefix + '/payment-methods',
					object,
					headers()
				);
		}
	},
	// /subscription/coupons
	coupons(
		type: string,
	) {
		switch (type) {
			case 'get':
				return axios.get(BackendAPIPrefix + '/subscription/coupons', headers());
		}
	},
	// /payment-method
	paymentMethod(
		type: string,
		object?: PaymentMethodType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix + '/payment-method/' + parameters?.payment_method_id,
					headers()
				);
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/payment-method/' + parameters?.payment_method_id,
					object,
					headers()
				);
			case 'delete':
				return axios.delete(
					BackendAPIPrefix + '/payment-method/' + parameters?.payment_method_id,
					headers()
				);
		}
	},
	// /setup-intents
	setupIntents(type: string) {
		switch (type) {
			case 'get':
				return axios.get(BackendAPIPrefix + '/setup-intents', headers());
		}
	},
	// /analytics
	analytics(type: string, object?: null, parameters?: APIParametersType) {
		switch (type) {
			case 'get':
				return axios.get(BackendAPIPrefix + '/analytics', headers());
		}
	},
	// /analytics/google
	analyticsGoogle() {
		return axios.get(BackendAPIPrefix + '/analytics/google', headers());
	},
	// analytics/google/callback
	analyticsGoogleCallback(object: GoogleSSOCallbackType) {
		return axios.post(
			BackendAPIPrefix + '/analytics/google/callback',
			object,
			headers()
		);
	},

	/**
	 * Organizations
	 */
	// /organizations (user organizations)
	organizations(type: string, object?: OrganizationType | null) {
		switch (type) {
			case 'get':
				return axios.get(BackendAPIPrefix + '/organizations', headers());
			case 'post':
				return axios.post(
					BackendAPIPrefix + '/organizations',
					object,
					headers()
				);
		}
	},
	// /organization/{organization_id}
	organization(
		type: string,
		object?: OrganizationType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix + '/organization/' + parameters?.organization_id,
					headers()
				);
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/organization/' + parameters?.organization_id,
					object,
					headers()
				);
			case 'delete':
				return axios.delete(
					BackendAPIPrefix + '/organization/' + parameters?.organization_id,
					headers()
				);
		}
	},
	/**
	 * Role
	 */
	// /organization/{organization_id}/roles
	organizationRoles(
		type: string,
		object?: RoleType | null,
		parameters?: APIParametersType
	) {
		const query = parameters?.query ? '?' + parameters?.query : '';
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/organization/' +
						parameters?.organization_id +
						'/roles' +
						query,
					headers()
				);
			case 'post':
				return axios.post(
					BackendAPIPrefix +
						'/organization/' +
						parameters?.organization_id +
						'/roles',
					object,
					headers()
				);
		}
	},

	/**
	 * Spaces
	 */
	// /spaces (user organization spaces)
	// //organization/{organization_id}/spaces
	organizationSpaces(
		type: string,
		object?: SpaceType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/organization/' +
						parameters?.organization_id +
						'/spaces',
					headers()
				);
			case 'post':
				return axios.post(
					BackendAPIPrefix +
						'/organization/' +
						parameters?.organization_id +
						'/spaces',
					object,
					headers()
				);
		}
	},
	// space/{space_id}
	space(
		type: string,
		object?: SpaceType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix + '/space/' + parameters?.space_id,
					headers()
				);
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/space/' + parameters?.space_id,
					object,
					headers()
				);
			case 'delete':
				return axios.delete(
					BackendAPIPrefix + '/space/' + parameters?.space_id,
					headers()
				);
		}
	},

	/**
	 * Subscriptions
	 */
	// //organization/{organization_id}/subscription
	organizationSubscription(
		type: string,
		object?: SubscriptionType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'put':
				return axios.put(
					BackendAPIPrefix +
						'/organization/' +
						parameters?.organization_id +
						'/subscription',
					object,
					headers()
				);
			case 'delete':
				return axios.delete(
					BackendAPIPrefix +
						'/organization/' +
						parameters?.organization_id +
						'/subscription',
					headers()
				);
		}
	},

	/**
	 * Views
	 */
	// space/{space_id}/views (get views)
	spaceViews(
		type: string,
		object?: ViewType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix + '/space/' + parameters?.space_id + '/views',
					headers()
				);
			case 'post':
				return axios.post(
					BackendAPIPrefix + '/space/' + parameters?.space_id + '/views',
					object,
					headers()
				);
		}
	},
	// view/{view_id}
	view(
		type: string,
		object?: ViewType | null,
		parameters?: APIParametersType | null
	) {
		switch (type) {
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/view/' + parameters?.view_id,
					object,
					headers()
				);
			case 'delete':
				return axios.delete(
					BackendAPIPrefix + '/view/' + parameters?.view_id,
					headers()
				);
		}
	},
	// space/{space_id}/view/{view_id}/charts
	spaceViewCharts(type: string, object?: null, parameters?: APIParametersType) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/space/' +
						parameters?.space_id +
						'/view/' +
						parameters?.view_id +
						'/charts?' +
						parameters?.query,
					headers()
				);
		}
	},
	/**
	 * Contents
	 */
	// space/{space_id}/contents (get contents)
	spaceContents(
		type: string,
		object?: ContentType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/space/' +
						parameters?.space_id +
						'/contents?' +
						parameters?.query,
					headers()
				);
			case 'post':
				return axios.post(
					BackendAPIPrefix + '/space/' + parameters?.space_id + '/contents',
					object,
					headers()
				);
		}
	},
	// space/{space_id}/contents/export (get contents)
	spaceContentsExport(
		type: string,
		object?: ContentType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/space/' +
						parameters?.space_id +
						'/contents?' +
						parameters?.query,
					headers()
				);
		}
	},
	// space/{space_id}/contents/batch (add multiple contents)
	spaceContentsBatch(
		type: string,
		object?: Array<ContentType> | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'post':
				return axios.post(
					BackendAPIPrefix +
						'/space/' +
						parameters?.space_id +
						'/contents/batch',
					object,
					headers()
				);
		}
	},
	// content/{content_id}
	content(
		type: string,
		object?: ContentType | null,
		parameters?: APIParametersType | null
	) {
		switch (type) {
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/content/' + parameters?.content_id,
					object,
					headers()
				);
			case 'delete':
				return axios.delete(
					BackendAPIPrefix + '/content/' + parameters?.content_id,
					headers()
				);
		}
	},

	/**
	 * Contents
	 */
	// space/{space_id}/posts (get posts)
	spacePosts(
		type: string,
		object?: PostType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/space/' +
						parameters?.space_id +
						'/posts?' +
						parameters?.query,
					headers()
				);
		}
	},
	// space/{space_id}/posts/export (get posts)
	spacePostsExport(
		type: string,
		object?: PostType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/space/' +
						parameters?.space_id +
						'/posts?' +
						parameters?.query,
					headers()
				);
		}
	},

	// post/{post_id}
	post(
		type: string,
		object?: PostType | null,
		parameters?: APIParametersType | null
	) {
		switch (type) {
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/post/' + parameters?.post_id,
					object,
					headers()
				);
			case 'delete':
				return axios.delete(
					BackendAPIPrefix + '/post/' + parameters?.post_id,
					headers()
				);
		}
	},

	/**
	 * Medias
	 */
	// space/{space_id}/medias
	spaceMedias(
		type: string,
		object?: MediaType | null,
		parameters?: APIParametersType
	) {
		const query = parameters?.query ? '?' + parameters?.query : '';
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/space/' +
						parameters?.space_id +
						'/medias' +
						query,
					headers()
				);
			case 'post':
				return axios.post(
					BackendAPIPrefix + '/space/' + parameters?.space_id + '/medias',
					object,
					headers()
				);
		}
	},
	// media/{media_id}
	media(
		type: string,
		object?: MediaType | null,
		parameters?: APIParametersType | null
	) {
		switch (type) {
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/media/' + parameters?.media_id,
					object,
					headers()
				);
			case 'delete':
				return axios.delete(
					BackendAPIPrefix + '/media/' + parameters?.media_id,
					headers()
				);
		}
	},
	/**
	 * Roles
	 */
	// space/{space_id}/roles
	spaceRoles(
		type: string,
		object?: RoleType | null,
		parameters?: APIParametersType
	) {
		const query = parameters?.query ? '?' + parameters?.query : '';
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/space/' +
						parameters?.space_id +
						'/roles' +
						query,
					headers()
				);
			case 'post':
				return axios.post(
					BackendAPIPrefix + '/space/' + parameters?.space_id + '/roles',
					object,
					headers()
				);
		}
	},
	// role/{role_id}
	role(
		type: string,
		object?: RoleType | null,
		parameters?: APIParametersType | null
	) {
		switch (type) {
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/role/' + parameters?.role_id,
					object,
					headers()
				);
			case 'delete':
				return axios.delete(
					BackendAPIPrefix + '/role/' + parameters?.role_id,
					headers()
				);
		}
	},
	/**
	 * Observations
	 */
	// space/{space_id}/observations
	spaceObservations(
		type: string,
		object?: ObservationType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/space/' +
						parameters?.space_id +
						'/observations?' +
						parameters?.query,
					headers()
				);
			case 'post':
				return axios.post(
					BackendAPIPrefix + '/space/' + parameters?.space_id + '/observations',
					object,
					headers()
				);
		}
	},
	/**
	 * Filters
	 */
	// space/{space_id}/filters?startdate={startdate}&endadate={enddate}&brands_id={brand_id,brand_id,brand_id} (get keywords filters)
	spaceFilters(type: string, object?: null, parameters?: APIParametersType) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/space/' +
						parameters?.space_id +
						'/filters?' +
						parameters?.query,
					headers()
				);
		}
	},
	/**
	 * Brands
	 */
	// space/{space_id}/brands (get brands)
	spaceBrands(
		type: string,
		object?: BrandType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix + '/space/' + parameters?.space_id + '/brands',
					headers()
				);
			case 'post':
				return axios.post(
					BackendAPIPrefix + '/space/' + parameters?.space_id + '/brands',
					object,
					headers()
				);
		}
	},
	// brand/{brand_id}
	brand(
		type: string,
		object?: BrandType | null,
		parameters?: APIParametersType | null
	) {
		switch (type) {
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/brand/' + parameters?.brand_id,
					object,
					headers()
				);
			case 'delete':
				return axios.delete(
					BackendAPIPrefix + '/brand/' + parameters?.brand_id,
					headers()
				);
		}
	},
	/**
	 * Keywords
	 */
	// space/{space_id}/keywords?query={text} (get keywords)
	spaceKeywords(
		type: string,
		object?: SpaceType | KeywordType | null,
		parameters?: APIParametersType
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix +
						'/space/' +
						parameters?.space_id +
						'/keywords?query=' +
						parameters?.query,
					headers()
				);
			case 'post':
				return axios.post(
					BackendAPIPrefix + '/space/' + parameters?.space_id + '/keywords',
					object,
					headers()
				);
		}
	},
	// keyword/{keyword_id}
	keyword(
		type: string,
		object?: KeywordType | null,
		parameters?: APIParametersType | null
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix + '/keyword/' + parameters?.keyword_id,
					headers()
				);
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/keyword/' + parameters?.keyword_id,
					object,
					headers()
				);
			case 'delete':
				return axios.delete(
					BackendAPIPrefix + '/keyword/' + parameters?.keyword_id,
					headers()
				);
		}
	},
	// keyword/message/suggestions
	keywordSuggestion(
		type: string,
		brands: string[],
		language: string,
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix + '/keyword/message/suggestions?brands=' + (brands.length === 1 ? brands[0] : brands.join(',')) + "&lang=" + language,
					headers()
				);
		}
	},
	/**
	 * Query
	 */
	// brand/{brand_id}/queries
	brandQueries(
		type: string,
		object?: QueryType | null,
		parameters?: APIParametersType | null
	) {
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix + '/brand/' + parameters?.brand_id + '/queries',
					headers()
				);
			case 'post':
				return axios.post(
					BackendAPIPrefix + '/brand/' + parameters?.brand_id + '/queries',
					object,
					headers()
				);
		}
	},
	// query/{query_id}
	query(
		type: string,
		object?: QueryType | null,
		parameters?: APIParametersType | null
	) {
		switch (type) {
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/query/' + parameters?.query_id,
					object,
					headers()
				);
		}
	},
	/**
	 * Notification Management
	 */
	// notifications/management/{space_id}
	notification(
		type: string,
		object?: SpaceNotificationConfiguration,
		parameters?: APIParametersType | null
	) {
		console.log(object)
		switch (type) {
			case 'get':
				return axios.get(
					BackendAPIPrefix + '/notifications/management/space/' + parameters?.space_id,
					headers()
				);
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/notifications/management/space/' + parameters?.space_id,
					object,
					headers()
				);
		}
	},
	sendNotification(
		type: string,
		object?: SpaceNotificationConfiguration,
		parameters?: APIParametersType | null
	) {
		switch (type) {
			case 'put':
				return axios.put(
					BackendAPIPrefix + '/notifications/send/space/' + parameters?.space_id,
					object,
					headers()
				);
			case 'get':
				return axios.get(
					BackendAPIPrefix + '/notifications/send/space/' + parameters?.space_id + "?category=" + parameters?.query,
					headers()
				);
		}
	}
};

// create and export config service
export const APIService: Plugin = {
	// required method to set config service as plugin
	install: (app: App) => {
		app.config.globalProperties.$API = API;
	},
};

// set $text as global variable
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$API: any;
	}
}
