
import { defineComponent } from 'vue';
import ModalBillingComponent from '@/components/modals/ModalBilling.component.vue';

export default defineComponent({
	components: {
		ModalBillingComponent,
	},
	computed: {
		organizationOwnerMail(): string {
			if (this.$store.state.organization.owner && this.$store.state.organization.owner.email)
				return this.$store.state.organization.owner.email
			return ''
		},
		isOwnOrganization(): boolean {
			if (
				this.organizationOwnerMail ||
				this.organizationOwnerMail === this.$store.state.profile.email
			)
				return true
			return false
		},
		premiumDemoLink(): string {
			return process.env.VUE_APP_PREMIUM_DESCRIPTION_LINK
		}
	},
	methods: {
		goBillingPage() {
			this.$router.push({
				name: this.$config('RouteConfig', 'PlansRouteName'),
				params: {
					organization_id: this.$store.state.organization._id,
					space_id: this.$store.state.space._id,
				}
			});
		},
	},
});
