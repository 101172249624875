
import { defineComponent } from 'vue';
import EmbededErrorComponent from '@/components/EmbededError.component.vue';

export default defineComponent({
	/**
	 * business logic only
	 */
	components: {
		EmbededErrorComponent,
	},
});
