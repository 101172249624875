import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';

export async function RouteChoiceDependsAuthenticationMiddleware(
	to: any,
	next: any
) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Choising route (depends authentication) ',
			'color: #FF0000;font-weight:bold;'
		);
		// route public (no authentication needed)
		if (
			Array.prototype.includes.call(
				getConfig('RouteConfig', 'NotLoggedRouteNames'),
				to.name
			)
		) {
			// route public + user logged
			if (StoreService.state.logged === true) {
				// route public + user logged + route is an authentication route
				if (
					getConfig('RouteConfig', 'AuthenticationLayoutRouteNames').includes(
						to.name
					)
				) {
					// go to overview route
					RouterService.push({
						name: getConfig('RouteConfig', 'HomeRouteName'),
					}).then(() => {
						next(false);
					});
				} else {
					resolve(true);
				}
			}
			// route public + user not logged
			else {
				resolve(true);
			}
		}

		// route private (authentication needed)
		else {
			// route private + user logged
			if (StoreService.state.logged === true) {
				resolve(true);
			}
			// route private + user not logged
			else {
				// go to route login
				RouterService.push({
					name: getConfig('RouteConfig', 'WelcomeRouteName'),
				}).then(() => {
					next(false);
				});
			}
		}
	});
}
