import { getConfig } from '@/services/Config.service';
import { StoreService } from '@/services/Store.service';
import {getCookie, setCookie} from "typescript-cookie";

export async function AuthenticationMiddleware() {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Authentication... ',
			'color: #FF0000;font-weight:bold;'
		);
		// user_token exists
		const jwtLocalStorage = localStorage.getItem(
			getConfig('AppConfig', 'UserTokenLocalStorageKey') as string
		)
		const jwtCookie = getCookie(getConfig('AppConfig', 'UserTokenLocalStorageKey') as string)
		if (jwtCookie) {
			// user_token exists + user not logged
			if (StoreService.state.logged === false) {
				// log user
				StoreService.dispatch('set', {
					stateProperty: 'user_token',
					value: jwtCookie,
				}).then(() => {
					StoreService.dispatch('login').then(() => {
						resolve(true);
					});
				});
			}
			// user_token exists + user logged
			else {
				resolve(true);
			}
		} else if (jwtLocalStorage) {
			setCookie(
				getConfig('AppConfig', 'UserTokenLocalStorageKey') as string,
				jwtLocalStorage,
				{
					domain: getConfig('AppConfig', 'CookieDomain') as string,
					expires: 365
				});
			if (StoreService.state.logged === false) {
				// log user
				StoreService.dispatch('set', {
					stateProperty: 'user_token',
					value: jwtLocalStorage,
				}).then(() => {
					StoreService.dispatch('login').then(() => {
						resolve(true);
					});
				});
			}
			// user_token exists + user logged
			else {
				resolve(true);
			}
		}
		// user_token doesn't exists
		else {
			// user_token doesn't exists + user not logged
			if (StoreService.state.logged === false) {
				resolve(true);
			}
			// user_token doesn't exists + user logged
			else {
				// logout user
				StoreService.dispatch('logout').then(() => {
					resolve(true);
				});
			}
		}
	});
}
