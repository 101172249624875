// src/services/GetSpaces.service.ts

import { StoreService } from '@/services/Store.service';
import { API } from '@/services/API.service';
import { getAlerts } from '@/services/Alerts.service';

export default async function (organization_id?: string) {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Service] Getting spaces... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'spacesLoaded',
			value: false,
		}).then(() => {
			if (organization_id === undefined) {
				organization_id = StoreService.state.organization!._id;
			}
			API.organizationSpaces('get', null, {
				organization_id: organization_id,
			})?.then((response: any) => {
				console.log(
					'%c [Service] Set spaces in store... ',
					'color: #0000FF;font-weight:bold;'
				);
				StoreService.dispatch('set', {
					stateProperty: 'spaces',
					value: response.data,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'spacesLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}).catch((error: any) => {
				getAlerts.pushFromError(error)
				reject(error)
			});
		});
	});
}
