// src/services/GetKeywords.service.ts

import { StoreService } from '@/services/Store.service';
import { API } from '@/services/API.service';
import { getAlerts } from '@/services/Alerts.service';
import moment from 'moment';

export default async function () {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Service] Getting keywords... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'keywordsLoaded',
			value: false,
		}).then(() => {
			const query =
				'startdate=' +
				moment(new Date(StoreService.state.filters!.startdate as string))
					.utc()
					.startOf('day')
					.toISOString() +
				'&enddate=' +
				moment(new Date(StoreService.state.filters!.enddate as string))
					.utc()
					.endOf('day')
					.toISOString() +
				'&brand_ids=' +
				StoreService.state.filters!.brand_ids?.join(',');
			API.spaceFilters('get', null, {
				space_id: StoreService.state.space!._id,
				query: query,
			})?.then((response: any) => {
				console.log(
					'%c [Service] Set keywords in store... ',
					'color: #0000FF;font-weight:bold;'
				);
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'keywords',
					value: response.data,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'keywordsLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}).catch((error: any) => {
				getAlerts.pushFromError(error)
				reject(error)
			});
		});
	});
}
