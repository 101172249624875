import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "fluid-layout",
  class: "h-full"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  id: "content",
  class: "p-6 d-flex h-full mt-n6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductNavBarHorizontalComponent = _resolveComponent("ProductNavBarHorizontalComponent")!
  const _component_ProductNavBarVerticalComponent = _resolveComponent("ProductNavBarVerticalComponent")!
  const _component_NoBrandFoundVue = _resolveComponent("NoBrandFoundVue")!
  const _component_NoMediaFoundVue = _resolveComponent("NoMediaFoundVue")!
  const _component_AlertsGroup = _resolveComponent("AlertsGroup")!
  const _component_UnsavedChangedModal = _resolveComponent("UnsavedChangedModal")!
  const _component_RequirePaymentModal = _resolveComponent("RequirePaymentModal")!
  const _component_ProgressBarComponent = _resolveComponent("ProgressBarComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ProductNavBarHorizontalComponent, {
        class: _normalizeClass(
				Array.prototype.includes.call(
					_ctx.$config('RouteConfig', 'LoggedSettingsRouteNames'),
					_ctx.$route.name
				)
					? 'bg-white'
					: ''
			)
      }, null, 8, ["class"]),
      _createElementVNode("div", {
        class: "d-flex h-full w-full",
        id: 
				Array.prototype.includes.call(
					_ctx.$config('RouteConfig', 'LoggedSettingsRouteNames'),
					_ctx.$route.name
				)
					? 'wrapper-main-settings'
					: ''
			
      }, [
        _createVNode(_component_ProductNavBarVerticalComponent, {
          class: _normalizeClass(
					Array.prototype.includes.call(
						_ctx.$config('RouteConfig', 'LoggedSettingsRouteNames'),
						_ctx.$route.name
					)
						? 'navbar-vertical-settings offset-navbar-horizontal'
						: ''
				)
        }, null, 8, ["class"]),
        _createElementVNode("div", {
          id: "main-wrapper",
          class: _normalizeClass(["w-full d-flex", 
					_ctx.$route.name &&
					Array.prototype.includes.call(
						_ctx.$config('RouteConfig', 'LoggedSettingsRouteNames'),
						_ctx.$route.name
					)
						? 'offset-navbar-horizontal'
						: ''
				])
        }, [
          _createElementVNode("div", {
            id: "main",
            class: _normalizeClass([
						_ctx.$route.name &&
						Array.prototype.includes.call(
							_ctx.$config('RouteConfig', 'LoggedSettingsRouteNames'),
							_ctx.$route.name
						)
							? 'bg-white'
							: 'bg-secondary'
					, "col h-full"])
          }, [
            _createElementVNode("div", _hoisted_3, [
              (
								_ctx.$store.state.brands.length === 0 &&
								_ctx.$config('RouteConfig', 'BrandsRequiredRouteNames').includes(
									_ctx.$route.name
								)
							)
                ? (_openBlock(), _createBlock(_component_NoBrandFoundVue, { key: 0 }))
                : _createCommentVNode("", true),
              (
								_ctx.$store.state.medias.length === 0 &&
								_ctx.$config('RouteConfig', 'MediasRequiredRouteNames').includes(
									_ctx.$route.name
								)
							)
                ? (_openBlock(), _createBlock(_component_NoMediaFoundVue, { key: 1 }))
                : _createCommentVNode("", true)
            ])
          ], 2)
        ], 2)
      ], 8, _hoisted_2)
    ]),
    _createVNode(_component_AlertsGroup),
    _createVNode(_component_UnsavedChangedModal),
    _createVNode(_component_RequirePaymentModal),
    _createVNode(_component_ProgressBarComponent)
  ], 64))
}