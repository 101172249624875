import { getConfig } from '@/services/Config.service';
import { getPermission } from '@/services/Permission.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';

export async function RedirectSubscribeMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] subsribe redirection ... ',
			'color: #FF0000;font-weight:bold;'
		);

		if(
			StoreService.state.space
			&& StoreService.state.organization
			&& !StoreService.state.organization.demo
			&& StoreService.state.organization.freemium &&
			to.name !== getConfig('RouteConfig', 'OrganizationUpdateRouteName') &&
			to.name !== getConfig('RouteConfig', 'OrganizationRolesRouteName') &&
			to.name !== getConfig('RouteConfig', 'SpaceUpdateRouteName') &&
			to.name !== getConfig('RouteConfig', 'SpaceRolesRouteName') &&
			to.name !== getConfig('RouteConfig', 'RoleAddRouteName') &&
			to.name !== getConfig('RouteConfig', 'RoleUpdateRouteName') &&
			to.name !== getConfig('RouteConfig', 'OnboardingRouteName') &&
			to.name !== getConfig('RouteConfig', 'SubscribeRouteName') &&
			to.name !== getConfig('RouteConfig', 'CheckoutRouteName')
			) {
			// Redirect on subscribe route
			RouterService.push({
				name: getConfig('RouteConfig', 'SubscribeRouteName'),
				params: {
					organization_id: to.params.organization_id,
					space_id: StoreService.state.space._id as string
				},
			}).then(() => {
				next(false);
			});
		} else if(StoreService.state.space
			&& StoreService.state.organization
			&& !StoreService.state.organization.demo
			&& !StoreService.state.organization.freemium && to.name === getConfig('RouteConfig', 'SubscribeRouteName')) {
			RouterService.push({
				name: getConfig('RouteConfig', 'SpaceRouteName'),
				params: {
					organization_id: to.params.organization_id,
					space_id: StoreService.state.space._id as string
				},
			}).then(() => {
				next(false);
			});
		}
		else {
			resolve(true)
		}
	});
}
