
import { defineComponent } from 'vue';

export default defineComponent({
	data() {
		return {
			menu: [
				{
					name: 'User',
					items: [
						{
							name: 'User_Email',
							link:
								'#' + this.$toolbox.slugify(this.$text('labels', 'User_Email')),
						},
						{
							name: 'User_Role',
							link:
								'#' + this.$toolbox.slugify(this.$text('labels', 'User_Role')),
						},
					],
				},
			],
		};
	},
	// watch: {
	// 	'$store.state.progressBarFinished': function (value) {
	// 		if (value === true) {
	// 			document
	// 				.getElementById(this.$route.hash.replace('#', '') + '-input')!
	// 				.focus();
	// 		}
	// 	},
	// },
	created() {
		if (this.$route.hash === '') {
			this.$router.push({
				name: this.$route.name?.toString(),
				hash: this.menu[0].items[0].link,
			});
		}
	},
});
