
import { defineComponent } from 'vue';
import { NavBarMixin } from '@/mixins/NavBar.mixin';
import LogoComponent from '@/components/Logo.component.vue';
export default defineComponent({
	/**
	 * business logic only
	 */
	mixins: [NavBarMixin],
	components: {
		LogoComponent,
	},
	methods: {
		handleSignUp() {
			document.querySelector('#public-layout')?.classList.add('animate');
		},
	},
});
