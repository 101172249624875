// src/services/SetBrandInStore.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from '@/services/Router.service';
import { BrandType } from '@/types/Brand.type';

export default async function (brand?: BrandType | null) {
	return new Promise((resolve) => {
		console.log(
			'%c [Service] Set brand in store... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'brandLoaded',
			value: false,
		}).then(() => {
			/* set brand passed by argument */
			if (brand !== undefined) {
				StoreService.dispatch('set', {
					stateProperty: 'brand',
					value: brand,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'brandLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			/* set brand passed by url */
			else if (
				RouterService.currentRoute.value.params.brand_id &&
				StoreService.state.brands!.find(
					(brand: BrandType) =>
						brand._id === RouterService.currentRoute.value.params.brand_id
				) !== undefined
			) {
				StoreService.dispatch('set', {
					stateProperty: 'brand',
					value: StoreService.state.brands!.find(
						(brand: BrandType) =>
							brand._id === RouterService.currentRoute.value.params.brand_id
					),
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'brandLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			/* set default brand */
			else {
				StoreService.dispatch('set', {
					stateProperty: 'brand',
					value: StoreService.state.brands![0],
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'brandLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
