import { LocationQueryRaw } from 'vue-router';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';
import { getToolbox } from '@/services/Toolbox.service';

// src/services/UpdateFiltersQuery.service.ts
export default async function (query: LocationQueryRaw) {

	return new Promise((resolve) => {
		console.log(
			'%c [Service] Updating URL... ',
			'color: #0000FF;font-weight:bold;'
		);

		StoreService.dispatch('set', {
			stateProperty: 'checkURLFilters',
			value: false,
		}).then(() => {
			StoreService.dispatch('set', {
				stateProperty: 'filtersQuery',
				value: query,
			}).then(() => {
				if (
					getToolbox.objectsAreDifferent(
						query, RouterService.currentRoute.value.query
					)
				) {
					RouterService.replace({
						query: query
					}).then(() => {
						resolve(true)
					});
				}
				else {
					StoreService.dispatch('set', {
						stateProperty: 'filtersAlreadyLoaded',
						value: false,
					})
				}
			});
		});
	});
}
