// src/configs/Routes.config.ts

// import Routes type
import { getConfig } from '@/services/Config.service';
import { getText } from '@/services/Text.service';
import type { RouteType } from '@/types/Route.type';

// list of routes
export const RouterConfig: Array<RouteType> = [
	/** error */
	{
		path: '/error/500',
		name: getConfig('RouteConfig', 'Error500RouteName'),
		component: () => import('@/vues/errors/500.vue'),
	},
	{
		path: '/error/502',
		name: getConfig('RouteConfig', 'Error502RouteName'),
		component: () => import('@/vues/errors/502.vue'),
	},
	{
		path: '/error/403',
		name: getConfig('RouteConfig', 'Error403RouteName'),
		component: () => import('@/vues/errors/403.vue'),
	},
	{
		path: '/error/404',
		name: getConfig('RouteConfig', 'Error404RouteName'),
		component: () => import('@/vues/errors/404.vue'),
	},
	/** authentication */
	{
		path: '/welcome',
		name: getConfig('RouteConfig', 'WelcomeRouteName'),
		component: () => import('@/vues/authentication/Welcome.vue'),
	},
	{
		path: '/email',
		name: getConfig('RouteConfig', 'EmailRouteName'),
		component: () => import('@/vues/authentication/Email.vue'),
	},
	{
		path: '/login',
		name: getConfig('RouteConfig', 'LoginRouteName'),
		component: () => import('@/vues/authentication/Login.vue'),
	},
	{
		path: '/login/google/callback',
		name: getConfig('RouteConfig', 'LoginGoogleCallbackRouteName'),
		component: () => import('@/vues/authentication/Google.vue'),
	},
	{
		path: '/login/password',
		name: getConfig('RouteConfig', 'LoginPasswordRouteName'),
		component: () => import('@/vues/authentication/Login.vue'),
	},
	{
		path: '/register',
		name: getConfig('RouteConfig', 'RegisterRouteName'),
		component: () => import('@/vues/authentication/Register.vue'),
	},
	{
		path: '/register/google/callback',
		name: getConfig('RouteConfig', 'RegisterGoogleCallbackRouteName'),
		component: () => import('@/vues/authentication/Google.vue'),
	},
	{
		path: '/register/form',
		name: getConfig('RouteConfig', 'RegisterFormRouteName'),
		component: () => import('@/vues/authentication/Register.vue'),
	},
	{
		path: '/register/success',
		name: getConfig('RouteConfig', 'RegisterSuccessRouteName'),
		component: () => import('@/vues/authentication/Register.vue'),
	},
	{
		path: '/register/verify',
		name: getConfig('RouteConfig', 'RegisterVerifyRouteName'),
		component: () => import('@/vues/authentication/Verify.vue'),
	},
	{
		path: '/password',
		name: getConfig('RouteConfig', 'PasswordRouteName'),
		redirect: '/password/reset',
	},
	{
		path: '/password/reset',
		name: getConfig('RouteConfig', 'PasswordResetRouteName'),
		component: () => import('@/vues/authentication/Reset.vue'),
	},
	{
		path: '/password/reset/form',
		name: getConfig('RouteConfig', 'PasswordResetFormRouteName'),
		component: () => import('@/vues/authentication/Reset.vue'),
	},
	{
		path: '/password/reset/success',
		name: getConfig('RouteConfig', 'PasswordResetSuccessRouteName'),
		component: () => import('@/vues/authentication/Reset.vue'),
	},
	{
		path: '/password/reset/error',
		name: getConfig('RouteConfig', 'PasswordResetErrorRouteName'),
		component: () => import('@/vues/authentication/Reset.vue'),
	},
	{
		path: '/password/forgot',
		name: getConfig('RouteConfig', 'PasswordForgotRouteName'),
		component: () => import('@/vues/authentication/Forgot.vue'),
	},
	{
		path: '/password/forgot/success',
		name: getConfig('RouteConfig', 'PasswordForgotSuccessRouteName'),
		component: () => import('@/vues/authentication/Forgot.vue'),
	},
	{
		path: '/password/forgot/error',
		name: getConfig('RouteConfig', 'PasswordForgotErrorRouteName'),
		component: () => import('@/vues/authentication/Forgot.vue'),
	},
	/** public */
	{
		path: '/privacy-policy',
		name: getConfig('RouteConfig', 'PrivacyPolicyRouteName'),
		meta: { friendlyName: getText('routes_name', 'Route_Privacy_Policy_Name') },
		component: () => import('@/vues/PrivacyPolicy.vue'),
	},
	/** product */
	{
		path: '/',
		name: getConfig('RouteConfig', 'HomeRouteName'),
		meta: { friendlyName: getText('routes_name', 'Route_Home_Friendly_Name') },
		component: () => import('@/vues/Home.vue'),
	},
	/** user */
	{
		path: '/informations',
		name: getConfig('RouteConfig', 'InformationsRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Informations_Friendly_Name'),
		},
		component: () => import('@/vues/authentication/Informations.vue'),
	},
	{
		path: '/profile/update',
		name: getConfig('RouteConfig', 'ProfileUpdateRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Profile_Friendly_Name'),
		},
		component: () => import('@/vues/settings/Profile.vue'),
	},
	{
		path: '/analytics/google/callback',
		name: getConfig('RouteConfig', 'AnalyticsGoogleCallbackRouteName'),
		component: () => import('@/vues/authentication/Google.vue'),
	},
	/** organization */
	{
		path: '/organization/:organization_id?',
		name: getConfig('RouteConfig', 'OrganizationRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Organization_Friendly_Name'),
		},
		component: () => import('@/vues/settings/Organization.vue'),
	},
	{
		path: '/organization/create',
		name: getConfig('RouteConfig', 'OrganizationCreateRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Organization_Create_Friendly_Name'
			),
		},
		component: () => import('@/vues/settings/Organization.vue'),
	},
	{
		path: '/organization/:organization_id/update',
		name: getConfig('RouteConfig', 'OrganizationUpdateRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Organization_Update_Friendly_Name'
			),
		},
		component: () => import('@/vues/settings/Organization.vue'),
	},

	/** space */
	{
		path: '/organization/:organization_id/space/:space_id?',
		name: getConfig('RouteConfig', 'SpaceRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Space_Friendly_Name'),
		},

		component: () => import('@/vues/settings/Space.vue'),
	},
	{
		path: '/organization/:organization_id/space/create',
		name: getConfig('RouteConfig', 'SpaceCreateRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Space_Create_Friendly_Name'),
		},
		component: () => import('@/vues/settings/Space.vue'),
	},
	{
		path: '/organization/:organization_id/space/:space_id/update',
		name: getConfig('RouteConfig', 'SpaceUpdateRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Space_Update_Friendly_Name'),
		},

		component: () => import('@/vues/settings/Space.vue'),
	},
	/** onboarding */
	{
		path: '/organization/:organization_id/space/:space_id/onboarding',
		name: getConfig('RouteConfig', 'OnboardingRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Onboarding_Friendly_Name'),
		},
		component: () => import('@/vues/Onboarding.vue'),
	},
	/** brand */
	{
		path: '/organization/:organization_id/space/:space_id/brand/:brand_id?',
		name: getConfig('RouteConfig', 'BrandRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Parameters_Friendly_Name'),
		},
		component: () => import('@/vues/settings/Brand.vue'),
	},
	{
		path: '/organization/:organization_id/space/:space_id/brand/create',
		name: getConfig('RouteConfig', 'BrandCreateRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Parameters_Friendly_Name'),
		},
		component: () => import('@/vues/settings/Brand.vue'),
	},
	{
		path: '/organization/:organization_id/space/:space_id/brand/:brand_id/update',
		name: getConfig('RouteConfig', 'BrandUpdateRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Parameters_Friendly_Name'),
		},
		component: () => import('@/vues/settings/Brand.vue'),
	},
	/** media */
	{
		path: '/organization/:organization_id/space/:space_id/medias',
		name: getConfig('RouteConfig', 'MediasRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Space_Medias_Friendly_Name'),
		},
		component: () => import('@/vues/contents/Medias.vue'),
	},
	{
		path: '/organization/:organization_id/space/:space_id/media/add',
		name: getConfig('RouteConfig', 'MediaAddRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Space_Add_Media_Friendly_Name'
			),
		},
		component: () => import('@/vues/settings/Media.vue'),
	},
	{
		path: '/organization/:organization_id/space/:space_id/media/:media_id/update',
		name: getConfig('RouteConfig', 'MediaUpdateRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Space_Update_Media_Friendly_Name'
			),
		},
		component: () => import('@/vues/settings/Media.vue'),
	},
	/** view */
	{
		path: '/organization/:organization_id/space/:space_id/view/:view_id',
		name: getConfig('RouteConfig', 'ViewRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Space_View_Friendly_Name'),
		},
		component: () => import('@/vues/contents/View.vue'),
	},
	/* contents */
	{
		path: '/organization/:organization_id/space/:space_id/contents',
		name: getConfig('RouteConfig', 'ContentsRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Contents_Friendly_Name'
			),
		},
		component: () => import('@/vues/contents/Contents.vue'),
	},
	{
		path: '/organization/:organization_id/space/:space_id/contents/add',
		name: getConfig('RouteConfig', 'ContentAddRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Space_Add_Content_Friendly_Name'
			),
		},
		component: () => import('@/vues/settings/Contents.vue'),
	},
	/** contents preview */
	{
		path: '/organization/:organization_id/space/:space_id/contents/preview',
		name: getConfig('RouteConfig', 'ContentsPreviewRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Space_Contents_Preview_Friendly_Name'),
		},
		component: () => import('@/vues/contents/Contents.vue'),
	},
	/* posts */
	{
		path: '/organization/:organization_id/space/:space_id/posts',
		name: getConfig('RouteConfig', 'PostsRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Space_Posts_Friendly_Name'
			),
		},
		component: () => import('@/vues/contents/Posts.vue'),
	},
	/** posts preview */
	{
		path: '/organization/:organization_id/space/:space_id/posts/preview',
		name: getConfig('RouteConfig', 'PostsPreviewRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Space_Posts_Preview_Friendly_Name'),
		},
		component: () => import('@/vues/contents/Posts.vue'),
	},
	/* insight */
	{
		path: '/organization/:organization_id/space/:space_id/insights',
		name: getConfig('RouteConfig', 'InsightsRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Space_Insight_Friendly_Name'),
		},
		component: () => import('@/vues/contents/Insights.vue'),
	},
	/* payment-methods */
	{
		path: '/organization/:organization_id/space/:space_id/payment-method/add',
		name: getConfig('RouteConfig', 'PaymentMethodAddRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Payment_Method_Add_Friendly_Name'
			),
		},
		component: () => import('@/vues/settings/PaymentMethod.vue'),
	},
	{
		path: '/organization/:organization_id/space/:space_id/payment-method/update',
		name: getConfig('RouteConfig', 'PaymentMethodUpdateRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Payment_Method_Update_Friendly_Name'
			),
		},
		component: () => import('@/vues/settings/PaymentMethod.vue'),
	},
	/* plans */
	{
		path: '/organization/:organization_id/space/:space_id/plans',
		name: getConfig('RouteConfig', 'PlansRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Plans_Friendly_Name'),
		},
		component: () => import('@/vues/contents/Plans.vue'),
	},
	{
		path: '/organization/:organization_id/space/:space_id/subscribe',
		name: getConfig('RouteConfig', 'SubscribeRouteName'),
		meta: {
			friendlyName: "Subscribe",
		},
		component: () => import('@/vues/contents/Plans.vue'),
	},
	/* billing */
	{
		path: '/organization/:organization_id/billing',
		name: getConfig('RouteConfig', 'OrganizationBillingRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Billing_Friendly_Name'),
		},
		component: () => import('@/vues/contents/Billing.vue'),
	},

	{
		path: '/organization/:organization_id/space/:space_id/billing',
		name: getConfig('RouteConfig', 'SpaceBillingRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Billing_Friendly_Name'),
		},
		component: () => import('@/vues/contents/Billing.vue'),
	},
	/* checkout */
	{
		path: '/organization/:organization_id/space/:space_id/checkout',
		name: getConfig('RouteConfig', 'CheckoutRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Checkout_Friendly_Name'),
		},
		component: () => import('@/vues/settings/Checkout.vue'),
	},
	/** roles */
	{
		path: '/organization/:organization_id/roles',
		name: getConfig('RouteConfig', 'OrganizationRolesRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Organization_Roles_Friendly_Name'
			),
		},
		component: () => import('@/vues/contents/Users.vue'),
	},
	{
		path: '/organization/:organization_id/space/:space_id/roles',
		name: getConfig('RouteConfig', 'SpaceRolesRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Space_Roles_Friendly_Name'),
		},
		component: () => import('@/vues/contents/Users.vue'),
	},
	{
		path: '/organization/:organization_id/space/:space_id/role/add',
		name: getConfig('RouteConfig', 'RoleAddRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Space_Add_Role_Friendly_Name'
			),
		},
		component: () => import('@/vues/settings/User.vue'),
	},
	{
		path: '/organization/:organization_id/space/:space_id/role/:role_id/update',
		name: getConfig('RouteConfig', 'RoleUpdateRouteName'),
		meta: {
			friendlyName: getText(
				'routes_name',
				'Route_Space_Update_Role_Friendly_Name'
			),
		},
		component: () => import('@/vues/settings/User.vue'),
	},
	/* Notifications */
	{
		path: '/organization/:organization_id/space/:space_id/notifications/update',
		name: getConfig('RouteConfig', 'NotificationsUpdateRouteName'),
		meta: {
			friendlyName: getText('routes_name', 'Route_Notifications_Friendly_Name'),
		},
		component: () => import('@/vues/settings/Notifications.vue'),
	},
];
