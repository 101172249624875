// src/services/SetSocialSourceInStoreFilters.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from './Router.service';

export default async function (social_source?: string) {
	const updateQuery = function () {
		return new Promise((resolve) => {
			StoreService.dispatch('set', {
				stateProperty: 'socialSourceFiltersReady',
				value: true,
			}).then(() => {
				resolve(true);
			});
		});
	};
	return new Promise((resolve) => {
		StoreService.dispatch('set', {
			stateProperty: 'socialSourceFiltersReady',
			value: false,
		}).then(() => {
			// set with argument
			if (social_source) {
				console.log("okkkk4");
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'social_source',
					value: social_source,
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
			// value is in URL
			else if (
				StoreService.state.checkURLFilters &&
				RouterService.currentRoute.value.query.social_source !== undefined
			) {
				let socialSources: Array<string> = []
				socialSources = (
					RouterService.currentRoute.value.query.social_source as string
				).replace('%2C', ',').split(',');
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'social_source',
					value: socialSources,
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			} else {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'social_source',
					value: [],
				}).then(() => {
					updateQuery().then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
