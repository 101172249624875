

import { StoreService } from '@/services/Store.service';
import { defineComponent } from 'vue';
import { RouteParamsRaw } from 'vue-router';

export default defineComponent({
	computed: {
		currentRouteFriendlyName(): string {
			return JSON.parse(
				JSON.stringify(this.$store.state.current_route.meta.friendlyName)
			);
		},
		currentRouteParams(): RouteParamsRaw {
			if (
				this.$store.state.current_route &&
				this.$store.state.current_route.params
			) {
				return this.$store.state.current_route.params;
			} else {
				return {};
			}
		},
		lastRouteName(): string {
			if (
				this.$store.state.last_product_route &&
				this.$store.state.last_product_route.name !== this.$route.name &&
				this.$store.state.last_product_route.name
			) {
				return this.$store.state.last_product_route.name;
			} else {
				return '';
			}
		},
		lastRouteParams(): RouteParamsRaw {
			if (
				this.$store.state.last_product_route &&
				this.$store.state.last_product_route.params
			) {
				return this.$store.state.last_product_route.params;
			} else {
				return {};
			}
		},
	},
	methods: {
		handleClose() {
			this.redirect();
		},
		redirect() {
			if (this.lastRouteName !== '' && this.lastRouteParams !== {}) {
				if (
					this.$route.name == this.$config("RouteConfig", "ContentsPreviewRouteName") ||
					this.$route.name == this.$config("RouteConfig", "PostsPreviewRouteName")
				) {
					StoreService.dispatch('set', {
						stateProperty: 'filtersPreview',
						value: {},
					})
				}
				this.$router.push({
					name: this.lastRouteName,
					params: this.lastRouteParams,
				});
			}
			else {
				this.$router.push({
					name: this.$config('RouteConfig', 'HomeRouteName'),
				});
			}
		},
	},
});
