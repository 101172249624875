// main.ts
/**
 * launcher application file
 */

// import method to create app from vue package
import { createApp, App } from 'vue';

// import global modules
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';
import { ConfigService } from '@/services/Config.service';
import { BeautifyService } from '@/services/Beautify.service';
import { TextService } from '@/services/Text.service';
import { ToolboxService } from '@/services/Toolbox.service';
import { APIService } from '@/services/API.service';
import { DebugService } from '@/services/Debug.service';
import { PermissionService } from '@/services/Permission.service';
import { LimitationService } from '@/services/Limitation.service';
import VueApexCharts from 'vue3-apexcharts';
// @ts-ignore
import VueProgressBar from '@aacassandra/vue3-progressbar';
const options = {
	color: 'rgb(245, 166, 20)',
};

// import styles
import '@/assets/scss/screen.scss';

// import Index
import IndexVue from '@/vues/Index.vue';

// create application
const app: App<Element> = createApp(IndexVue);

// set global component
app.use(RouterService);
app.use(StoreService);
app.use(ConfigService);
app.use(BeautifyService);
app.use(TextService);
app.use(ToolboxService);
app.use(APIService);
app.use(PermissionService);
app.use(LimitationService);
app.use(DebugService);
app.use(VueApexCharts);
app.use(VueProgressBar, options);

// mount app
app.mount('#app');
