// src/services/SetBrandIdsInStoreFilter.service.ts

import { StoreService } from '@/services/Store.service';
import { BrandType } from '@/types/Brand.type';
import { RouterService } from './Router.service';
import SetPageInStoreFiltersService from './SetPageInStoreFilters.service';

export default async function (brand_id?: string) {
	const updateQuery = function (resetPage: boolean = false) {
		return new Promise((resolve) => {
			if (resetPage) {
				SetPageInStoreFiltersService(0)
					.then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'brandIdsFiltersReady',
							value: true,
						}).then(() => {
							resolve(true);
						});
					})
			}
			else {
				StoreService.dispatch('set', {
					stateProperty: 'brandIdsFiltersReady',
					value: true,
				}).then(() => {
					resolve(true);
				});
			}
		});
	};

	return new Promise((resolve) => {
		console.log(
			'%c [Service] Set brand ids in store... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'brandIdsFiltersReady',
			value: false,
		}).then(() => {
			if (brand_id !== undefined) {
				let brand_ids = JSON.parse(
					JSON.stringify(StoreService.state.filters?.brand_ids)
				);
				if (brand_ids.includes(brand_id)) {
					brand_ids = brand_ids.filter((id: string) => brand_id !== id);
				} else {
					brand_ids.push(brand_id);
				}
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'brand_ids',
					value: brand_ids,
				}).then(() => {
					updateQuery(true).then(() => {
						resolve(true);
					});
				});
			}
			/* keep brands filters */
			else if (StoreService.state.filters.brand_ids) {
				updateQuery(false).then(() => {
					resolve(true);
				});
			}
			/* init brands filters */
			else {
				let brand_ids = (
					JSON.parse(
						JSON.stringify(StoreService.state.brands)
					) as Array<BrandType>
				).map((brand: BrandType) => brand._id)
				if (
					StoreService.state.checkURLFilters === true &&
					RouterService.currentRoute.value.query.brand_ids
				) {
					const url_brand_ids: Array<string> = (
						RouterService.currentRoute.value.query.brand_ids as string
					).replace('%2C', ',').split(',');
					brand_ids = brand_ids.filter(id => url_brand_ids.includes(id!));
				}
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'brand_ids',
					value: brand_ids,
				}).then(() => {
					updateQuery(!StoreService.state.checkURLFilters).then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
