import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';

export async function RedirectViewMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] View redirection ... ',
			'color: #FF0000;font-weight:bold;'
		);

		if (
			StoreService.state.view &&
			(
				to.name === getConfig('RouteConfig', 'SpaceRouteName') ||
				(
					to.name === getConfig('RouteConfig', 'ViewRouteName') &&
					!to.params.view_id
				)
			)
		) {
			// Redirect on view route
			RouterService.push({
				name: getConfig('RouteConfig', 'ViewRouteName'),
				params: {
					organization_id: to.params.organization_id,
					space_id: to.params.space_id,
					view_id: StoreService.state.view._id as string,
				},
			}).then(() => {
				next(false);
			});
		} else {
			resolve(true);
		}
	});
}
