import GetViewsService from '@/services/GetViews.service';
import { StoreService } from '@/services/Store.service';

export async function LoadViewsMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Views loading... ',
			'color: #FF0000;font-weight:bold;'
		);

		StoreService.dispatch('set', {
			stateProperty: 'viewsLoaded',
			value: false,
		}).then(() => {
			// get user views in view
			GetViewsService().then(() => {
				// set views in Store.state.views
				StoreService.dispatch('set', {
					stateProperty: 'viewsLoaded',
					value: true,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'viewLoaded',
						value: false,
					}).then(() => {
						resolve(true);
					});
				});
			});
		});
	});
}
