// src/services/config.service.ts

// import configs
import { AppConfig } from '@/configs/App.config';
import { RoleConfig } from '@/configs/Role.config';
import { RouteConfig } from '@/configs/Route.config';
import { VueConfig } from '@/configs/Vue.config';

// import array of config objects type
import { ArrayConfigType } from '@/types/ArrayConfig.type';

// import config type
import { ConfigType } from '@/types/Config.type';

// create array of config objects
const arrayConfigs: ArrayConfigType = [
	AppConfig,
	RoleConfig,
	RouteConfig,
	VueConfig,
];

// import App from vue to create a plugin
import { App, Plugin } from 'vue';

export function getConfig(ObjectName: string, property: string): any {
	const array = arrayConfigs.filter(
		(element: ConfigType) => element.ObjectName === ObjectName
	);
	if (array.length > 0) {
		return array[0][property];
	}
	return null;
}

// create and export config service
export const ConfigService: Plugin = {
	// required method to set config service as plugin
	install: (app: App) => {
		app.config.globalProperties.$config = getConfig;
	},
};

// set $config as global variable
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$config: any;
	}
}
