import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import SetMediaInStoreService from '@/services/SetMediaInStore.service';
import { StoreService } from '@/services/Store.service';
import { MediaType } from '@/types/Media.type';

export async function LoadMediaMiddleware(to: any, next: any) {
	const updateMedia = function (media: MediaType | null) {
		return new Promise((resolve) => {
			SetMediaInStoreService(media).then(() => {
				resolve(true);
			});
		});
	};

	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Media loading... ',
			'color: #FF0000;font-weight:bold;'
		);
		// media_id is provided
		if (to.params.media_id) {
			// replace provided media
			const media = StoreService.state.medias.find(
				(entity: MediaType) => entity._id === to.params.media_id
			);
			if (media) {
				updateMedia(media).then(() => {
					resolve(true);
				});
			} else {
				// redirect on not found route
				RouterService.push({
					name: getConfig('RouteConfig', 'Error404RouteName'),
				}).then(() => {
					next(false);
				});
			}
		}
		// media_id is not provided and space has not medias
		else {
			updateMedia(null).then(() => {
				resolve(true);
			});
		}
	});
}
