// src/services/SetGranularityInStoreFilters.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from './Router.service';
import SetPageInStoreFiltersService from './SetPageInStoreFilters.service';

export default async function (granularity?: boolean) {
	const updateQuery = function (resetPage: boolean = false) {
		return new Promise((resolve) => {
			if (resetPage) {
				SetPageInStoreFiltersService(0)
					.then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'granularityFiltersReady',
							value: true,
						}).then(() => {
							resolve(true);
						});
					})
			}
			else {
				StoreService.dispatch('set', {
					stateProperty: 'granularityFiltersReady',
					value: true,
				}).then(() => {
					resolve(true);
				});
			}
		});
	};
	
	return new Promise((resolve) => {
		StoreService.dispatch('set', {
			stateProperty: 'granularityFiltersReady',
			value: false,
		}).then(() => {
			// set with argument
			if (granularity !== undefined) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'granularity',
					value: granularity,
				}).then(() => {
					updateQuery(true).then(() => {
						resolve(true);
					});
				});
			}
			// set with url (arrive on product)
			// value is in URL
			else if (
				StoreService.state.checkURLFilters === true &&
				RouterService.currentRoute.value.query.granularity !== undefined
			) {
                const validGranularities: Array<string> = ['day', 'week', 'month', 'quarter']
				if (validGranularities.includes(RouterService.currentRoute.value.query.granularity as string)) {
					StoreService.dispatch('update', {
						stateProperty: 'filters',
						stateChildProperty: 'granularity',
						value: RouterService.currentRoute.value.query.granularity,
					}).then(() => {
						updateQuery().then(() => {
							resolve(true);
						});
					});
				}
                else {
					StoreService.dispatch('update', {
						stateProperty: 'filters',
						stateChildProperty: 'granularity',
						value: null,
					}).then(() => {
						updateQuery().then(() => {
							resolve(true);
						});
					});
				}
			}
            else {
				updateQuery().then(() => {
					resolve(true);
				});
			}
		});
	});
}
