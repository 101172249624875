// src/services/SetMediaInStore.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from '@/services/Router.service';
import { MediaType } from '@/types/Media.type';

export default async function (media?: MediaType | null) {
	return new Promise((resolve) => {
		console.log(
			'%c [Service] Set media in store',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'mediaLoaded',
			value: false,
		}).then(() => {
			/* set media passed by argument */
			if (media !== undefined) {
				StoreService.dispatch('set', {
					stateProperty: 'media',
					value: media,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'mediaLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			/* set media passed by url */
			else if (
				RouterService.currentRoute.value.params.media_id &&
				StoreService.state.medias!.find(
					(media: MediaType) =>
						media._id === RouterService.currentRoute.value.params.media_id
				) !== undefined
			) {
				StoreService.dispatch('set', {
					stateProperty: 'media',
					value: StoreService.state.medias!.find(
						(media: MediaType) =>
							media._id === RouterService.currentRoute.value.params.media_id
					),
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'mediaLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			else {
				StoreService.dispatch('set', {
					stateProperty: 'mediaLoaded',
					value: true,
				}).then(() => {
					resolve(true);
				});
			}
		});
	});
}
