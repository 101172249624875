
import { defineComponent } from 'vue';
import { NavBarMixin } from '@/mixins/NavBar.mixin';
import ProductNavBarVerticalContentsComponent from '@/components/navbars/ProductNavBarVerticalContents.component.vue';
import ProductNavBarVerticalDefaultComponent from '@/components/navbars/ProductNavBarVerticalDefault.component.vue';
import ProductNavBarVerticalMediaComponent from '@/components/navbars/ProductNavBarVerticalMedia.component.vue';
import ProductNavBarVerticalOrganizationComponent from '@/components/navbars/ProductNavBarVerticalOrganization.component.vue';
import ProductNavBarVerticalParametersComponent from '@/components/navbars/ProductNavBarVerticalParameters.component.vue';
import ProductNavBarVerticalProfileComponent from '@/components/navbars/ProductNavBarVerticalProfile.component.vue';
import ProductNavBarVerticalSpaceComponent from '@/components/navbars/ProductNavBarVerticalSpace.component.vue';
import ProductNavBarVerticalUserComponent from '@/components/navbars/ProductNavBarVerticalUser.component.vue';

export default defineComponent({
	mixins: [NavBarMixin],
	components: {
		ProductNavBarVerticalContentsComponent,
		ProductNavBarVerticalDefaultComponent,
		ProductNavBarVerticalMediaComponent,
		ProductNavBarVerticalOrganizationComponent,
		ProductNavBarVerticalParametersComponent,
		ProductNavBarVerticalProfileComponent,
		ProductNavBarVerticalSpaceComponent,
		ProductNavBarVerticalUserComponent,
	},
});
