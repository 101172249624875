import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import SetSpaceInStoreService from '@/services/SetSpaceInStore.service';
import { StoreService } from '@/services/Store.service';
import { SpaceType } from '@/types/Space.type';

export async function LoadSpaceMiddleware(to: any, next: any) {
	const updateSpace = function (space: SpaceType | null) {
		return new Promise((resolve) => {
			// set space in store
			SetSpaceInStoreService(space).then(() => {
				// set child entities loaded status to false
				StoreService.dispatch('set', {
					stateProperty: 'viewsLoaded',
					value: false,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'rolesLoaded',
						value: false,
					}).then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'brandsLoaded',
							value: false,
						}).then(() => {
							StoreService.dispatch('set', {
								stateProperty: 'mediasLoaded',
								value: false,
							}).then(() => {
								StoreService.dispatch('set', {
									stateProperty: 'filtersQuery',
									value: null,
								}).then(() => {
									resolve(true);
								});
							});
						});
					});
				});
			});
		});
	};

	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Space loading... ',
			'color: #FF0000;font-weight:bold;'
		);
		// space_id is provided
		if (to.params.space_id) {
			// replace provided space
			const space = StoreService.state.spaces.find(
				(entity: SpaceType) => entity._id === to.params.space_id
			);
			if (space) {
				updateSpace(space).then(() => {
					resolve(true);
				});
			} else {
				// redirect on not found route
				RouterService.push({
					name: getConfig('RouteConfig', 'Error404RouteName'),
				}).then(() => {
					next(false);
				});
			}
		}
		// space_id is not provided but space has spaces
		else if (StoreService.state.spaces.length > 0) {
			updateSpace(StoreService.state.spaces[0]).then(() => {
				resolve(true);
			});
		}
		// space_id is not provided and space has not spaces
		else {
			updateSpace(null).then(() => {
				resolve(true);
			});
		}
	});
}
