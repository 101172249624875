// src/services/SetViewInStore.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from '@/services/Router.service';
import { ViewType } from '@/types/View.type';
import { getConfig } from './Config.service';

export default async function (view?: ViewType | null) {
	return new Promise((resolve) => {
		console.log(
			'%c [Service] Set view in store',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'viewLoaded',
			value: false,
		}).then(() => {
			/* set view passed by argument */
			if (view !== undefined) {
				StoreService.dispatch('set', {
					stateProperty: 'view',
					value: view,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'viewLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			/* set view passed by url */
			else if (
				RouterService.currentRoute.value.params.view_id !== undefined &&
				RouterService.currentRoute.value.params.view_id !== '' &&
				StoreService.state.views!.find(
					(view: ViewType) =>
						view._id === RouterService.currentRoute.value.params.view_id
				) !== undefined
			) {
				StoreService.dispatch('set', {
					stateProperty: 'view',
					value: StoreService.state.views!.find(
						(view: ViewType) =>
							view._id === RouterService.currentRoute.value.params.view_id
					),
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'viewLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
			/* set default view */
			else if (
				StoreService.state.views!.find(
					(view: ViewType) =>
						view.space_id === null &&
						view.title?.toLowerCase() ===
							getConfig('VueConfig', 'ViewNameOverview')
				) !== undefined
			) {
				StoreService.dispatch('set', {
					stateProperty: 'view',
					value: StoreService.state.views!.find(
						(view: ViewType) =>
							view.space_id === null &&
							view.title?.toLowerCase() ===
								getConfig('VueConfig', 'ViewNameOverview')
					),
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'viewLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			} else {
				StoreService.dispatch('set', {
					stateProperty: 'view',
					value: StoreService.state.views![0],
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'viewLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
