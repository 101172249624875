import CreateDefaultOrganizationService from '@/services/CreateDefaultOrganization.service';
import GetOrganizationsService from '@/services/GetOrganizations.service';
import { StoreService } from '@/services/Store.service';

export async function LoadOrganizationsMiddleware(to: any, next: any) {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Middleware] Organizations loading... ',
			'color: #FF0000;font-weight:bold;'
		);

		StoreService.dispatch('set', {
			stateProperty: 'organizationsLoaded',
			value: false,
		}).then(() => {
			// check if user has organizations
			GetOrganizationsService().then(() => {
				// user has at least one organization
				if (StoreService.state.organizations!.length > 0) {
					StoreService.dispatch('set', {
						stateProperty: 'organizationsLoaded',
						value: true,
					}).then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'organizationLoaded',
							value: false,
						}).then(() => {
							resolve(true)
						});
					});
				}
				// user doesn't have organization
				else {
					if (to.params.organization_id) {
						resolve(true);
					} else {
						// create one default organization for user if no organization_id
						CreateDefaultOrganizationService()?.then((response: any) => {
							// set organizations in Store.state.organizations
							StoreService.dispatch('set', {
								stateProperty: 'organizations',
								value: [response.data],
							}).then(() => {
								StoreService.dispatch('set', {
									stateProperty: 'organizationsLoaded',
									value: true,
								}).then(() => {
									StoreService.dispatch('set', {
										stateProperty: 'organizationLoaded',
										value: false,
									}).then(() => {
										resolve(true)
									});
								});
							});
						})
						.catch((error: any) => {
							console.log(error);
						});
					}
				}
			})
			.catch((error: any) => {
				console.log(error);
			});
		});
	});
}
