import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalBillingComponent = _resolveComponent("ModalBillingComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$store.state.organization)
      ? (_openBlock(), _createBlock(_component_ModalBillingComponent, {
          key: 0,
          id: "modal-organization-required-payement",
          ref: "modal-organization-required-payement",
          title: 
			_ctx.$beautify.firstLetterUppercase(
				_ctx.$text('expressions', 'Premium_section')
			)
		,
          text: 
			_ctx.isOwnOrganization?
			_ctx.$beautify.firstLetterUppercase(
				_ctx.$text('expressions', 'Premium_upgrade')
			):
			_ctx.$beautify
				.firstLetterUppercase(
					_ctx.$text('expressions', 'Premium_upgrade_owner')
				)
				.replace(
					'{owner_email}',
					'<b>' + _ctx.organizationOwnerMail + '</b>'
				)
		,
          isOwnOrganization: _ctx.isOwnOrganization,
          premiumDemoLink: _ctx.premiumDemoLink,
          action1: _ctx.$beautify.firstLetterUppercase(_ctx.$text('actions', 'Close')),
          action2: _ctx.$beautify.firstLetterUppercase(_ctx.$text('actions', 'Plan_Upgrade')),
          onCallbackAction2: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBillingPage())),
          callbackAction1AutoDismiss: true,
          callbackAction2AutoDismiss: true
        }, null, 8, ["title", "text", "isOwnOrganization", "premiumDemoLink", "action1", "action2"]))
      : _createCommentVNode("", true),
    (_ctx.$store.state.organization)
      ? (_openBlock(), _createBlock(_component_ModalBillingComponent, {
          key: 1,
          id: "modal-user-required-payement",
          ref: "modal-user-required-payement",
          title: 
			_ctx.$beautify.firstLetterUppercase(
				_ctx.$text('expressions', 'Limit_Reached')
			)
		,
          text: 
			_ctx.$beautify.firstLetterUppercase(
				_ctx.$text('expressions', 'Contact_Us')
			)
		,
          action1: _ctx.$beautify.firstLetterUppercase(_ctx.$text('actions', 'Close')),
          callbackAction1AutoDismiss: true
        }, null, 8, ["title", "text", "action1"]))
      : _createCommentVNode("", true)
  ], 64))
}