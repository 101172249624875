import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DisableMobileVue = _resolveComponent("DisableMobileVue")!

  return (
			(typeof _ctx.$route.name !== 'undefined' ||
				_ctx.$store.state.productLoaded === false) &&
			_ctx.isMobile === false
		)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }))
    : (_ctx.isMobile === true)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_DisableMobileVue)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}