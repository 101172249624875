
import { defineComponent } from 'vue';

export default defineComponent({
	data() {
		return {
			menu: [
				{
					name: this.$text('expressions', 'Parameters_Search_Monitoring'),
					items: [
						{
							name: this.$text('expressions', 'Parameters_Brand_Settings'),
							link: '#brand-settings',
						},
						{
							name: this.$text('expressions', 'Parameters_Language_Settings'),
							link: '#language-settings',
						},
						{
							name: this.$text('expressions', 'Parameters_Exclude_Keywords'),
							link: '#exclude-keywords',
						},
					],
				},
				{
					name: this.$text('expressions', 'Parameters_Content_Analysis'),
					items: [
						{
							name: this.$text(
								'expressions',
								'Parameters_CEO_And_Spokesperson'
							),
							link: '#ceo-and-spokesperson',
						},
						{
							name: this.$text('expressions', 'Parameters_Message_Keywords'),
							link: '#message-keywords',
						},
						{
							name: this.$text('expressions', 'Parameters_Website'),
							link: '#query-website',
						},
						{
							name: this.$text('expressions', 'Parameters_SEO_Keywords'),
							link: '#seo-keywords',
						},
						{
							name: this.$text('expressions', 'Parameters_Google_Analytics'),
							link: '#google-analytics',
						},
					],
				},
			],
		};
	},
	created() {
		if (this.$route.hash === '') {
			this.$router.push({
				name: this.$route.name?.toString(),
				hash: this.menu[0].items[0].link,
			});
		}
	},
});
