
import { defineComponent } from 'vue';
import { IndexMixin } from '@/mixins/Index.mixin';
import AlertsGroup from '@/layouts/Alerts/AlertsGroup.layout.vue';
import ProductNavBarHorizontalComponent from '@/components/navbars/ProductNavBarHorizontal.component.vue';
import ProgressBarComponent from '@/components/ProgressBar.component.vue';

declare module '@vue/runtime-core' {
	// eslint-disable-next-line
	interface ComponentCustomProperties {
		$Progress: any;
	}
}

export default defineComponent({
	mixins: [IndexMixin],

	components: {
		AlertsGroup,
		ProductNavBarHorizontalComponent,
		ProgressBarComponent,
	},

	mounted() {
		//  [App.vue specific] When App.vue is finish loading finish the progress bar
		this.$Progress.finish();
		// get filters keywords
	},
	created() {
		//  [App.vue specific] When App.vue is first loaded start the progress bar
		this.$Progress.start();
		//  hook the progress bar to start before we move router-view
		this.$router.beforeEach((to) => {
			//  does the page we want to go to have a meta.progress object
			if (to.meta.progress !== undefined) {
				let meta = to.meta.progress;
				// parse meta tags
				this.$Progress.parseMeta(meta);
			}
			//  start the progress bar
			this.$Progress.start();
			this.$store.dispatch('set', {
				stateProperty: 'progressBarFinished',
				value: false,
			});
		});
		//  hook the progress bar to finish after we've finished moving router-view
		this.$router.afterEach(() => {
			//  finish the progress bar
			this.$Progress.finish();
			this.$store.dispatch('set', {
				stateProperty: 'progressBarFinished',
				value: true,
			});
		});
	},
});
