import Axios from 'axios';
import { AuthenticationService } from '@/services/Authentication.service';
import { StoreService } from '@/services/Store.service';
import { RouterService } from '@/services/Router.service';
import { getConfig } from '@/services/Config.service';
import { App, Plugin } from 'vue';

const BackendAPIPrefix = getConfig('AppConfig', 'BackendAPIPrefix');

// axios for front <> back communication with interceptors
export const axios = Axios;
axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		// BACK ERROR
		if (error && error.toJSON().config.url.match(BackendAPIPrefix) !== null) {
			if (error.response && error.response.status) {
				if (error.response.status === 401)
					AuthenticationService.logoutUser();
				else if (
					error.request.status === 502 &&
					RouterService.currentRoute.value.name !==
						getConfig('RouteConfig', 'Error502RouteName')
				) {
					RouterService.push({
						name: getConfig('RouteConfig', 'Error502RouteName'),
					});
				}
			}
			else if (!error.response) {
				StoreService.state.alerts.push({
					category: 'error',
					title: 'Something went wrong',
					texts: [
						'Backend does not seem to be available.'
					]
				})
			}
		}
		return Promise.reject(error);
	}
);

//create and export axios service
export const AxiosService: Plugin = {
	// required method to set config service as plugin
	install: (app: App) => {
		app.config.globalProperties.$axios = axios;
	},
};

// set $axiosas global variable
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$axios: any;
	}
}
