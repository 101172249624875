// src/services/GetViews.service.ts

import { StoreService } from '@/services/Store.service';
import { API } from '@/services/API.service';
import { getAlerts } from '@/services/Alerts.service';

export default async function (space_id?: string) {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Service] Getting views... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'viewsLoaded',
			value: false,
		}).then(() => {
			if (space_id === undefined) {
				space_id = StoreService.state.space!._id;
			}
			API.spaceViews('get', null, {
				space_id: space_id,
			})?.then((response: any) => {
				console.log(
					'%c [Service] Set views in store... ',
					'color: #0000FF;font-weight:bold;'
				);
				StoreService.dispatch('set', {
					stateProperty: 'views',
					value: response.data,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'viewsLoaded',
						value: true,
					}).then(() => {
						resolve(true);
					});
				});
			}).catch((error: any) => {
				getAlerts.pushFromError(error)
				reject(error)
			});
		});
	});
}
