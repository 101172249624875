// src/services/Permission.service.ts

import { StoreService } from '@/services/Store.service';
import { getConfig } from '@/services/Config.service';
import { RoleType } from '@/types/Role.type';

export const getPermission = {
	/**
	 * Permissions
	 */
	canGetOrganizationBilling(organization_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			organization_role &&
			organization_role.role &&
			organization_role.role >= getConfig('RoleConfig', 'Editor')
		)
			return true;
		else if (
			organization_role === undefined &&
			StoreService.state.organization &&
			StoreService.state.organization.role &&
			StoreService.state.organization.role.role &&
			StoreService.state.organization.role.role >=
				getConfig('RoleConfig', 'Editor')
		)
			return true;
		return false;
	},
	canUpdateOrganizationBilling(organization_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			organization_role &&
			organization_role.role &&
			organization_role.role >= getConfig('RoleConfig', 'Owner')
		)
			return true;
		else if (
			organization_role === undefined &&
			StoreService.state.organization &&
			StoreService.state.organization.role &&
			StoreService.state.organization.role.role &&
			StoreService.state.organization.role.role >=
				getConfig('RoleConfig', 'Owner')
		)
			return true;
		return false;
	},
	canGetOrganization(organization_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			organization_role &&
			organization_role.role &&
			organization_role.role >= getConfig('RoleConfig', 'Viewer')
		)
			return true;
		else if (
			organization_role === undefined &&
			StoreService.state.organization &&
			StoreService.state.organization.role &&
			StoreService.state.organization.role.role &&
			StoreService.state.organization.role.role >=
				getConfig('RoleConfig', 'Viewer')
		)
			return true;
		return false;
	},
	canUpdateOrganization(organization_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			organization_role &&
			organization_role.role &&
			organization_role.role >= getConfig('RoleConfig', 'Editor')
		)
			return true;
		else if (
			organization_role === undefined &&
			StoreService.state.organization &&
			StoreService.state.organization.role &&
			StoreService.state.organization.role.role &&
			StoreService.state.organization.role.role >=
				getConfig('RoleConfig', 'Editor')
		)
			return true;
		return false;
	},
	canDeleteOrganization(organization_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			organization_role &&
			organization_role.role &&
			organization_role.role >= getConfig('RoleConfig', 'Owner')
		)
			return true;
		else if (
			organization_role === undefined &&
			StoreService.state.organization &&
			StoreService.state.organization.role &&
			StoreService.state.organization.role.role &&
			StoreService.state.organization.role.role >=
				getConfig('RoleConfig', 'Owner')
		)
			return true;
		return false;
	},
	canCreateSpace(organization_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			organization_role &&
			organization_role.role &&
			organization_role.role >= getConfig('RoleConfig', 'Editor')
		)
			return true;
		else if (
			organization_role === undefined &&
			StoreService.state.organization &&
			StoreService.state.organization.role &&
			StoreService.state.organization.role.role &&
			StoreService.state.organization.role.role >=
				getConfig('RoleConfig', 'Editor')
		)
			return true;
		return false;
	},
	canGetSpace(organization_role?: RoleType, space_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			(organization_role &&
				organization_role.role &&
				organization_role.role >= getConfig('RoleConfig', 'Viewer')) ||
			(space_role &&
				space_role.role &&
				space_role.role >= getConfig('RoleConfig', 'Viewer'))
		)
			return true;
		else if (
			organization_role === undefined &&
			space_role === undefined &&
			((StoreService.state.organization &&
				StoreService.state.organization.role &&
				StoreService.state.organization.role.role &&
				StoreService.state.organization.role.role >=
					getConfig('RoleConfig', 'Viewer')) ||
				(StoreService.state.space &&
					StoreService.state.space.role &&
					StoreService.state.space.role.role &&
					StoreService.state.space.role.role >=
						getConfig('RoleConfig', 'Viewer')))
		)
			return true;
		return false;
	},
	canUpdateSpace(organization_role?: RoleType, space_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			(organization_role &&
				organization_role.role &&
				organization_role.role >= getConfig('RoleConfig', 'Editor')) ||
			(space_role &&
				space_role.role &&
				space_role.role >= getConfig('RoleConfig', 'Editor'))
		)
			return true;
		else if (
			organization_role === undefined &&
			space_role === undefined &&
			((StoreService.state.organization &&
				StoreService.state.organization.role &&
				StoreService.state.organization.role.role &&
				StoreService.state.organization.role.role >=
					getConfig('RoleConfig', 'Editor')) ||
				(StoreService.state.space &&
					StoreService.state.space.role &&
					StoreService.state.space.role.role &&
					StoreService.state.space.role.role >=
						getConfig('RoleConfig', 'Editor')))
		)
			return true;
		return false;
	},
	canDeleteSpace(organization_role?: RoleType, space_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			(organization_role &&
				organization_role.role &&
				organization_role.role >= getConfig('RoleConfig', 'Editor')) ||
			(space_role &&
				space_role.role &&
				space_role.role >= getConfig('RoleConfig', 'Editor'))
		)
			return true;
		else if (
			organization_role === undefined &&
			space_role === undefined &&
			((StoreService.state.organization &&
				StoreService.state.organization.role &&
				StoreService.state.organization.role.role &&
				StoreService.state.organization.role.role >=
					getConfig('RoleConfig', 'Editor')) ||
				(StoreService.state.space &&
					StoreService.state.space.role &&
					StoreService.state.space.role.role &&
					StoreService.state.space.role.role >=
						getConfig('RoleConfig', 'Editor')))
		)
			return true;
		return false;
	},
	canListEntity(organization_role?: RoleType, space_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			(organization_role &&
				organization_role.role &&
				organization_role.role >= getConfig('RoleConfig', 'Viewer')) ||
			(space_role &&
				space_role.role &&
				space_role.role >= getConfig('RoleConfig', 'Viewer'))
		)
			return true;
		else if (
			organization_role === undefined &&
			space_role === undefined &&
			((StoreService.state.organization &&
				StoreService.state.organization.role &&
				StoreService.state.organization.role.role &&
				StoreService.state.organization.role.role >=
					getConfig('RoleConfig', 'Viewer')) ||
				(StoreService.state.space &&
					StoreService.state.space.role &&
					StoreService.state.space.role.role &&
					StoreService.state.space.role.role >=
						getConfig('RoleConfig', 'Viewer')))
		)
			return true;
		return false;
	},
	canCreateEntity(organization_role?: RoleType, space_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			(organization_role &&
				organization_role.role &&
				organization_role.role >= getConfig('RoleConfig', 'Editor')) ||
			(space_role &&
				space_role.role &&
				space_role.role >= getConfig('RoleConfig', 'Editor'))
		)
			return true;
		else if (
			organization_role === undefined &&
			space_role === undefined &&
			((StoreService.state.organization &&
				StoreService.state.organization.role &&
				StoreService.state.organization.role.role &&
				StoreService.state.organization.role.role >=
					getConfig('RoleConfig', 'Editor')) ||
				(StoreService.state.space &&
					StoreService.state.space.role &&
					StoreService.state.space.role.role &&
					StoreService.state.space.role.role >=
						getConfig('RoleConfig', 'Editor')))
		)
			return true;
		return false;
	},
	canGetEntity(organization_role?: RoleType, space_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			(organization_role &&
				organization_role.role &&
				organization_role.role >= getConfig('RoleConfig', 'Viewer')) ||
			(space_role &&
				space_role.role &&
				space_role.role >= getConfig('RoleConfig', 'Viewer'))
		)
			return true;
		else if (
			organization_role === undefined &&
			space_role === undefined &&
			((StoreService.state.organization &&
				StoreService.state.organization.role &&
				StoreService.state.organization.role.role &&
				StoreService.state.organization.role.role >=
					getConfig('RoleConfig', 'Viewer')) ||
				(StoreService.state.space &&
					StoreService.state.space.role &&
					StoreService.state.space.role.role &&
					StoreService.state.space.role.role >=
						getConfig('RoleConfig', 'Viewer')))
		)
			return true;
		return false;
	},
	canUpdateEntity(organization_role?: RoleType, space_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			(organization_role &&
				organization_role.role &&
				organization_role.role >= getConfig('RoleConfig', 'Editor')) ||
			(space_role &&
				space_role.role &&
				space_role.role >= getConfig('RoleConfig', 'Editor'))
		)
			return true;
		else if (
			organization_role === undefined &&
			space_role === undefined &&
			((StoreService.state.organization &&
				StoreService.state.organization.role &&
				StoreService.state.organization.role.role &&
				StoreService.state.organization.role.role >=
					getConfig('RoleConfig', 'Editor')) ||
				(StoreService.state.space &&
					StoreService.state.space.role &&
					StoreService.state.space.role.role &&
					StoreService.state.space.role.role >=
						getConfig('RoleConfig', 'Editor')))
		)
			return true;
		return false;
	},
	canDeleteEntity(organization_role?: RoleType, space_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			(organization_role &&
				organization_role.role &&
				organization_role.role >= getConfig('RoleConfig', 'Editor')) ||
			(space_role &&
				space_role.role &&
				space_role.role >= getConfig('RoleConfig', 'Editor'))
		)
			return true;
		else if (
			organization_role === undefined &&
			space_role === undefined &&
			((StoreService.state.organization &&
				StoreService.state.organization.role &&
				StoreService.state.organization.role.role &&
				StoreService.state.organization.role.role >=
					getConfig('RoleConfig', 'Editor')) ||
				(StoreService.state.space &&
					StoreService.state.space.role &&
					StoreService.state.space.role.role &&
					StoreService.state.space.role.role >=
						getConfig('RoleConfig', 'Editor')))
		)
			return true;
		return false;
	},
	canListOrganizationUsers(organization_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			organization_role &&
			organization_role.role &&
			organization_role.role >= getConfig('RoleConfig', 'Viewer')
		)
			return true;
		else if (
			organization_role === undefined &&
			StoreService.state.organization &&
			StoreService.state.organization.role &&
			StoreService.state.organization.role.role &&
			StoreService.state.organization.role.role >=
				getConfig('RoleConfig', 'Viewer')
		)
			return true;
		return false;
	},
	canManageOrganizationOwners() {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		return false;
	},
	canManageOrganizationEditors(organization_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			organization_role &&
			organization_role.role &&
			organization_role.role >= getConfig('RoleConfig', 'Editor')
		)
			return true;
		else if (
			organization_role === undefined &&
			StoreService.state.organization &&
			StoreService.state.organization.role &&
			StoreService.state.organization.role.role &&
			StoreService.state.organization.role.role >=
				getConfig('RoleConfig', 'Editor')
		)
			return true;
		return false;
	},
	canManageOrganizationViewers(organization_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			organization_role &&
			organization_role.role &&
			organization_role.role >= getConfig('RoleConfig', 'Editor')
		)
			return true;
		else if (
			organization_role === undefined &&
			StoreService.state.organization &&
			StoreService.state.organization.role &&
			StoreService.state.organization.role.role &&
			StoreService.state.organization.role.role >=
				getConfig('RoleConfig', 'Editor')
		)
			return true;
		return false;
	},
	canListSpaceUsers(organization_role?: RoleType, space_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			(organization_role &&
				organization_role.role &&
				organization_role.role >= getConfig('RoleConfig', 'Viewer')) ||
			(space_role &&
				space_role.role &&
				space_role.role >= getConfig('RoleConfig', 'Viewer'))
		)
			return true;
		else if (
			organization_role === undefined &&
			space_role === undefined &&
			((StoreService.state.organization &&
				StoreService.state.organization.role &&
				StoreService.state.organization.role.role &&
				StoreService.state.organization.role.role >=
					getConfig('RoleConfig', 'Viewer')) ||
				(StoreService.state.space &&
					StoreService.state.space.role &&
					StoreService.state.space.role.role &&
					StoreService.state.space.role.role >=
						getConfig('RoleConfig', 'Viewer')))
		)
			return true;
		return false;
	},
	canManageSpaceEditors(organization_role?: RoleType, space_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			(organization_role &&
			organization_role.role &&
			organization_role.role >= getConfig('RoleConfig', 'Editor')) ||
			(space_role &&
				space_role.role &&
				space_role.role >= getConfig('RoleConfig', 'Editor'))
		)
			return true;
		else if (
			(organization_role === undefined &&
			StoreService.state.organization &&
			StoreService.state.organization.role &&
			StoreService.state.organization.role.role &&
			StoreService.state.organization.role.role >=
				getConfig('RoleConfig', 'Editor')) || (
                (StoreService.state.space &&
                    StoreService.state.space.role &&
                    StoreService.state.space.role.role &&
                    StoreService.state.space.role.role >=
                    getConfig('RoleConfig', 'Editor'))
            )
		)
			return true;
		return false;
	},
	canManageSpaceViewers(organization_role?: RoleType, space_role?: RoleType) {
		if (StoreService.state.profile && StoreService.state.profile.admin === true)
			return true;
		else if (
			(organization_role &&
				organization_role.role &&
				organization_role.role >= getConfig('RoleConfig', 'Editor')) ||
			(space_role &&
				space_role.role &&
				space_role.role >= getConfig('RoleConfig', 'Editor'))
		)
			return true;
		else if (
			organization_role === undefined &&
			space_role === undefined &&
			((StoreService.state.organization &&
				StoreService.state.organization.role &&
				StoreService.state.organization.role.role &&
				StoreService.state.organization.role.role >=
					getConfig('RoleConfig', 'Editor')) ||
				(StoreService.state.space &&
					StoreService.state.space.role &&
					StoreService.state.space.role.role &&
					StoreService.state.space.role.role >=
						getConfig('RoleConfig', 'Editor')))
		)
			return true;
		return false;
	},
};

// import App from vue to create a plugin
import { App, Plugin } from 'vue';

// create and export config service
export const PermissionService: Plugin = {
	// required method to set config service as plugin
	install: (app: App) => {
		app.config.globalProperties.$permission = getPermission;
	},
};

// set $permission as global variable
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$permission: any;
	}
}
