// src/services/GetBrands.service.ts

import { StoreService } from '@/services/Store.service';
import { API } from '@/services/API.service';
import { getAlerts } from '@/services/Alerts.service';

export default async function (space_id?: string) {
	const resetFilters = function () {
		console.log(
			'%c [Service] Reset filters... ',
			'color: #0000FF;font-weight:bold;'
		);
		return new Promise((resolve) => {
			StoreService.dispatch('update', {
				stateProperty: 'filters',
				stateChildProperty: 'brand_ids',
				value: undefined,
			}).then(() => {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'keywords',
					value: undefined,
				}).then(() => {
					StoreService.dispatch('update', {
						stateProperty: 'filters',
						stateChildProperty: 'keyword_ids',
						value: undefined,
					}).then(() => {
						StoreService.dispatch('update', {
							stateProperty: 'filters',
							stateChildProperty: 'languages',
							value: undefined,
						}).then(() => {
							StoreService.dispatch('update', {
								stateProperty: 'filters',
								stateChildProperty: 'categories',
								value: undefined,
							}).then(() => {
								resolve(true);
							});
						});
					});
				});
			});
		});
	};

	return new Promise((resolve, reject) => {
		console.log(
			'%c [Service] Getting brands... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'brandsLoaded',
			value: false,
		}).then(() => {
			if (space_id === undefined) {
				space_id = StoreService.state.space!._id;
			}
			API.spaceBrands('get', null, {
				space_id: space_id,
			})?.then((response: any) => {
				console.log(
					'%c [Service] Set brands in store... ',
					'color: #0000FF;font-weight:bold;'
				);
				StoreService.dispatch('set', {
					stateProperty: 'brands',
					value: response.data,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'brandsLoaded',
						value: true,
					}).then(() => {
						resetFilters().then(() => {
							resolve(true);
						});
					});
				});
			})
			.catch((error: any) => {
				getAlerts.pushFromError(error)
				reject(error)
			});
		});
	});
}
