// src/mixins/Index.mixin.ts

/**
 * root component
 */
import { getConfig } from '@/services/Config.service';
import { getToolbox } from '@/services/Toolbox.service';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export const IndexMixin = defineComponent({
	/**
	 * business logic only
	 */
	computed: {
		layout(): string {
			const route = useRoute();
			if (this.$store.state.productLoaded === false) {
				return 'LoadingLayout';
			} else {
				/* UnvailableServiceLayout */
				if (this.$toolbox.isMobile()) {
					return 'UnvailableServiceLayout';
				} else if (
					route &&
					route.name &&
					Array.isArray(getConfig('RouteConfig', 'OnboardingRouteNames')) &&
					Array.prototype.includes.call(
						getConfig('RouteConfig', 'OnboardingRouteNames'),
						route.name
					)
				) {
					/* LoggedOnboardingLayout */
					return 'LoggedOnboardingLayout';
				} else if (
					/* ErrorLayout */
					route &&
					route.name &&
					Array.isArray(getConfig('RouteConfig', 'ErrorRouteNames')) &&
					Array.prototype.includes.call(
						getConfig('RouteConfig', 'ErrorRouteNames'),
						route.name
					)
				) {
					return 'ErrorLayout';
				} else if (
					/* LoggedSettingsLayout */
					route &&
					route.name &&
					Array.isArray(getConfig('RouteConfig', 'LoggedSettingsRouteNames')) &&
					Array.prototype.includes.call(
						getConfig('RouteConfig', 'LoggedSettingsRouteNames'),
						route.name
					)
				) {
					return 'LoggedSettingsLayout';
				} else if (
					/* LoggedSettingsWithoutNavBarVerticalLayout */
					route &&
					route.name &&
					Array.isArray(
						getConfig(
							'RouteConfig',
							'LoggedSettingsWithoutNavBarVerticalRouteNames'
						)
					) &&
					Array.prototype.includes.call(
						getConfig(
							'RouteConfig',
							'LoggedSettingsWithoutNavBarVerticalRouteNames'
						),
						route.name
					)
				) {
					return 'LoggedSettingsWithoutNavBarVerticalLayout';

				} else if (
					/* LoggedSettingsWithoutNavBarLayout */
					route &&
					route.name &&
					Array.isArray(
						getConfig(
							'RouteConfig',
							'LoggedSettingsWithoutNavBarRouteNames'
						)
					) &&
					Array.prototype.includes.call(
						getConfig(
							'RouteConfig',
							'LoggedSettingsWithoutNavBarRouteNames'
						),
						route.name
					)
				) {
					return 'LoggedSettingsWithoutNavBarLayout';
				} else if (
					/* LoggedContentsWithoutNavBarVerticalLayout */
					route &&
					route.name &&
					Array.isArray(getConfig(
						'RouteConfig',
						'LoggedContentsWithoutNavBarVerticalRouteNames'
					)) &&
					Array.prototype.includes.call(
						getConfig(
							'RouteConfig',
							'LoggedContentsWithoutNavBarVerticalRouteNames'
						),
						route.name
					)
				) {
					return 'LoggedContentsWithoutNavBarVerticalLayout';
				} else if (
					/* LoggedClosableLayout */
					route &&
					route.name &&
					Array.isArray(getConfig('RouteConfig', 'LoggedClosableRouteNames')) &&
					Array.prototype.includes.call(
						getConfig('RouteConfig', 'LoggedClosableRouteNames'),
						route.name
					)
				) {
					return 'LoggedClosableLayout';
				} else if (
					/* AuthenticationLayout */
					route &&
					route.name &&
					Array.isArray(
						getConfig('RouteConfig', 'AuthenticationLayoutRouteNames')
					) &&
					Array.prototype.includes.call(
						getConfig('RouteConfig', 'AuthenticationLayoutRouteNames'),
						route.name
					)
				) {
					return 'AuthenticationLayout';
				} else if (
					/* EmbededErrorLayout */
					route &&
					route.name &&
					((this.$store.state.brands.length === 0 &&
						this.$config('RouteConfig', 'BrandsRequiredRouteNames').includes(
							this.$route.name
						)) ||
						(this.$store.state.medias.length === 0 &&
							this.$config('RouteConfig', 'MediasRequiredRouteNames').includes(
								this.$route.name
							)))
				) {
					return 'EmbededErrorLayout';
				} else if (
					/* NotLoggedClosableLayout */
					route &&
					route.name &&
					Array.isArray(
						getConfig('RouteConfig', 'NotLoggedClosableRouteNames')
					) &&
					Array.prototype.includes.call(
						getConfig('RouteConfig', 'NotLoggedClosableRouteNames'),
						route.name
					)
				) {
					return 'NotLoggedClosableLayout';
				} else {
					/* Default */
					return 'LoggedContentLayout';
				}
			}
		},
	},

	methods: {
		updated() {
			/**
			 *
			 * addons for mobile (breakpoint >lg)
			 *
			 */
			if (getToolbox.isMobile()) {
				// to close vertical-bar after click on link
				const navBar = document.getElementById('nav-bar');

				const hamburgerNavbar = document.querySelector(
					'.navbar-expand-lg .navbar-toggler'
				);
				if (navBar) {
					const links = navBar.getElementsByTagName('a');

					for (let i = 0; i < links.length; i++) {
						links[i].addEventListener('click', () => {
							if (hamburgerNavbar instanceof HTMLElement) {
								if (getToolbox.isMobile()) {
									hamburgerNavbar.click();
								}
							}
						});
					}
				}
			}
		},
	},
});
