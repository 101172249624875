import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';

export async function RouteChoiceDependsProfileMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Choising route (depends profile)',
			'color: #FF0000;font-weight:bold;'
		);
		// route public (no authentication needed)
		if (
			Array.prototype.includes.call(
				getConfig('RouteConfig', 'NotLoggedRouteNames'),
				to.name
			)
		) {
			// route public + user logged + profileCompleted
			if (
				StoreService.state.logged === true &&
				StoreService.state.profileCompleted === true
			) {
				resolve(true);
			}
			// route public + user not logged
			else if (
				StoreService.state.logged === true &&
				StoreService.state.profileCompleted === false
			) {
				// go to information page
				if (to.name !== getConfig('RouteConfig', 'InformationsRouteName')) {
					RouterService.push({
						name: getConfig('RouteConfig', 'InformationsRouteName'),
					}).then(() => {
						next(false);
					});
				} else {
					resolve(true);
				}
			}
		}

		// route private (authentication needed)
		else {
			// route private + user logged + profile completed
			if (
				StoreService.state.logged === true &&
				StoreService.state.profileCompleted === true
			) {
				resolve(true);
			}
			// route private + user logged + profile not completed
			else if (
				StoreService.state.logged === true &&
				StoreService.state.profileCompleted === false
			) {
				// go to information page
				if (to.name !== getConfig('RouteConfig', 'InformationsRouteName')) {
					RouterService.push({
						name: getConfig('RouteConfig', 'InformationsRouteName'),
					}).then(() => {
						next(false);
					});
				} else {
					resolve(true);
				}
			}
			// route private + user not logged + profile completed
			else {
				resolve(true);
			}
		}
	});
}
