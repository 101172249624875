// src/services/GetProfile.service.ts

import { StoreService } from '@/services/Store.service';
import { API } from '@/services/API.service';
import { getAlerts } from '@/services/Alerts.service';

export default async function () {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Service] Getting profile... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'profileLoaded',
			value: false,
		}).then(() => {
			API.profile('get')
				?.then((response: any) => {
					console.log(
						'%c [Service] Set profile in store... ',
						'color: #0000FF;font-weight:bold;'
					);
					StoreService.dispatch('set', {
						stateProperty: 'profile',
						value: response.data,
					}).then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'profileLoaded',
							value: true,
						}).then(() => {
							resolve(true);
						});
					});
				})
				.catch((error: any) => {
					getAlerts.pushFromError(error)
					reject(error)
				});
		});
	});
}
