import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';

export async function ErrorRedirectMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Error redirection... ',
			'color: #FF0000;font-weight:bold;'
		);
		if (to.name === undefined) {
			// go to 404
			RouterService.push({
				name: getConfig('RouteConfig', 'Error404RouteName'),
			}).then(() => {
				next(false);
			});
		} else {
			resolve(true);
		}
	});
}
