import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';

export async function RedirectCheckoutMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Checkout redirection ... ',
			'color: #FF0000;font-weight:bold;'
		);

		if (
			to.name === getConfig('RouteConfig', 'CheckoutRouteName') &&
			StoreService.state.cart === null
		) {
			// Redirect on organization route
			RouterService.push({
				name: getConfig('RouteConfig', 'ViewRouteName'),
				params: {
					organization_id: to.params.organization_id,
					space_id: to.params.space_id,
				},
			}).then(() => {
				next(false);
			});
		} else {
			resolve(true);
		}
	});
}
