import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import { StoreService } from '@/services/Store.service';

export async function RedirectUsersMiddleware(to: any, next: any) {
	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Users redirection ... ',
			'color: #FF0000;font-weight:bold;'
		);

		if (
			to.name === getConfig('RouteConfig', 'OrganizationRolesRouteName') &&
			StoreService.state.space
		) {
			// Redirect on space users route
			RouterService.push({
				name: getConfig('RouteConfig', 'SpaceRolesRouteName'),
				params: {
					organization_id: to.params.organization_id,
					space_id: StoreService.state.space._id as string,
				},
			}).then(() => {
				next(false);
			});
		} else {
			resolve(true);
		}
	});
}
