// src/configs/Texts.config.ts

// hydrate and export texts object
export const TextsConfig: { [key: string]: any } = {
	actions: {
		Add: 'add',
		AddBrand: 'add a brand',
		Add_Brand: 'add a brand',
		AddCompetitor: 'add a competitor',
		Add_Competitor: 'add a competitor',
		AddContent: 'add content',
		Add_Content: 'add content',
		Add_Import_Content: 'add/import content',
		AddMedia: 'add media',
		Add_Media: 'add media',
		Add_Or_Ignore_First_Media: 'add or ignore first media',
		Add_Single_Content: 'add single content',
		AddUser: 'add user',
		Add_User: 'add user',
		Active: 'active',
		Apply: 'apply',
		Apply_Filters: 'apply filters',
		Archive: 'archive',
		Back_To: 'back to',
		BrandParameters: 'brand parameters',
		Cancel: 'cancel',
		Change_Color: 'change color',
		Change_Plan: 'change plan',
		ChangeBrandColor: 'change brand color',
		CheckFileContent: 'check the file content',
		CheckInformation: 'check the information',
		Close: 'close',
		CreateAccount: 'create account',
		Create_A_Space: 'create space',
		Create_An_Organization: 'create organization',
		Create_Brand: 'create brand',
		Create_First_Brand: 'create first brand',
		Compare: 'compare',
		Connect_Google_Analytics: 'connect your Google Analytics',
		Connect_With: 'connect with',
		Connect_With_Email: 'connect with your email address',
		Connect_With_Google: 'connect with Google SSO',
		Continue: 'continue',
		Continue_Google: 'Continue with Google',
		Deactivate: 'Deactivate',
		Delete: 'delete',
		Delete_Brand: 'delete brand',
		Delete_Organization: 'delete organization',
		Delete_Space: 'delete space',
		Discard_Changes: 'Discard changes',
		DownloadResults: 'Download results',
		Download_Contents: 'download contents',
		Download_Posts: 'Download social posts',
		DragAndDropDocument: 'drag and drop document here to upload',
		Get_A_Demo: 'get a demo',
		GetStarted: 'Get Started',
		GoBack: 'go back',
		Hide: 'hide',
		Ignore: 'ignore',
		Ignore_Media: 'ignore',
		Import: 'import',
		ImportContents: 'import contents',
		ImportFile: 'import file',
		Import_File_Contents: 'import file contents',
		Lets_Go: 'Okay, let\'s go!',
		Loading: 'loading',
		LogIn: 'log in',
		Login_Google: 'Continue with Google',
		Logout: 'logout',
		Next: 'next',
		OpeningFile: 'opening file',
		Payment_Method_Add: 'Add payment method',
		Press: 'press',
		Register: 'register',
		Register_Google: 'Sign up with Google',
		Remove: 'remove',
		Remove_Brand: 'remove brand',
		Remove_Organization: 'remove organization',
		Remove_Space: 'remove space',
		Remove_And_Ignore_Media: 'exclude all contents from',
		Resend_Link: 'resend link',
		Reset_Password: 'reset password',
		Save: 'save',
		Save_And_Exit: 'save and exit',
		Save_Filters: 'save filters',
		Select_File: 'select file',
		Select_Category_Column: 'what is the category?',
		Select_Date_Column: 'what is the date?',
		Select_Link_Column: 'what is the link?',
		Select_Title_Column: 'what is the title?',
		Select_First_Content: 'select your first content',
		Select_First_Data_Line: 'select the first of data',
		Send_Link: 'send link',
		Show: 'show',
		SignIn: 'sign in',
		SignUp: 'sign up',
		SignUp_Google: 'Sign up with Google',
		Submit: 'submit',
		Plan_Change: 'select plan',
		Plan_Upgrade: 'upgrade plan',
	},
	labels: {
		// -- brand
		Brand_Country: 'location and language',
		Brand_Misspelling: 'brand misspelling',
		Brand_Multilingual: 'multi-language',
		Brand_Name: 'Brand name',
		Brand_Variation_Name: 'Brand variation name',
		Brand_Website: 'website URL',

		// -- content
		Content_Category: 'category',
		Content_Date: 'date',
		Content_Link: 'link',
		Content_Title: 'title',
		Content_IOV: 'Impact Of Voice™',

		// -- media
		Media_Category: 'category',
		Media_Domain: 'domain',
		Media_Family: 'family',
		Media_Ignore: 'ignore',
		Media_Name: 'name',
		Media_Rating: 'rating',

		// -- onboarding
		Onboarding_Brand_Name: 'Brand name',
		Onboarding_Competitor: 'Competitor',
		Onboarding_Country: 'Country',
		Onboarding_Keyword: 'Keyword',
		Onboarding_Space_Category: 'Space category',
		Onboarding_Website: 'Website URL',

		// -- organization
		Organization_Address: 'address',
		Organization_City: 'city',
		Organization_Country: 'country',
		Organization_Phone_Number: 'phone number',
		Organization_Email: 'email',
		Organization_Name: 'name',
		Organization_Website: 'website',
		Organization_ZIP: 'ZIP',

		// --payment method
		Payment_Method_Bank_Transfer_IBAN: 'IBAN',
		Payment_Method_Bank_Transfer_Name: 'name of the bank account owner',
		Payment_Method_Email: 'email',
		Payment_Method_Card_Information: 'card information',
		Payment_Method_Coupon: 'Coupon',
		Payment_Method_Country: 'country',

		// -- profile
		Address: 'address',
		CEOName: 'CEO name',
		City: 'city',
		Company_Name: 'company name',
		Competitors: 'competitors',
		Competitors_Activate: 'active competitors',
		Country: 'country',
		Email: 'Email',
		First_Name: 'first name',
		Job_Title: 'job title',
		Keyword_Variation: 'keyword variation',
		Query_Keyword: 'keyword',
		Last_Name: 'last name',
		MessageKeywords: 'strategic messages',
		Password: 'password',
		Password_Confirmation: 'confirm password',
		Phone_Number: 'phone number',
		ResultLanguage: 'result language',
		SearchKeywords: 'search topics',
		SEOKeywords: 'SEO keywords',
		Spokespersons: 'spokespersons',
		TrafficAndGoals: 'traffic & goals',
		Variation: 'variation',
		Website_Protocol: 'https://',
		Work_Email: 'Work email',
		ZIP: 'ZIP',

		// -- query
		Query_Country: 'location and language',
		Query_Website: 'website URL',
		Query_Twitter: 'twitter account',
		Query_Linkedin: 'linkedin account',
		Query_CEO_Linkedin: 'CEO Linkedin account',
		Query_Head_Of_Linkedin: 'Head of [...] Linkedin account',
		Query_Empty_Google_Analytics: 'no Google Analytics view',
		Query_Google_Analytics: '{property} > {view}',
		Query_Keep_Google_Analytics: 'keep current view',

		// -- space
		Space_Address: 'address',
		Space_City: 'city',
		Space_Country: 'country',
		Space_Description: 'description',
		Space_Phone_Number: 'phone number',
		Space_Email: 'email',
		Space_Name: 'name',
		Space_Website: 'website',
		Space_Product_Description: 'Product description',
		Space_ZIP: 'ZIP',

		// -- user
		User_Last_Name: 'last name',
		User_First_Name: 'first name',
		User_Email: 'email',
		User_Role: 'role',

		// -- post
		Post_Date: 'date',
		Post_Likes: 'likes',
		Post_Comments: 'comments',
	},
	placeholders: {
		// -- brand
		Brand_Country: 'Select a country',
		Brand_Misspelling: 'brand misspelling',
		Brand_Name: 'Greenpeace',
		Brand_Website: 'www.greenpeace.org',

		// -- onboarding
		Onboarding_Brand_Name: 'Type your brand name here...',
		Onboarding_Competitor: 'Type a competitor name here...',
		Onboarding_Country: 'Select country...',
		Onboarding_Keyword: 'Type a keyword here...',
		Onboarding_Website: 'Website URL...',

		// -- organization
		Organization_Address: '702 H Street, NW Suite 300',
		Organization_City: 'Washington',
		Organization_Country: 'USA',
		Organization_Phone_Number: '1-800-722-6995',
		Organization_Email: 'connect@greenpeace.us',
		Organization_Name: 'Greenpeace',
		Organization_Website: 'www.greenpeace.org',
		Organization_ZIP: '20001',

		// -- content
		Content_Category: 'category',
		Content_Date: 'date',
		Content_Link: 'link',
		Content_Title: 'title',

		// -- media
		Media_Category: 'category',
		Media_Domain: 'domain',
		Media_Family: 'family',
		Media_Ignore: 'ignore',
		Media_Name: 'name',
		Media_Rating: 'rating',

		// -- payment method
		PayPayment_Method_Cardholder_Name: '',
		Payment_Method_Card_Number: '1234 1234 1234 1234',
		Payment_Method_CCV: 'CCV',
		Payment_Method_Country: 'country',

		// -- profile
		Address: 'your address',
		City: 'city address',
		Company_Name: 'company name',
		Country: 'country address',
		Email: 'your email',
		First_Name: 'your first name',
		Job_Title: 'your job',
		Keyword_variation: 'keyword variation',
		Query_Keyword: 'ONG',
		Last_Name: 'your last name',
		Phone_Number: 'your phone number',
		Website_Protocol: 'https://',
		Work_Email: 'Work email',
		ZIP: 'ZIP',

		// -- query
		Query_Country: 'Select a country',
		Query_Website: 'www.greenpeace.org',
		Query_Twitter: 'greenpeacefr',
		Query_Linkedin: 'datakudo',
		Query_CEO_Linkedin: 'octave-laurentin-3561055b',
		Query_Head_Of_Linkedin: 'octave-laurentin-3561055b',

		// -- space
		Space_Address: '702 H Street, NW Suite 300',
		Space_City: 'Washington',
		Space_Country: 'USA',
		Space_Description: '',
		Space_Phone_Number: '1-800-722-6995',
		Space_Email: 'connect@greenpeace.us',
		Space_Name: 'Greenpeace',
		Space_Website: 'www.greenpeace.org',
		Space_ZIP: '20001',
		Variation: 'variation',

		// -- user
		User_Last_Name: 'last name',
		User_First_Name: 'first name',
		User_Email: 'email',
		User_Role: 'role',
		User_Scope: 'scope',
	},
	errors: {
		Min_Characters: '{entity} must be composed of at least {x} characters',
		Max_Characters: '{entity} must be composed of at most {x} characters',
		Min_Array_Elements: 'you must create at least {x} {entity}',
		Max_Array_Elements: 'you must create at most {x} {entity}',
		Entity_Already_Used: '{entity} already used',
		Entity_Limit_Reach: 'your limit of {x} {entity} is reached',
		Entity_Name_Already_Used: '{entity} name already used',
		Entity_Required: '{entity} must be specified',
		Entitiy_Must_Be_Different: '{entity_1} must be different than {entity_2}',
	},
	expressions: {

		// --Authentication
		Authentication_Title: 'Keep one step ahead of your competitors.',
		Authentication_Subtitle:
			'Manage your awareness on the web, analyze your competitors actions, and grow your business by increasing touch points with your buyers.',

		Button_Toggle_Filters: 'advanced filters',
		Ask_Sure_To_Delete: 'Do you want to delete?',
		Single_Content: 'single content',
		File_Contents: 'file content',

		// Modals
		Ask_Sure_To_Quit_Without_Save: 'Do you want to save?',
		Limit_Reached: 'You have reached the limit.',
		Contact_Us:
			'Please contact us through the chat below or by email to create a new organization.',
		Premium_demo_link: 'Premium features description',
		Premium_section: 'This section is for premium users.',
		Premium_upgrade:
			'To access all the premium features, upgrade your plan now!',
		Premium_upgrade_owner:
			'To access all the premium features, contact the organization owner {owner_email} to upgrade plan now!',

		// -- billing
		Billing_Title: 'usage',
		Billing_Current_Plan_Card_Footer_Question: 'Have question about your plan?',
		Billing_Current_Plan_Card_Footer_Link: 'Learn about plans and pricing',
		Billing_Section_2_Title: 'payment information',
		Billing_No_Payment_Info_Title: 'no payment info',
		Billing_Accepted_Payment_Methods:
			'We accept American Express, Mastercard, Visa, Diners Club, UnionPay, JCB, Discover, Paypal or ACH for payments in USD $.',

		Billing_Card_Number_Label: 'ends with',
		Billing_Card_Expiry_Label: 'MM/YY',
		Billing_Sepa_Debit_Number_Label: 'ends with',
		Billing_Sepa_Debit_Bank_Code_Label: 'bank code',
		Billing_Remove_Confirmation_Question:
			'Are you sure you want to remove this {type} ends by {last4}',

		Billing_Payment_Methods_List_Title: 'your payment methods',

		// --brand
		Brand_Delete_HelpText: 'Enter {name} to confirm the deletion',
		Brand_Delete_Confirmation_Question:
			'Are you sure you want to delete {name}?',

		//--contents
		Contents_File_Type_Error: 'Incorrect file type. Authorized file type : ',
		Contents_Check_Information_Explanation:
			'Help us to identify the contents in your files. We will only ask you for the first line to help our system understand the structure of your file.',
		Contents_Total_Of_Contents: 'Total of contents',

		// --cache
		Still_Calculating: 'Still calculating, click to refresh the data',

		// --Email
		Enter_Email_address: 'enter your email address',

		// Error
		Error_Introduction_Links: 'Here are some helpful links instead:',
		Error_No_Brand_Found_Title: 'You have not created a brand yet!',
		Error_No_Media_Found_Title: 'You have not added or ignored a media yet!',
		Error_No_Contents_Data: 'No contents to display with actual filters and date range!',
		Error_No_Posts_Data: 'No social posts to display with actual filters and date range!',
		Error_Social_Not_Configured: 'Fill space social parameters to get results!',
		Error_No_Charts_Data: 'No charts to display with actual filters and date range!',
		Error_No_Insights_Data: 'No insights to display with actual filters and date range!',

		// Error 404
		Error_403_Title: 'Oops!',
		Error_403_Subtitle: 'You are not allowed to pass.',
		Error_404_Title: 'Oops!',
		Error_404_Subtitle: "We can't seem to find the page you're looking for.",

		// Error Unvailable
		Error_Unvailable_Title: 'Oops!',
		Error_Unvailable_Subtitle:
			'The service seems to be temporarily unavailable, you can try to access it again in a few moments. Sorry for the inconvenience.',

		// -- filters
		Filters_Subtitle_Categories: 'categories',
		Filters_Subtitle_Languages: 'languages',
		Filters_Subtitle_Social_Source: 'Social',
		Filters_Subtitle_Search_Keywords: 'search topics',
		Filters_Subtitle_Message_Keywords: 'strategic messages',
		Filters_Subtitle_Spoke_Keywords: 'CEO & spokesperson',
		Filters_Title: 'filters',
		Filters_Title_Contents: 'contents',
		Filters_Title_Keywords: 'keywords',

		// --switch organization
		Switch_Organization_Change_Title: 'change organization',
		Switch_Organization_Current_Title: 'current organization',
		// --switch space
		Switch_Space_Change_Title: 'change space',
		Switch_Space_Current_Title: 'current space',

		ForgotPassword_Link: 'forgot password?',
		Google_Analytics: 'Google Analytics',
		Invalid_Resources: 'invalid resources',

		// --insights
		Insights_Title_1: 'media opportunities',
		Insights_Title_2: 'keywords opportunities',
		Insights_Title_3: 'backlinks opportunities',
		Comparison_Period: 'compare period',

		// --informations
		Additional_Information: 'additional informations',
		Informations_Title: 'so you’re new around here!',
		Informations_Subtitle: 'a very warm welcome from the team!',

		// -- invalid
		Credentials_Invalid: 'invalid email or password',
		Password_Invalid: 'invalid password',
		Password_AtLeast8Characters_Invalid: 'must contain at least 8 characters',
		Password_Atmost128Characters_Invalid: 'must contain at most 128 characters',
		Password_AtLeast1CapitalLetter_Invalid:
			'must contain at least 1 uppercase letter',
		Password_AtLeast1LowercaseLetter_Invalid:
			'must contain at least 1 lowercase letter',
		Password_AtLeast1SpecialCharacter_Invalid:
			'must contain at least 1 special character',
		Password_AtLeast1Number_Invalid: 'must contain at least 1 number',
		Password_NoUnknownCharacters_Invalid:
			'must not contain unknown characters',
		Password_Confirmation_Invalid: 'passwords must match',
		First_Name_Required: 'first name is required',
		Last_Name_Required: 'last name is required',
		Company_Name_Required: 'company name is required',

		// -- login
		Login_Card_Title: 'log into your account',
		Login_Card_Account_Verification_Mail_Not_Received:
			'Account verification mail not received?',
		Login_Card_Success_Mail_Activation_Sent:
			'please wait {time} seconds before requesting a new email with the account activation link.',
		Login_Card_Footer: 'you do not have an account?',
		Login_Header: 'Good to see you',
		Login_Paragraph:
			"Let's make amazing PR, Easier, Better, Faster, Stronger with Datakudo.",

		// -- not
		Not_Received: 'not received?',
		Not_You: 'not you?',

		// --medias
		Media_All_Media: 'all media',
		Media_Management: 'media management',
		Medias_List: 'List media',
		Medias_Top: 'Top medias',
		Medias_Ignored: 'Ignored medias',
		Media_Name_Section_Title: 'Name',
		Media_Name_Section_Subtitle: 'What is the media name?',
		Media_Name_Section_Explanation: '',
		Media_Domain_Section_Title: 'Domain',
		Media_Domain_Section_Subtitle: 'What is the media domain?',
		Media_Domain_Section_Explanation:
			'Enter the domain without any subdomain or top level domain (e.g. "datakudo" is the domain of app.datakudo.com).',
		Media_Family_Section_Title: 'Family',
		Media_Family_Section_Subtitle: 'What is the family of this media?',
		Media_Family_Section_Explanation: '(e.g. "Lifestyle" or "News")',
		Media_Category_Section_Title: 'Category',
		Media_Category_Section_Subtitle:
			'What is the default category of this media contents?',
		Media_Category_Section_Explanation: '(e.g. "Web Articles" or "Blog")',
		Media_Ignore_Section_Title: 'Ignore',
		Media_Ignore_Section_Subtitle:
			'Do you want to ignore all contents coming from this media?',
		Media_Ignore_Section_Explanation: '',
		Media_Remove_Confirmation_Question: 'Are you sure you want to remove',
		Media_Ignore_Confirmation_Question: 'Are you sure you want to ignore',

		// -- onboarding
		Onboarding_Not_Complete_Message:
		'Finish your space configuration to start processing your data.',
		Onboarding_Analytics_Question:
			'Connect your brand Google Analytics property.',
		Onboarding_Analytics_Explanation:
			'Google Analytics will be use on readonly mode to access.',
		Onboarding_Brand_Name_Question: 'What is your brand name?',
		Onboarding_Brand_Name_Explanation:
			'Enter the keywords you would use on Google to find web articles about your brand. We will then create smart daily searches to get you all relevant results.',
		Onboarding_Competitors_Question:
			'What are the names of your main competitors?',
		Onboarding_Competitors_Explanation:
			'Enter the names of these competing brands to search for their PR results as well. Avoid brand duplication (accronyms, misspelling...), you will have the possibility to add name variations in the parameters.',
		Onboarding_Country_Question: 'Where is your brand located?',
		Onboarding_Country_Explanation:
			'The country is used for the place of origin of our searches, as if you were in that country to search for branded content on Google. It also provides information about the language of the brand.',
		Onboarding_Search_Keywords_Question:
			'What topics do you associate with your brand in your searches?',
		Onboarding_Search_Keywords_Explanation:
			'For best results, split group of keywords into several keywords. For example, use "health" and then "insurance" instead of "health insurance". Add between one and five topics.',
		Onboarding_SEO_Keywords_Question:
			'What are the keywords you want to track for SEO purpose?',
		Onboarding_SEO_Keywords_Explanation:
			'These keywords will be use to generate searches without any brand mentions and look for brand website SEO position.',
		Onboarding_Space_Category_Question:
			'What do you want to analyze in this space?',
		Onboarding_Space_Category_Explanation:
			'If you want to analyze competiting brands, select "Brands". If you want to follow a topic, select "Topics".',
		Onboarding_Spoke_Keywords_Question:
			'Who are the spokespeople for your brand?',
		Onboarding_Spoke_Keywords_Explanation:
			'Enter the name of the CEO or any other spokesperson that we can search for in your PR content.',
		Onboarding_Message_Keywords_Question: 'What are your strategic messages?',
		Onboarding_Message_Keywords_Explanation:
			'Enter the keywords of the strategic messages you want to analyze in the PR content.',
		Onboarding_Website_Question: 'What is your brand website URL?',
		Onboarding_Website_Explanation:
			"Copy and paste the brand's website URL below, useful if you want to link your Google Analytics account or if you need to track your brand's SEO position.",

		// -- organization
		Organization_Delete_HelpText: 'Enter {name} to confirm the deletion',
		Organization_Delete_Confirmation_Question:
			'Are you sure you want to delete {name}?',

		// -- parameters
		Parameters_Brand_Name: 'brand name',
		Parameters_Brand_Name_Subtitle:
			'The Brand name field and its variations allow us to search daily for new results. The URL field helps us identify backlinks in the content.',
		Parameters_Brand_Settings: 'brand settings',
		Parameters_Brand_Settings_Subtitle:
			'Enter the keywords you would use on Google to find web articles about the brand. We will then create smart daily searches to get you all relevant articles.',
		Parameters_Twitter: 'twitter',
		Parameters_Twitter_Subtitle:
			'Twitter account name (without @) to retrieve Twitter messages mentioning the brand.',
		Parameters_Linkedin: 'linkedin',
		Parameters_Linkedin_Subtitle:
			'Linkedin account name (without @) to retrieve Linkedin posts published by the brand.',
		Parameters_Linkedin_CEO_Subtitle:
			'Linkedin account name (without @) to retrieve Linkedin posts published by the CEO.',
		Parameters_Linkedin_Head_Of_Subtitle:
			'Linkedin account name (without @) to retrieve Linkedin posts published by the Head Of [...].',
		Parameters_Website: 'website',
		Parameters_Website_Subtitle:
			'The URL field helps us identify backlinks in the content and identify SEO position of the website.',
		Parameters_CEO_And_Spokesperson: 'CEO and spokesperson',
		Parameters_CEO_And_Spokesperson_Subtitle:
			'We will be able to analyse how often the CEO and spokespersons are mentioned.',
		Parameters_Content_Analysis: 'content analysis',
		Parameters_Content_Analysis_Question:
			'Now that you have your searches set up, what would you want to analyze from that content?',
		Parameters_Content_Analysis_Subtitle:
			'Now that you have your searches set up, what would you want to analyze from that content?',
		Parameters_Exclude_Keywords: 'Exclude keywords',
		Parameters_Exclude_Keywords_Subtitle:
			'If you get irrelevant results related to your brand name, you can exclude them from the results by writing them below.<br>\
			For example: "Forest" for the brand Amazon.',
		Parameters_Google_Analytics: 'Google Analytics',
		Parameters_Google_Analytics_Subtitle:
			'Thanks to Google Analytics, we will be able to track and display website traffic, website conversions and much more!',
		Parameters_Google_Analytics_Empty:
			'No Google Analytics view connected.',
		Parameters_Google_Analytics_Current:
			'Current Google Analytics view: ',
		Parameters_Google_Analytics_Replace:
			'Replace with the following Google Analytics view:',
		Parameters_Language_Settings: 'language and location settings',
		Parameters_Language_Settings_Subtitle:
			'Choose the language and location of the search.',
		Parameters_Search_Keywords: 'search topics',
		Parameters_Search_Keywords_Subtitle:
			'We will search daily for the keywords below with the brand names mentioned above.<br>\
			Example of searches we\'ll make: {"brand name" "keyword 1"} or {"brand name" "keyword 2"}<br>\
			To get a maximum of results, split group of keywords into multiple keywords. For example, prefer "health" then "insurance" to "health insurance".',
		Parameters_Message_Keywords: 'strategic messages',
		Parameters_Message_Keywords_Subtitle:
			'We will be able to analyse how often your Strategic Messages are mentioned.',
		Parameters_Generate_Message_Keywords_title: 'Generate new keywords with our AI',
		Parameters_Search_Monitoring: 'search monitoring',
		Parameters_Search_Monitoring_Question:
			'Which keywords would you write on Google to get articles?',
		Parameters_Search_Monitoring_Subtitle:
			'Enter the keywords you would use on Google to find web articles about the brand. We will then create smart daily searches to get you all relevant articles.',
		Parameters_SEO_Keywords: 'SEO keywords',
		Parameters_SEO_Keywords_Subtitle:
			'Enter the keywords you would use on Google to track SEO ranking position of given website.',
		Parameters_SEO_Tracking: 'SEO Tracking',

		// -- password
		Password_Reset_Card_Title_Error_Token_Invalid:
			'invalid reset password link',
		Password_Reset_Card_Description_Error_Token_Invalid:
			"You can't change your password with an invalid link. <br /> Click on the link below to receive a new reset password link at {email}.",
		Password_Reset_Card_Title_Error_Token_Expired:
			'invalid reset password link',
		Password_Reset_Card_Description_Error_Token_Expired:
			"You can't change your password with an invalid link. <br /> Click on the link below to receive a new reset password link at {email}.",
		Password_Reset_Card_Footer_Error_Token_Invalid_Mail_Activation_Sent:
			'please wait {time} seconds before requesting a new email with the reset password link.',
		Password_Reset_Card_Footer_Error_Token_Expired_Mail_Activation_Sent:
			'please wait {time} seconds before requesting a new email with the reset password link.',
		Password_Forgot_Card_Footer: 'you remember your password?',
		Password_Forgot_Card_Title: 'reset your password',
		Password_Forgot_Card_Footer_Success: 'not received?',
		Password_Forgot_Card_Footer_Success_Mail_Activation_Sent:
			'please wait {time} seconds before requesting a new email with the reset password link.',
		Password_Forgot_Card_Title_Success: 'check your mailbox!',
		Password_Forgot_Card_Description_Success:
			'A password recovery link has just been sent to {email}. <br/> Please click on the email link to change your password.',
		Password_Reset_Card_Footer: 'you already have an account?',
		Password_Reset_Card_Title: 'reset your password',

		// -- payment method
		Payment_Method_Policy:
			'By providing your payment information, you authorise Datakudo and Stripe, our payment service provider, to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.',
		Payment_Method_Switch: 'Payment by',
		Payment_Method_Bank_Transfer_Name_Error_Incomplete:
			'Your bank account owner name is incomplete.',
		Payment_Method_Bank_Transfer_Name_Error_Invalid:
			'Your bank account owner name is invalid.',
		Payment_Method_Card_Expiry_Error_Incomplete:
			"Your card's expiration date is incomplete.",
		Payment_Method_Card_Expiry_Error_Invalid:
			"Your card's expiration date is invalid.",
		Payment_Method_Card_Cvc_Error_Incomplete:
			"Your card's security code is incomplete.",
		Payment_Method_Card_Cvc_Error_Invalid:
			"Your card's security code is invalid.",
		Payment_Method_Card_Information_Invalid:
			'Your card informations are invalid',
		Payment_Method_Cardholder_Name_Error_Incomplete:
			"Your card's name is incomplete",
		Payment_Method_Card_Number_Error_Incomplete:
			"Your card's number is incomplete.",
		Payment_Method_Card_Number_Error_Invalid: "Your card's number is invalid.",
		Payment_Method_Iban_Error_Incomplete: 'Your iban is incomplete.',
		Payment_Method_Iban_Error_Invalid: 'Your iban is invalid.',
		Payment_Method_Set_As_Default_Payment_Method:
			'Set as default payment method',

		// -- plans
		Plans_Quantity_Search_Per_Month: '{qt} Google Searches',
		Plans_Quantity_Content_Per_Month: '{qt} Contents / mo',
		Plans_Quantity_Content_Per_Screen: '{qt} Contents / Screen',
		Plans_Plan_Feature: '{plan} plan features',

		// -- posts
		Posts_Total_Of_Posts: 'Total of social posts',

		Region_And_Language: 'Region and Language',
		// --register
		Register_Card_Title: 'create your datakudo account',
		Register_Card_Footer: 'you already have an account?',

		// --register success
		Register_Card_Title_Success: 'account successfully created',
		Register_Card_Description_Success:
			'An account activation link has just been sent to {email}. <br/> Please click on the email link to verify your account.',
		Register_Card_Success_Mail_Activation_Sent:
			'please wait {time} seconds before requesting a new email with the account activation link.',
		Register_Card_Footer_Success: 'not received?',
		Register_Card_Description_Success_Footer_Mail_Activation_Sent:
			'please wait {time} seconds before requesting a new email with the account activation link.',

		Register_Header: 'Create your account',
		Register_Paragraph: "It's free. No credit card needed.",

		// --register verify success
		Register_Verify_Card_Title_Success_Mail_Activation_Sent:
			'Account activation link sent',
		Register_Verify_Card_Description_Success_Mail_Activation_Sent:
			'An account activation link has just been sent to {email}. <br_/> Please click on this email link to verify your account.',
		Register_Verify_Card_Footer_Success_Mail_Activation_Sent:
			'please wait {time} seconds before requesting a new email with the account activation link.',

		// --register verify error
		Register_Verify_Card_Title_Error: 'account verification error',
		Register_Verify_Card_Title_Error_Invalid_Token:
			'failed account verification',
		Register_Verify_Card_Description_Error_Invalid_Token:
			'Sorry, We cannot verify your account. <br /> Please click on the link below to receive at {email} the account activation link.',
		Register_Verify_Card_Title_Error_Token_Expired: 'token expired',
		Register_Verify_Card_Description_Error_Token_Expired:
			'Your account {email} has already been verified. Please connect.',
		Register_Verify_Card_Title_Error_Unknow_User: 'User account not found',
		Register_Verify_Card_Description_Error_Unknow_User:
			'Sorry, We cannot find your account.',

		// --role
		Role_Remove_From_Organization_Confirmation_Question:
			'Are you sure you want to delete {user} from organization {organization}?',
		Role_Remove_From_Space_Confirmation_Question:
			'Are you sure you want to delete {user} from space {space}?',

		// --space
		Space_Delete_HelpText: 'Enter {name} to confirm the deletion',
		Space_Delete_Confirmation_Question:
			'Are you sure you want to delete {name}?',

		// --usage
		Usage_Reset: 'Usage reset in {time}',

		// --user
		User_All_User: 'all user',
		User_Email_Section_Subtitle: 'what is the user email?',
		User_Email_Section_Explanation: '',
		User_Role_Section_Subtitle: 'what should be the role of this user?',
		User_Role_Section_Explanation:
			'choose "editor" if you want the user to be able to manage the different entities. Otherwise select "viewer" to allow him to see the content only.',
		User_Scope_Section_Subtitle:
			"should this user's role be limited to the current space?",
		User_Scope_Section_Explanation:
			'if you activate this button, the user will be limited to the current space, otherwise he will have access to all the spaces of the current organization.',
		Users_Filters_All_User: 'all users',
		Users_Filters_Organization_Users: 'organization users',
		Users_Filters_Space_Users: 'space users',

		// -- view
		View_Not_Enough_Data: 'not enough data with the specified filters',

		// --vertical navbar
		VerticalNavBar_Global_Views: 'global views',

		// --Welcome
		Nice_To_See_You: 'nice to see you!',

		// --Notifications
		Notification_Configuration_Title: 'Email notification for the space "{space}" of the organisation "{organization}"',
		Notification_Configuration_SubTitle: 'Emails will be sent to : {email}',
		Notification_Configuration_Saved_Message_Title: 'Email notification',
		Notification_Configuration_Saved_Message: 'Your notification settings are saved successfully!',
		Notification_Configuration_Send_Message: 'Your notification is scheduled successfully!',
		Notification_Configuration_Send_Error_Message: 'Sorry, you just can send one email a day!',
		Notification_Opportunities_label: 'Opportunities',
		Notification_Opportunities_description:"Get insights on your competitor's activities such as significant visibility growth, articles where they are several to appear but not your brand.",
		Notification_Summary_label: 'Growth and Insights Summary',
		Notification_Summary_description:"Get strategic intelligence on your leadership, evolution of your competitors' rankings, topics they are working hardest on, and recommendations on the topics with the most visibility.",
		Notification_Sales_Summary_label: 'Salespeople Insights Summary',
		Notification_Sales_Summary_description:"Personalize your sales approaches by showing an interest in the competitive environment of your strategic prospects and customers.",
		Notification_Sales_Summary_description2:"Generate personalized emails using AI to follow-up with all your prospects.",
		Notification_Sales_Summary_description3:"To do so, add your product description",
		Notification_Hr_Summary_label: 'Advanced Insights Summary',
		Notification_Hr_Summary_description:"Gather the top content from each brand only when it matches the strategic keywords you chose in parameters.",
		Notification_Summary_Recurrence_label: 'Recurrence',
		Notification_Summary_Recurrence_Weekly: 'weekly',
		Notification_Summary_Recurrence_Monthly: 'monthly',
		Notification_Summary_Recurrence_Both: 'both',
	},
	routes_name: {
		// --routes
		Route_Billing_Friendly_Name: 'billing',
		Route_Checkout_Friendly_Name: 'checkout',
		Route_Contents_Friendly_Name: 'contents',
		Route_Posts_Friendly_Name: 'posts',
		Route_Home_Friendly_Name: 'home',
		Route_Onboarding_Friendly_Name: 'onboarding',
		Route_Organization_Friendly_Name: 'organization',
		Route_Organization_Create_Friendly_Name: 'create an organization',
		Route_Organization_Update_Friendly_Name: 'update an organization',
		Route_Organization_Roles_Friendly_Name: 'users',
		Route_Parameters_Friendly_Name: 'parameters',
		Route_Payment_Method_Add_Friendly_Name: 'add payment method',
		Route_Payment_Method_Update_Friendly_Name: 'update payment method',
		Route_Plans_Friendly_Name: 'plans',
		Route_Privacy_Policy_Name: 'privacy policy',
		Route_Informations_Friendly_Name: 'informations',
		Route_Profile_Friendly_Name: 'profile',
		Route_Notifications_Friendly_Name: 'notifications management',
		Route_Space_Friendly_Name: 'space',
		Route_Space_Create_Friendly_Name: 'create a space',
		Route_Space_Results_Friendly_Name: 'results',
		Route_Space_Insight_Friendly_Name: 'insights',
		Route_Space_Medias_Friendly_Name: 'medias',
		Route_Space_Add_Content_Friendly_Name: 'add a content',
		Route_Space_Add_Media_Friendly_Name: 'add a media',
		Route_Space_Update_Media_Friendly_Name: 'update a media',
		Route_Space_Roles_Friendly_Name: 'users',
		Route_Space_Add_Role_Friendly_Name: 'add a user role',
		Route_Space_Update_Role_Friendly_Name: 'update a user role',
		Route_Space_Overview_Friendly_Name: 'overview',
		Route_Space_Update_Friendly_Name: 'update a space',
		Route_Space_View_Friendly_Name: 'view',
		Route_Space_Contents_Preview_Friendly_Name: 'contents preview',
		Route_Space_Posts_Preview_Friendly_Name: 'posts preview',
	},
	words: {
		Account: 'account',
		Admin: 'admin',
		Brand: 'brand',
		Bank_Transfer: 'bank transfer',
		Buttons: 'buttons',
		Card: 'card',
		Compare: 'compare',
		Competitor: 'competitor',
		Contact: 'contact',
		Content: 'content',
		Contents: 'contents',
		Custom: 'custom',
		Day: 'day',
		Days: 'days',
		Default: 'default',
		Enter: 'enter',
		Filter: 'filter',
		Filters: 'filters',
		Google_Searches: 'google searches',
		Hour: 'hour',
		Hours: 'hours',
		Insights: 'insights',
		Job: 'job',
		Keyword: 'keyword',
		Keywords: 'keywords',
		Media: 'media',
		Medias: 'medias',
		Mentions: 'mentions',
		Misspelling: 'misspelling',
		Misspellings: 'misspellings',
		Month: 'month',
		Months: 'months',
		Ok: 'ok',
		Or: 'or',
		Organization: 'organization',
		Organizations: 'organizations',
		Overview: 'overview',
		Plan_Current: 'current plan',
		Parameters: 'parameters',
		Period: 'period',
		Predictive: 'predictive',
		Pricing: 'pricing',
		Product: 'product',
		Profile: 'profile',
		Notifications: 'notifications',
		Results: 'results',
		Send_now: 'Send now',
		Scoring: 'scoring',
		Selected: 'selected',
		Sepa_Debit: 'Iban',
		Sheet: 'sheet',
		Social: 'Social',
		Social_Posts: 'social posts',
		Space: 'space',
		Spaces: 'spaces',
		SSO: 'SSO',
		Step: 'step',
		Usage: 'usage',
		Usage_Reset: 'usage reset',
		Unset: 'unset',
		User: 'user',
		Variation: 'variation',
		Variations: 'variations',
		Weeks: 'weeks',
		Week: 'week',
		Year: 'year',
		Years: 'years',
	},
};
