
import { CompetitorType } from '@/types/Competitor.type';
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';
import CompetitorButtonComponent from '@/components/buttons/CompetitorButton.component.vue';
import DateRangePickerComponent from '@/components/DateRangePicker.component.vue';
import SetBrandIdsInStoreFiltersService from '@/services/SetBrandIdsInStoreFilters.service';
import GetKeywordsService from '@/services/GetKeywords.service';
import SetKeywordIdsInStoreFiltersService from '@/services/SetKeywordIdsInStoreFilters.service';
import CheckFiltersService from '@/services/CheckFilters.service';

type dataType = {
	lastToggleTime: Date
};

export default defineComponent({
	data(): dataType {
		return {
			lastToggleTime: new Date()
		};
	},
	components: {
		CompetitorButtonComponent,
		DateRangePickerComponent,
	},
	created() {
		SetBrandIdsInStoreFiltersService()
	},
	watch: {
		'$store.state.brandsLoaded': function (value: boolean, old: boolean) {
			if (
				value && !old &&
				this.$config('RouteConfig', 'FilterableRouteNames').includes(
					this.$store.state.wanted_route.name
				) === true
			) {
				SetBrandIdsInStoreFiltersService().then(() => {
					GetKeywordsService().then(() => {
						SetKeywordIdsInStoreFiltersService().then(() => {
							CheckFiltersService();
						});
					});
				});
			}
		},
	},
	methods: {
		toggle(competitor: CompetitorType) {
			let vue = this;

			const now = new Date
			this.lastToggleTime = now
			SetBrandIdsInStoreFiltersService(competitor._id).then(() => {
				setTimeout(function () {
					if (now == vue.lastToggleTime) {
						SetKeywordIdsInStoreFiltersService().then(() => {
							CheckFiltersService();
						});
					}
				}, 2000);
			});
		},
		showPaymentModal() {
			var modalRequirePayment = new Modal(
				document.getElementById(
					'modal-organization-required-payement'
				) as Element
			);
			modalRequirePayment.show();
		},
	},
});
