// src/configs/Vue.config.ts

// import Config type
import { ConfigType } from '@/types/Config.type';

// hydrate and export appConfig object
export const VueConfig: ConfigType = {
	ObjectName: 'VueConfig',
	// screen width < LgBreakPoint = mobile
	LgBreakPoint: 991,
	ViewNameOverview: 'overview',
};
