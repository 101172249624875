import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropdown-menu dropdown-menu-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-menu-inside-wrapper", _ctx.dropdownMenuInsideWrapperClass])
    }, [
      _renderSlot(_ctx.$slots, "header"),
      _renderSlot(_ctx.$slots, "content")
    ], 2)
  ]))
}