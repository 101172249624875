// src/services/SetKeywordIdsInStoreFilter.service.ts

import { StoreService } from '@/services/Store.service';
import { RouterService } from './Router.service';
import { CountriesConfig } from '@/configs/Countries.config';
import SetPageInStoreFiltersService from './SetPageInStoreFilters.service';

export default async function (languages?: Array<string>) {
	const updateQuery = function (resetPage: boolean = false) {
		return new Promise((resolve) => {
			if (resetPage) {
				SetPageInStoreFiltersService(0)
					.then(() => {
						StoreService.dispatch('set', {
							stateProperty: 'languagesFiltersReady',
							value: true,
						}).then(() => {
							resolve(true);
						});
					})
			}
			else {
				StoreService.dispatch('set', {
					stateProperty: 'languagesFiltersReady',
					value: true,
				}).then(() => {
					resolve(true);
				});
			}
		});
	};

	return new Promise((resolve) => {
		console.log(
			'%c [Service] Set languages in store... ',
			'color: #0000FF;font-weight:bold;'
		);
		StoreService.dispatch('set', {
			stateProperty: 'languagesFiltersReady',
			value: false,
		}).then(() => {
			if (languages !== undefined) {
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'languages',
					value: languages,
				}).then(() => {
					updateQuery(true).then(() => {
						resolve(true);
					});
				});
			}
			/* keep languages filters */
			else if (StoreService.state.filters.languages) {
				updateQuery(false).then(() => {
					resolve(true);
				});
			}
			/* init languages filters */
			else {
				let languages: Array<string> = []
				if (
					StoreService.state.checkURLFilters === true &&
					RouterService.currentRoute.value.query.languages !== undefined
				) {
					languages = (
						RouterService.currentRoute.value.query.languages as string
					).replace('%2C', ',').split(',');
				}
				else {
					StoreService.state.brands.forEach(brand => {
						brand.queries!.forEach(query => {
							CountriesConfig[query.country!]
							if (
								CountriesConfig[query.country!].language &&
								!languages.includes(CountriesConfig[query.country!].language)
							) {
								languages.push(CountriesConfig[query.country!].language)
							}
						});
					});
				}
				StoreService.dispatch('update', {
					stateProperty: 'filters',
					stateChildProperty: 'languages',
					value: languages,
				}).then(() => {
					updateQuery(!StoreService.state.checkURLFilters).then(() => {
						resolve(true);
					});
				});
			}
		});
	});
}
