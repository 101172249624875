// src/services/CreateDefaultOrganization.service.ts
import { API } from '@/services/API.service';
import { getConfig } from '@/services/Config.service';
import { getAlerts } from '@/services/Alerts.service';
import { StoreService } from './Store.service';

export default async function () {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Service] Create default organization... ',
			'color: #0000FF;font-weight:bold;'
		);
		if (StoreService.state.profile && StoreService.state.profile.company) {
			API.organizations('post', {
				name: StoreService.state.profile.company
			})
				?.then((response: any) => {
					resolve(response);
				})
				.catch((error: any) => {
					getAlerts.pushFromError(error)
					reject(error)
				});
		}
		else {
			API.organizations('post', {
				name: getConfig('AppConfig', 'FirstOrganizationName') as string
			})
				?.then((response: any) => {
					resolve(response);
				})
				.catch((error: any) => {
					getAlerts.pushFromError(error)
					reject(error)
				});
		}
	});
}
