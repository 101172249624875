// src/services/router.service.ts

// import router
import {
	createRouter,
	RouterOptions,
	createWebHistory,
	Router,
} from 'vue-router';

// import routes
import { RouterConfig } from '@/configs/Router.config';
import { StoreService } from '@/services/Store.service';
import { getConfig } from '@/services/Config.service';
import { OrganizationType } from '@/types/Organization.type';
import { SpaceType } from '@/types/Space.type';
import { ViewType } from '@/types/View.type';
import { RoleType } from '@/types/Role.type';
import { BrandType } from '@/types/Brand.type';
import { MediaType } from '@/types/Media.type';
import { ErrorRedirectMiddleware } from '@/middlewares/ErrorRedirect.middleware';
import { AuthenticationMiddleware } from '@/middlewares/Authentication.middleware';
import { RouteChoiceDependsAuthenticationMiddleware } from '@/middlewares/RouteChoiceDependsAuthentication.middleware';
import { LoadProfileMiddleware } from '@/middlewares/LoadProfile.middleware';
import { CheckProfileMiddleware } from '@/middlewares/CheckProfile.middleware';
import { RouteChoiceDependsProfileMiddleware } from '@/middlewares/RouteChoiceDependsProfile.middleware';
import { LoadOrganizationsMiddleware } from '@/middlewares/LoadOrganizations.middleware';
import { LoadOrganizationMiddleware } from '@/middlewares/LoadOrganization.middleware';
import { RedirectOrganizationMiddleware } from '@/middlewares/RedirectOrganization.middleware';
import { LoadSpacesMiddleware } from '@/middlewares/LoadSpaces.middleware';
import { LoadSpaceMiddleware } from '@/middlewares/LoadSpace.middleware';
import { RedirectSpaceMiddleware } from '@/middlewares/RedirectSpace.middleware';
import { PermissionMiddleware } from '@/middlewares/Permission.middleware';
import { LimitationMiddleware } from '@/middlewares/Limitation.middleware';
import { RedirectOnboardingMiddleware } from '@/middlewares/RedirectOnboarding.middleware';
import { LoadViewsMiddleware } from '@/middlewares/LoadViews.middleware';
import { LoadViewMiddleware } from '@/middlewares/LoadView.middleware';
import { RedirectViewMiddleware } from '@/middlewares/RedirectView.middleware';
import { LoadRolesMiddleware } from '@/middlewares/LoadRoles.middleware';
import { LoadRoleMiddleware } from '@/middlewares/LoadRole.middleware';
import { RedirectRoleMiddleware } from '@/middlewares/RedirectRole.middleware';
import { LoadBrandsMiddleware } from '@/middlewares/LoadBrands.middleware';
import { LoadBrandMiddleware } from '@/middlewares/LoadBrand.middleware';
import { RedirectBrandMiddleware } from '@/middlewares/RedirectBrand.middleware';
import { LoadMediasMiddleware } from '@/middlewares/LoadMedias.middleware';
import { LoadMediaMiddleware } from '@/middlewares/LoadMedia.middleware';
import { RedirectMediaMiddleware } from '@/middlewares/RedirectMedia.middleware';
import { RedirectUsersMiddleware } from '@/middlewares/RedirectUsers.middleware';
import { RedirectBillingMiddleware } from '@/middlewares/RedirectBilling.middleware';
import { RedirectCheckoutMiddleware } from '@/middlewares/RedirectCheckout.middleware';
import { RedirectFiltersQueryMiddleware } from '@/middlewares/RedirectFiltersQuery.middleware';
import { RedirectSubscribeMiddleware } from "@/middlewares/RedirectSubscribe.middleware";

// router options
const options: RouterOptions = {
	routes: RouterConfig,
	history: createWebHistory(),
};

const RouterService: Router = createRouter(options);

// navigations guards before
RouterService.beforeEach(async (to, from, next) => {
	console.log(
		'%c [Route] : ' + to.name?.toString(),
		'color: #FFA500;font-weight:bold;'
	);

	// redirection start
	await StoreService.dispatch('set', {
		stateProperty: 'redirecting',
		value: true,
	});

	// save wanted route name
	await StoreService.dispatch('set', {
		stateProperty: 'wanted_route',
		value: to,
	});

	// save previous route name
	await StoreService.dispatch('set', {
		stateProperty: 'previous_route',
		value: from,
	});

	// save last product route name
	if (
		!getConfig('RouteConfig', 'LoggedSettingsRouteNames').includes(from.name)
	) {
		await StoreService.dispatch('set', {
			stateProperty: 'last_product_route',
			value: from,
		});
	}

	// handle Public Route which not Authentication Route to do not get spaces, brands etc
	// end of block --await StoreService + next()-- can be factored but it's easier to understand if it's not
	if (
		getConfig('RouteConfig', 'NotLoggedRouteNames').includes(to.name) ===
			false ||
		getConfig('RouteConfig', 'AuthenticationLayoutRouteNames').includes(
			to.name
		) === true
	) {
		// Middlewares global routes
		await AuthenticationMiddleware();
		await RouteChoiceDependsAuthenticationMiddleware(to, next);

		// Middlewares product routes (only if user is logged)
		if (StoreService.state.logged === true) {
			// Check if route exists
			await ErrorRedirectMiddleware(to, next);

			// if not error route
			if (!getConfig('RouteConfig', 'ErrorRouteNames').includes(to.name)) {
				// profile is not loaded yet
				if (StoreService.state.profileLoaded === false) {
					await LoadProfileMiddleware();
				}

				// profile is not completed yet
				if (StoreService.state.profileCompleted === false) {
					await CheckProfileMiddleware();
					RouteChoiceDependsProfileMiddleware(to, from);
				}

				// profile is loaded and completed
				if (
					StoreService.state.profileLoaded === true &&
					StoreService.state.profileCompleted === true
				) {
					// OR (
					// 	(LOADING) organizations not loaded yet
					// 	(CREATION) AND (
					//	 organization_id provided
					//   organization_id not in loaded organizations
					//  )
					// )
					if (
						StoreService.state.organizationsLoaded === false ||
						(to.params.organization_id &&
							StoreService.state.organizations?.find(
								(organization: OrganizationType) =>
									organization._id === to.params.organization_id
							) === undefined)
					) {
						await LoadOrganizationsMiddleware(to, next);
					}

					// OR (
					// 	(LOADING) organization not loaded yet
					// 	(SWITCH) AND (
					// 	 organization_id provided
					//   (OR) (
					//	  organization stored is null
					//    organization stored has different id
					//   )
					// 	)
					// )
					if (
						StoreService.state.organizationLoaded === false ||
						(to.params.organization_id &&
							(!StoreService.state.organization ||
								to.params.organization_id !==
									StoreService.state.organization!._id))
					) {
						await LoadOrganizationMiddleware(to, next);
					}
					// check organization redirection
					await RedirectOrganizationMiddleware(to, next);

					// OR (
					//  (LOADING) spaces not loaded yet
					//  (CREATION) AND (
					//	 space_id provided
					//   space not in loaded spaces
					//  )
					// )
					if (
						StoreService.state.spacesLoaded === false ||
						(to.params.space_id &&
							StoreService.state.spaces?.find(
								(space: SpaceType) => space._id === to.params.space_id
							) === undefined)
					) {
						await LoadSpacesMiddleware(to, next);
					}

					// OR (
					//  (LOADING) space not loaded yet
					// 	(SWITCH) AND (
					// 	 organization_id provided
					// 	 space_id provided
					//   (OR) (
					//	  space stored is null
					//    space stored has different id
					//    space stored has different parent id
					//   )
					// 	)
					// )
					if (
						StoreService.state.spaceLoaded === false ||
						(to.params.organization_id &&
							to.params.space_id &&
							(!StoreService.state.space ||
								StoreService.state.space._id !== to.params.space_id ||
								StoreService.state.space.organization_id !==
									to.params.organization_id))
					) {
						await LoadSpaceMiddleware(to, next);
					}
					// check space redirection
					await RedirectSpaceMiddleware(to, next);

					// check permission
					await PermissionMiddleware(to, next);

					// check limitation
					await LimitationMiddleware(to, next);

					// check subscribe
					await RedirectSubscribeMiddleware(to, next);

					// check onboarding
					await RedirectOnboardingMiddleware(to, next);

					// OR (
					//  (LOADING) views not loaded yet
					//  (CREATION) AND (
					//	 view_id provided
					//   view not in loaded views
					//  )
					// )
					if (
						getConfig('RouteConfig', 'ViewsRequiredRouteNames').includes(to.name) &&
						(
							StoreService.state.viewsLoaded === false ||
							(to.params.view_id &&
								StoreService.state.views?.find(
									(view: ViewType) => view._id === to.params.view_id
								) === undefined)
						)
					) {
						await LoadViewsMiddleware(to, next);
					}

					// OR (
					//  (LOADING) view not loaded yet
					//  (SWITCH) AND (
					//   organization_id provided
					// 	 space_id provided
					// 	 view_id provided
					//   (OR) (
					//	  view stored is null
					//    view stored has different id
					//    AND (
					//	   view stored parent id not null (generic view)
					//     view stored has different parent id
					//    )
					//   )
					//  )
					// )
					if (
						getConfig('RouteConfig', 'ViewRequiredRouteNames').includes(to.name) &&
						(
							StoreService.state.viewLoaded === false ||
							(to.params.organization_id &&
								to.params.space_id &&
								to.params.view_id &&
								(!StoreService.state.view ||
									StoreService.state.view._id !== to.params.view_id ||
									(StoreService.state.view.space_id &&
										StoreService.state.view.organization_id &&
										(StoreService.state.view.space_id !== to.params.space_id ||
											StoreService.state.view.organization_id !==
												to.params.organization_id))))
						)
					) {
						await LoadViewMiddleware(to, next);
					}
					// check view redirection
					await RedirectViewMiddleware(to, next);

					// OR (
					//  (LOADING) views not loaded yet
					//  (CREATION) AND (
					//	 brand_id provided
					//   brand not in loaded brands
					//  )
					// )
					if (
						getConfig('RouteConfig', 'BrandsRequiredRouteNames').includes(to.name) &&
						(
							StoreService.state.brandsLoaded === false ||
							(to.params.brand_id &&
								StoreService.state.brands?.find(
									(brand: BrandType) => brand._id === to.params.brand_id
								) === undefined)
						)
					) {
						await LoadBrandsMiddleware(to, next);
					}

					// OR (
					//  (LOADING) brand not loaded yet
					//  (SWITCH) AND (
					//   organization_id provided
					// 	 space_id provided
					// 	 brand_id provided
					//   (OR) (
					//	  brand stored is null
					//    brand stored has different id
					//    brand stored has different parent id
					//   )
					//  )
					// )
					if (
						getConfig('RouteConfig', 'BrandRequiredRouteNames').includes(to.name) &&
						(
							StoreService.state.brandLoaded === false ||
							(to.params.organization_id &&
								to.params.space_id &&
								to.params.brand_id &&
								(!StoreService.state.brand ||
									StoreService.state.brand._id !== to.params.brand_id ||
									StoreService.state.brand.space_id !== to.params.space_id ||
									StoreService.state.brand.organization_id !==
										to.params.organization_id))
						)
					) {
						await LoadBrandMiddleware(to, next);
					}

					// check brand redirection
					await RedirectBrandMiddleware(to, next);

					// OR (
					//  (LOADING) roles not loaded yet
					//  (CREATION) AND (
					//	 role_id provided
					//   role not in loaded roles
					//  )
					// )
					if (
						getConfig('RouteConfig', 'RolesRequiredRouteNames').includes(to.name) &&
						(
							StoreService.state.rolesLoaded === false ||
							(to.params.role_id &&
								StoreService.state.roles?.find(
									(role: RoleType) => role._id === to.params.role_id
								) === undefined)
						)
					) {
						await LoadRolesMiddleware(to);
					}

					// OR (
					//  (LOADING) role not loaded yet
					//  (SWITCH) AND (
					//   organization_id provided
					// 	 space_id provided
					// 	 role_id provided
					//   (OR) (
					//	  role stored is null
					//    role stored has different id
					//    role stored has different parent id
					//   )
					//  )
					// )
					if (
						getConfig('RouteConfig', 'RoleRequiredRouteNames').includes(to.name) &&
						(
							StoreService.state.roleLoaded === false ||
							(to.params.organization_id &&
								to.params.space_id &&
								to.params.role_id &&
								(!StoreService.state.role ||
									StoreService.state.role._id !== to.params.role_id ||
									StoreService.state.role.space_id !== to.params.space_id ||
									StoreService.state.role.organization_id !==
										to.params.organization_id))
						)
					) {
						await LoadRoleMiddleware(to, next);
					}

					// check role redirection
					await RedirectRoleMiddleware(to, next);

					// OR (
					//  (LOADING) medias not loaded yet
					//  (CREATION) AND (
					//	 media_id provided
					//   media not in loaded medias
					//  )
					// )
					if (
						getConfig('RouteConfig', 'MediasRequiredRouteNames').includes(to.name) &&
						(
							StoreService.state.mediasLoaded === false ||
							(to.params.media_id &&
								StoreService.state.medias?.find(
									(media: MediaType) => media._id === to.params.media_id
								) === undefined)
						)
					) {
						await LoadMediasMiddleware(to);
					}

					// OR (
					//  (LOADING) media not loaded yet
					//  (SWITCH) AND (
					//   organization_id provided
					// 	 space_id provided
					// 	 media_id provided
					//   (OR) (
					//	  media stored is null
					//    media stored has different id
					//    media stored has different parent id
					//   )
					//  )
					// )
					if (
						getConfig('RouteConfig', 'MediaRequiredRouteNames').includes(to.name) &&
						(
							StoreService.state.mediaLoaded === false ||
							(to.params.organization_id &&
								to.params.space_id &&
								to.params.media_id &&
								(!StoreService.state.media ||
									StoreService.state.media._id !== to.params.media_id ||
									StoreService.state.media.space_id !== to.params.space_id ||
									StoreService.state.media.organization_id !==
										to.params.organization_id))
						)
					) {
						await LoadMediaMiddleware(to, next);
					}

					// check media redirection
					await RedirectMediaMiddleware(to, next);

					// check users redirection
					await RedirectUsersMiddleware(to, next);

					// check billing redirection
					await RedirectBillingMiddleware(to, next);

					// check checkout redirection
					await RedirectCheckoutMiddleware(to, next);

					// check filters query redirection
					await RedirectFiltersQueryMiddleware(from, to, next)
				}
			}
		}
	}


	// save current route name
	await StoreService.dispatch('set', {
		stateProperty: 'current_route',
		value: to,
	});

	// set product as loaded
	if (StoreService.state.productLoaded === false) {
		await StoreService.dispatch('set', {
			stateProperty: 'productLoaded',
			value: true,
		});
	}
	next();
});

// navigations guards after
RouterService.afterEach(async (to, from, failure) => {
	// redirection end
	await StoreService.dispatch('set', {
		stateProperty: 'redirecting',
		value: false,
	});
	// set class to body
	document.body.className = '';
	if (to.name) {
		document.body.classList.add(to.name as string);
	}
});

export { RouterService };
