import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import SetBrandInStoreService from '@/services/SetBrandInStore.service';
import { StoreService } from '@/services/Store.service';
import { BrandType } from '@/types/Brand.type';

export async function LoadBrandMiddleware(to: any, next: any) {
	const updateBrand = function (brand: BrandType | null) {
		return new Promise((resolve) => {
			SetBrandInStoreService(brand).then(() => {
				resolve(true);
			});
		});
	};

	return new Promise((resolve) => {
		console.log(
			'%c [Middleware] Brand loading... ',
			'color: #FF0000;font-weight:bold;'
		);
		// brand_id is provided
		if (to.params.brand_id) {
			// replace provided brand
			const brand = StoreService.state.brands.find(
				(entity: BrandType) => entity._id === to.params.brand_id
			);
			if (brand) {
				updateBrand(brand).then(() => {
					resolve(true);
				});
			} else {
				// redirect on not found route
				RouterService.push({
					name: getConfig('RouteConfig', 'Error404RouteName'),
				}).then(() => {
					next(false);
				});
			}
		}
		// brand_id is not provided but space has brands
		else if (StoreService.state.brands.length > 0) {
			updateBrand(StoreService.state.brands[0]).then(() => {
				resolve(true);
			});
		}
		// brand_id is not provided and space has not brands
		else {
			updateBrand(null).then(() => {
				resolve(true);
			});
		}
	});
}
