
import { defineComponent } from 'vue';
import ModalMixin from '@/mixins/Modal.mixin';
export default defineComponent({
	mixins: [ModalMixin],
	props: {
		id: {
			type: String,
		},
		callbackAction1AutoDismiss: {
			type: Boolean,
		},
		callbackAction2AutoDismiss: {
			type: Boolean,
		},
	},
});
