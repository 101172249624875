// src/mixins/Alert.mixin.ts
import { defineComponent } from 'vue';
export default defineComponent({
	/**
	 * business logic only
	 */
	props: {
		title: {
			type: String,
			required: true,
		},
		texts: {
			type: Array,
		}
    },
});
