import { getConfig } from '@/services/Config.service';
import { RouterService } from '@/services/Router.service';
import { getPermission } from '@/services/Permission.service';

export async function PermissionMiddleware(to: any, next: any) {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Middleware] Check Permission ... ',
			'color: #FF0000;font-weight:bold;'
		);

		if (
			// get organization access
			(to.name === getConfig('RouteConfig', 'OrganizationUpdateRouteName') &&
				!getPermission.canGetOrganization()) ||
			// create space access
			(to.name === getConfig('RouteConfig', 'SpaceCreateRouteName') &&
				!getPermission.canCreateSpace()) ||
			// get space access
			(to.name === getConfig('RouteConfig', 'SpaceUpdateRouteName') &&
				!getPermission.canGetSpace()) ||
			// create content access
			(to.name === getConfig('RouteConfig', 'ContentAddRouteName') &&
				!getPermission.canCreateEntity()) ||
			// get products access /plans
			(to.name === getConfig('RouteConfig', 'PlansRouteName') &&
				!getPermission.canUpdateOrganizationBilling()) ||
			// get products access /checkout
			(to.name === getConfig('RouteConfig', 'CheckoutRouteName') &&
				!getPermission.canUpdateOrganizationBilling())
		)
			// redirect on not permitted route
			RouterService.push({
				name: getConfig('RouteConfig', 'Error403RouteName'),
			}).then(() => {
				next(false);
			});
		else {
			resolve(true);
		}
	});
}
