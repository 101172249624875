// src/services/Alerts.service.ts

import { StoreService } from '@/services/Store.service';

export const getAlerts = {
	/**
	 * Alerts
	 */
    pushFromError(error: any) {
		switch (error.response.status) {
			// Bad Request (400)
			case 400:
				StoreService.state.alerts.push({
					category: 'warning',
					title: 'Bad Request',
					texts: [
						'This request is invalid.'
					]
				})
				break
			// Require Payment (402)
			case 402:
				StoreService.state.alerts.push({
					category: 'warning',
					title: 'Payment required',
					texts: [
						'Limit has been reached!',
						'You should upgrade plans to access resources.'
					]
				})
				break
			// Not Permitted (403)
			case 403:
				StoreService.state.alerts.push({
					category: 'warning',
					title: 'Permission Denied',
					texts: [
						'You have not enough permissions to access resources.'
					]
				})
				break
			// Not Found (404)
			case 404:
				StoreService.state.alerts.push({
					category: 'warning',
					title: 'Not Found',
					texts: [
						'The resource you try to access is not found.'
					]
				})
				break
			// Conflict (409)
			case 409:
				StoreService.state.alerts.push({
					category: 'warning',
					title: 'Conflict',
					texts: [
						'The resource already exists.'
					]
				})
				break
			// Conflict (422)
			case 422:
				StoreService.state.alerts.push({
					category: 'warning',
					title: 'Unprocessable',
					texts: [
						'The resource is not processable.'
					]
				})
				break
			// Internal Server Error (500)
			case 500:
				StoreService.state.alerts.push({
					category: 'error',
					title: 'Something went wrong',
					texts: [
						'Backend internal error.'
					]
				})
				break
			// Timeout (504)
			case 504:
				StoreService.state.alerts.push({
					category: 'error',
					title: 'Wow, it sounds like you have a lot of contents.',
					texts: [
						'Try to reduce the filters (number of brands, date range, keywords...).',
						'Contact us if you want to get results on a large amount of data.'
					]
				})
				break
			default:
				StoreService.state.alerts.push({
					category: 'error',
					title: 'Something went wrong',
					texts: [
						'Unknown error.'
					]
				})
		}
	},
	pushMissings(missing: number) {
		StoreService.state.alerts.push({
			category: 'warning',
			title: 'Upgrade plans',
			texts: [
				"Organization limit reached.",
				missing + " missing content(s)."
			]
		})
	},
	pushTimeout() {
		StoreService.state.alerts.push({
			category: 'error',
			title: 'Wow, it sounds like you have a lot of contents.',
			texts: [
				'Try to reduce the filters (number of brands, date range, keywords...).',
				'Contact us with the chat above to get results on a large amount of data.'
			]
		})
	}
};

// import App from vue to create a plugin
import { App, Plugin } from 'vue';
import { Store } from 'vuex';

// create and export config service
export const AlertService: Plugin = {
	// required method to set config service as plugin
	install: (app: App) => {
		app.config.globalProperties.$alerts = getAlerts;
	},
};

// set $alerts as global variable
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$alerts: any;
	}
}
