import GetBrandsService from '@/services/GetBrands.service';
import { StoreService } from '@/services/Store.service';

export async function LoadBrandsMiddleware(to: any, next: any) {
	return new Promise((resolve, reject) => {
		console.log(
			'%c [Middleware] Brands loading... ',
			'color: #FF0000;font-weight:bold;'
		);

		StoreService.dispatch('set', {
			stateProperty: 'brandsLoaded',
			value: false,
		}).then(() => {
			// get user brands in space
			GetBrandsService().then(() => {
				// set brandsLoaded in Store.state.brandsLoaded
				StoreService.dispatch('set', {
					stateProperty: 'brandsLoaded',
					value: true,
				}).then(() => {
					StoreService.dispatch('set', {
						stateProperty: 'brandLoaded',
						value: false,
					}).then(() => {
						resolve(true);
					});
				});
			});
		});
	});
}
